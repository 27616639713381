import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import { APIRequest } from '../../services/axios';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { FaRegEye, FaRegTimesCircle } from 'react-icons/fa';
import { useWindowSize } from '../../utils/utils';
import { BiCheckCircle } from 'react-icons/bi';
import { Steps } from 'primereact/steps';
import { Checkbox } from 'primereact/checkbox';
import Loader from '../../components/loader';
import Header from '../../components/header';
import { Link, useNavigate } from 'react-router-dom';
import FrameContratto from '../../components/frameContratto';

function PageCandidato() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const fileRefs = useRef({});
    const toast = useRef(null);
    const progressBarRef = useRef(null);
    const containerRef = useRef(null);
    const [width] = useWindowSize();
    const [docs, setDocs] = useState(null)
    const steps = [
        { id: 0, label: 'Dati Anagrafici', completed: false },
        { id: 1, label: 'Documenti', completed: false },
        { id: 2, label: 'Conferma', completed: false }
    ]
    const [documentsUser, setDocumentsUser] = useState(null)
    const [progressAmount, setProgressAmount] = useState(0);
    const [checkPolicy, setCheckPolicy] = useState(false)
    const [checkErrorLoadDoc, setCheckErrorLoadDoc] = useState(null)
    const [loading, setLoading] = useState(false);
    const [inputValues, setInputValues] = useState({}); // Stato per gli input
    const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false); // Stato per la validazione generale
    const [isComplete, setIsComplete] = useState(false);
    const [indexStepDocs, setIndexStepDocs] = useState(null);
    const [indexSteps, setIndexSteps] = useState(2);
    const [filesArray, setFilesArray] = useState([]);
    const [stepCompleted, setStepCompleted] = useState([])
    const [uploatedAllDocs, setUploatedAllDocs] = useState(false)
    const [resultsTypeDocs, setResultsTypeDocs] = useState(null);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false)
    const [documentsAccepted, setDocumentsAccepted] = useState(false);
    const [policyAccepted, setPolicyAccepted] = useState(false);
    const [resultUser, setResultUser] = useState(null);
    const [resultDoc, setResultDoc] = useState(null);
    const [candidate, setCandidate] = useState(null);
    const [countdown, setCountdown] = useState(60); // 60 secondi
    const allResults = useSelector(state => state.allResults.results)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await APIRequest('GET', 'recruitmentService/userContract?idUser=' + JSON.parse(localStorage.getItem('user')).id);
                if (res.data.success) {
                    setDocs(res.data.data);
                } else {
                    console.log(res.data.message);
                }
            } catch (e) {
                console.log(e);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            let userResult = null
            try {
                const res = await APIRequest("GET", `recruitmentService`, null, dispatch);
                if (res.data.success) {
                    userResult = res.data.data.find(el => el.idUser.id === JSON.parse(localStorage.getItem('user')).id);
                    if (userResult) {
                        setResultUser(userResult);
                    }
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            } catch (e) {
                if (e.code === "ERR_NETWORK") {
                    const find = allResults.find(el => el.title === e.config.url.split("/").pop());
                    toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                    if (find) setResultUser(find.results);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                }
                console.error(e);
            }

            await APIRequest('GET', 'documentsService')
                .then((res) => {
                    if (res.data.success) {
                        setResultDoc(res.data.data.find(el => el?.id === userResult?.idDocument?.id));
                    } else {
                        console.log(res.data.message)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        fetchData();
    }, [dispatch, allResults]);

    useEffect(() => {
        if (docs?.[0]?.documents) {
            // Ottieni tutti i typeDoc richiesti in docs[0].documents
            const requiredDocs = docs[0].documents
                .filter((doc) => doc.required && doc.typeDoc) // Filtra solo i documenti richiesti e con typeDoc
                .map((doc) => doc.typeDoc);

            // Ottieni tutti i typeDoc presenti in stepCompleted
            const completedDocs = stepCompleted
                .filter((step) => step.step?.typeDoc) // Filtra step con typeDoc definito
                .map((step) => step.step.typeDoc);

            // Verifica se tutti i requiredDocs sono inclusi nei completedDocs
            const uploatedAllDocs = requiredDocs.every((requiredTypeDoc) =>
                completedDocs.includes(requiredTypeDoc)
            );

            // Aggiorna lo stato in base alla verifica
            setUploatedAllDocs(uploatedAllDocs);
        }
    }, [docs, stepCompleted]); // Monitora i cambiamenti di docs e stepCompleted

    useEffect(() => {
        // Attiva il countdown solo quando deleteConfirmed è true
        if (deleteConfirmed) {
            const interval = setInterval(() => {
                setCountdown(prevCountdown => {
                    if (prevCountdown <= 1) {
                        clearInterval(interval); // Ferma l'aggiornamento quando il timer raggiunge 0
                        navigate('/'); // Reindirizza alla homepage
                        return 0;
                    }
                    return prevCountdown - 1;
                });
            }, 1000); // Aggiornamento ogni secondo

            // Cleanup dell'intervallo
            return () => clearInterval(interval);
        }
    }, [navigate, deleteConfirmed]);

    // Inizializza inputValues basandoti su docs[0].fields
    useEffect(() => {
        if (docs !== null && docs[0]?.fields) {
            const initialValues = {};
            docs[0].fields.forEach((field) => {
                initialValues[field.id] = field.value || ""; // Inizializza con valore corrente o stringa vuota
            });
            setInputValues(initialValues);
        }
    }, [docs]);

    // Funzione per aggiornare i valori degli input
    const handleInputChange = (id, newValue) => {
        setInputValues((prevValues) => ({
            ...prevValues,
            [id]: newValue,
        }));

        // Aggiorna anche il valore in `docs[0].fields` (senza modificare la struttura originale)
        docs[0].fields.find((field) => field.id === id).value = newValue;
    };

    // Effetto per controllare se tutti i campi obbligatori sono compilati
    useEffect(() => {
        const checkAllFieldsFilled = () => {
            if (docs) {
                return docs
                    .filter((step) => step?.fields) // Considera solo i passi con campi
                    .every((step) =>
                        step?.fields?.every(
                            (field) => field.value !== null && field.value.trim() !== ""
                        )
                    );
            }
            return false;
        };

        // Aggiorna lo stato di completamento
        setIsAllFieldsFilled(checkAllFieldsFilled());
    }, [docs, inputValues]); // Dipendenze includono `inputValues`


    const scrollToElement = (index) => {
        if (width < 900 && containerRef.current) {
            const container = containerRef.current;
            // Otteniamo la lista degli elementi li all'interno di Steps
            const stepList = container.querySelectorAll('.p-steps-item');

            // Controlliamo se l'elemento esiste
            if (container && stepList[index] && width < 899 && (indexStepDocs > 0 && indexStepDocs < docs.length - 1)) {
                const element = stepList[index];
                const containerRect = container.getBoundingClientRect();
                const elementRect = element.getBoundingClientRect();

                // Calcolo lo scroll necessario per centrare l'elemento
                const offset = (elementRect.left - containerRect.left) - (containerRect.width / 2) + (elementRect.width / 2);

                // Imposta lo scroll in modo fluido
                container.scrollTo({
                    left: container.scrollLeft + offset,
                    behavior: 'smooth'
                });
            }
        }
    };

    useEffect(() => {
        // Scrolla all'elemento selezionato quando l'indice cambia
        scrollToElement(indexStepDocs);
    }, [indexStepDocs]);

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'documentsService/documentType', null, dispatch)
                .then((res) => {
                    if (res.data.success) {
                        setResultsTypeDocs(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            setResultsTypeDocs(find.results)
                        }
                    }
                    console.log(e);
                });
            await APIRequest('GET', 'documentsService/user?userId=' + JSON.parse(localStorage.getItem("user")).id)
                .then((res) => {
                    if (res.data.success) {
                        setDocumentsUser(res.data.data);
                    } else {
                        console.log(res.data.message)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        trovaRisultato();
        dispatch(resetPathCrumb());
    }, [dispatch]);

    useEffect(() => {
        if (docs) {
            docs[0].documents.forEach((_, index) => {
                if (!fileRefs.current[index]) {
                    fileRefs.current[index] = React.createRef();
                }
            });
        }
    }, [docs]);

    const onTemplateSelect = async (e, indexStepDocs) => {
        const currentFileRef = fileRefs.current[indexStepDocs]; // Accesso al riferimento specifico
        setIndexStepDocs(indexStepDocs)

        if (currentFileRef && currentFileRef.current) {
            const files = e.files;
            let _filesArray = [...filesArray];
            let _totalSize = _filesArray.find(el => el?.step?.id === docs[0].documents[indexStepDocs]?.id)?.totalSize || 0;

            Object.keys(files).forEach((key) => {
                _totalSize += files[key].size || 0;
            });


            const formData = new FormData();
            formData.append('file', new Blob([_filesArray.find(el => el?.step?.id === docs[0].documents[indexStepDocs].id)?.fileBuffer], { type: _filesArray.find(el => el?.step?.id === docs[0].documents[indexStepDocs].id)?.fileUpload?.type }), _filesArray.find(el => el?.step?.id === docs[0].documents[indexStepDocs].id)?.fileUpload?.name.split(" ").join("_"));
            formData.append('document_type', resultsTypeDocs.find(el => el.name === docs[0].documents[indexStepDocs].typeDoc)?.id);
            formData.append('metadata', "{}");
            formData.append('description', "");
            formData.append('title', `${_filesArray.find(el => el?.step?.id === docs[0].documents[indexStepDocs].id)?.titleDoc.split(" ").join("_")}`);
            formData.append('url', "/");
            formData.append('idUser', JSON.parse(localStorage.getItem('user'))?.id);
            formData.append('path', "/");


            await APIRequest('POST', 'documentsService', formData)
                .then(res => {
                    if (res.data.success) {
                        if (files) {
                            const file = {
                                step: docs[0].documents[indexStepDocs],
                                titleDoc: files[0].name.split(".")[0],
                                fileUpload: files[0],
                                fileBuffer: files[0],
                                totalSize: _totalSize
                            };
                            _filesArray.push(file);
                            setFilesArray(_filesArray);
                        }
                        const formDataObj = {
                            formData,
                            step: docs[0].documents[indexStepDocs]
                        };

                        setStepCompleted(prev => [...prev, formDataObj]);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch(e => {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                });
        }
    };


    const onTemplateUpload = (e) => {
        toast.current.show({ severity: 'info', summary: 'Successo', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        let _filesArray = [...filesArray];
        let _stepCompleted = [...stepCompleted];
        let findIndex
        let filter = []
        findIndex = _filesArray.findIndex(el => el?.step?.id === indexStepDocs)
        if (findIndex >= 0) {
            filter = _filesArray.filter(el => el?.step?.id !== indexStepDocs)
            setFilesArray(filter);
            filter = _stepCompleted.filter(el => el?.step?.id !== indexStepDocs)
            setStepCompleted(filter);
        }
        callback();
    };

    const onTemplateClear = (e, index) => {
        let _filesArray = [...filesArray];
        let _stepCompleted = [...stepCompleted];
        let findIndex
        let filter = []
        findIndex = _filesArray.findIndex(el => el?.step?.id === index)
        if (findIndex >= 0) {
            filter = _filesArray.filter(el => el?.step?.id !== index)
            setFilesArray(filter);
            filter = _stepCompleted.filter(el => el?.step?.id !== index)
            setStepCompleted(filter);
        }
    };

    const headerTemplate = (options, indexStepDocs) => {
        const { className, chooseButton, cancelButton } = options;

        const value = filesArray.find(el => el?.step?.typeDoc === docs[0].documents.find(ele => ele.id === indexStepDocs)?.typeDoc)?.totalSize || 0 / 10000;
        const formatedValue = fileRefs.current[indexStepDocs] && fileRefs.current[indexStepDocs].current
            ? fileRefs.current[indexStepDocs].current.formatSize(filesArray.find(el => el?.step?.id === docs[0].documents.find(ele => ele.id === indexStepDocs)?.id)?.totalSize || 0)
            : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                {chooseButton}
                {cancelButton}
                <div className="flex max-[380px]:flex-col items-center min-[380px]:gap-3 max-[400px]:w-9/12">
                    <span>{formatedValue} / 5 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props, indexStepDocs) => {
        const fileData = filesArray.find(el => el?.step?.id === docs[0].documents[indexStepDocs].id); // Accedi al file specifico usando indexStepDocs

        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {fileData?.fileBuffer?.name || 'No file selected'}
                        <small className='text-gray-400'>{props.formatSize}</small>
                    </span>
                </div>
                <Button
                    type="button"
                    icon="pi pi-times"
                    className="p-button-outlined p-button-rounded p-button-danger ml-auto"
                    onClick={() => onTemplateRemove(fileData?.fileBuffer, props.onRemove)}
                />
            </div>
        );
    };

    const emptyTemplate = (indexStepDocs) => {
        return (
            <div className="flex items-center flex-row">
                <i className="pi pi-file-arrow-up mt-3 p-5 text-sky-500"
                    style={{ fontSize: '3em' }}
                    onClick={() => {
                        const input = fileRefs.current[indexStepDocs].current && fileRefs.current[indexStepDocs].current.getInput();
                        if (input) input.click();
                    }}
                ></i>
                <div className='flex flex-col items-center'>
                    <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="mt-5 mb-2">
                        <span className='text-sky-500 underline cursor-pointer mr-1'
                            onClick={() => {
                                const input = fileRefs.current[indexStepDocs].current && fileRefs.current[indexStepDocs].current.getInput();
                                if (input) input.click();
                            }}>
                            Clicca per caricare
                        </span>
                        oppure trascina e rilascia un file
                    </span>
                    <span className='text-gray-400'>PDF, DOC, DOCX, PNG, JPG (max. 5MB)</span>
                </div>
            </div>
        );
    };

    const handleConfirmUpload = async () => {
        if (stepCompleted.length > 0) {
            if (stepCompleted.find(el => el?.step?.label === "Contratto") || documentsUser.find(el => el.idDocument.document_type.name === "ContrattoFirmato")) {
                let _CheckErrorLoadDoc
                for (let index = 0; index < stepCompleted.length; index++) {
                    if (_CheckErrorLoadDoc) { break; }
                    const element = stepCompleted[index];
                    /* await APIRequest('POST', 'documentsService', element.formData)
                        .then(res => {
                            if (res.data.success) {
                                // Aggiorna la progress bar in base al numero di step completati
                                setProgressAmount(((index + 1) / stepCompleted.length) * 100);

                                if (index === stepCompleted.length - 1) {
                                    setLoading(false);
                                    setCheckErrorLoadDoc(false);
                                    setTimeout(() => reverseAnimation(), 300); // Completa l'animazione dopo l'ultimo step
                                }
                            } else {
                                setLoading(false);
                                setCheckErrorLoadDoc(true)
                                _CheckErrorLoadDoc = true
                                toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            setLoading(false);
                            setCheckErrorLoadDoc(true)
                            _CheckErrorLoadDoc = true
                            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                        }); */
                }
            } else {
                setLoading(false);
                setCheckErrorLoadDoc(true)
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non hai caricato il contratto firmato`, life: 3000 });
            }
        } else {
            setLoading(false);
            setCheckErrorLoadDoc(true)
            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non hai caricato nessun file`, life: 3000 });
        }
    };
    const reverseAnimation = () => {
        setIsComplete(false);
    };

    useEffect(() => {
        // Funzione che esegue il progresso
        const intervalId = setTimeout(() => {
            const interval = setInterval(() => {
                setProgressAmount((prev) => {
                    const nextProgress = prev + 10;
                    if (nextProgress >= 100) {
                        clearInterval(interval);
                        setTimeout(() => {
                            reverseAnimation();
                        }, 300);
                    }
                    return nextProgress;
                });
            }, 300);
        }, 2000);

        return () => clearTimeout(intervalId); // Cleanup function
    }, []);

    useEffect(() => {
        if (progressBarRef.current) {
            progressBarRef.current.style.width = `${progressAmount}%`;
        }
    }, [progressAmount]);

    const getDocumentDescription = (typeDoc) => {
        switch (typeDoc) {
            case "DocumentoIdentitaFronte":
                return "Carica il fronte del tuo documento di identità (es. carta d'identità o passaporto).";
            case "DocumentoIdentitaRetro":
                return "Carica il retro del tuo documento di identità (es. carta d'identità o passaporto).";
            case "Patente":
                return "Carica una copia della tua patente, se applicabile.";
            case "CertificatoIva":
                return "Carica il certificato di attribuzione della tua Partita IVA.";
            case "TitoloStudio":
                return "Carica il documento che attesta il tuo titolo di studio.";
            case "CreditoEcm":
                return "Carica i crediti ECM necessari per l'esercizio della professione.";
            case "PolizzaAssicurativa":
                return "Carica una copia della tua polizza assicurativa professionale.";
            case "Curriculum":
                return "Carica una copia aggiornata del tuo curriculum vitae firmato.";
            case "ContrattoFirmato":
                return "Carica il contratto firmato, se disponibile.";
            default:
                return "Carica il documento richiesto per completare la tua candidatura.";
        }
    };

    const downloadFile = async () => {
        if (resultDoc?.file && resultDoc?.file?.data) {
            // Crea un blob dal file base64
            const byteCharacters = atob(resultDoc?.file?.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
                type:
                    resultDoc?.url.includes("jpg") ? "image/jpeg" :
                        resultDoc?.url.includes("png") ? "image/png" :
                            resultDoc?.url.includes("pdf") ? "application/pdf" :
                                ""
            });

            // Crea un URL temporaneo dal blob
            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl, "_blank");
        }
    }

    const statusCandidatura = (rowData) => {
        const statusMapping = {
            "Candidato": "è in attesa di valutazione",
            "Valutazione": "è in attesa di approvazione",
            "Approvato": "è approvata ma in attesa di revisione",
            "Rifiutato": "è stata rifiutata",
            "Ritirata": "è stata ritirata",
            "Errore": "ha riscontrato degli errori",
            "Avallato": "è stata approvata",
        };
        return `Lo stato della tua candidatura ${statusMapping[rowData?.status?.label] || "non è determinabile"}`;
    };

    const deleteCandidature = () => {
        setDeleteConfirm(true);
    }

    const deleteConfirmedFunction = () => {
        setDeleteConfirmed(true)
        /* APIRequest('DELETE', `recruitmentService/${resultUser.id}`)
            .then((res) => {
                if (res.data.success) {
                    toast.current.show({ severity: 'success', summary: 'Candidatura eliminata con successo!', detail: '', life: 3000 });
                    setDeleteConfirm(false);
                    auth.signout(() => navigate("/"));
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            })
            .catch((e) => {
                console.error(e);
            }); */
    }

    const chooseOptions = { icon: 'pi pi-fw pi-plus', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined h-7 w-7 hidden' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined h-7 w-7' };

    return (
        <>
            <div className="flex flex-col h-screen relative">
                <Toast ref={toast} />
                {resultUser ?
                    <>
                        {resultUser?.status?.label !== "Avallato" ?
                            <>
                                <Header />
                                {/* Main Content */}
                                <main className="flex flex-col items-center justify-center flex-grow">
                                    {deleteConfirmed ?
                                        <>
                                            <div className='flex flex-col w-10/12 items-center justify-between my-auto'>
                                                <div className='flex flex-col justify-center items-center rounded-md backdrop-blur-sm bg-[#ffffff63]'>
                                                    <div className='p-4 px-10 rounded-lg w-full flex flex-col items-center'>
                                                        <div className="image">
                                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 7L9.00004 18L3.99994 13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                                        </div>
                                                        <h2 className='text-3xl text-center text-gray-800 mt-2'>La tua candidatura è stata annullata!</h2>
                                                        <p className='text-center text-gray-600'>Ci dispiace che tu abbia deciso di annullare la tua candidatura.</p>
                                                        <Link className='text-xl underline text-sky-500 my-2' to='/'>Torna alla homepage</Link>
                                                        <p className='text-center text-gray-600'>
                                                            Verrai reindirizzato alla homepage automaticamente tra {countdown} secondi.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="bg-[#00b1b1] w-full py-3">
                                                <p className="font-semibold pb-3 text-white text-3xl">Benvenuto, {JSON.parse(window.localStorage.getItem("registry")).firstName} {JSON.parse(window.localStorage.getItem("registry")).lastName}</p>
                                            </div>
                                            <div className="flex flex-col items-center justify-around size-full">
                                                {resultUser ? (
                                                    <div className="content bg-white p-6 rounded-lg shadow-lg w-4/5 max-w-lg mt-5">
                                                        {deleteConfirm ?
                                                            <>
                                                                <p className="text-lg font-medium text-gray-700 mb-4">
                                                                    Sei sicuro di voler ritirare la tua candidatura?
                                                                </p>
                                                                <div className="flex items-center justify-center gap-4">
                                                                    <button
                                                                        onClick={() => deleteConfirmedFunction()}
                                                                        className="bg-gray-500 hover:bg-green-500 shadow-lg shadow-gray-400 hover:shadow-green-300 overflow-hidden flex items-center md:size-14 max-[425px]:size-14 px-2 md:p-0 rounded-lg shadow-md transition-all duration-300 ease-linear hover:w-full group"
                                                                    >
                                                                        <span className="flex items-center justify-center size-12 md:mx-4 max-[425px]:mx-2 group-hover:mx-2 transition-all duration-300">
                                                                            <i className="pi pi-check text-white text-2xl transform transition-transform duration-200"></i>
                                                                        </span>
                                                                        <span className="text-white font-bold md:text-2xl text-nowrap uppercase transition-opacity duration-300 md:ml-2 max-[425px]:ml-2 group-hover:mx-3 delay-200 w-full">
                                                                            Conferma cancellazione
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <p className="text-lg font-medium text-gray-700 mb-4">
                                                                    {statusCandidatura(resultUser)}
                                                                </p>
                                                                <div className="mb-4">
                                                                    <p className="text-gray-600">
                                                                        <strong>Posizione:</strong> {resultUser.jobPosition.title}
                                                                    </p>
                                                                    <p className="text-gray-600">
                                                                        <strong>Data candidatura:</strong> {
                                                                            new Intl.DateTimeFormat("it-IT", {
                                                                                day: "2-digit",
                                                                                month: "2-digit",
                                                                                year: "numeric",
                                                                            }).format(new Date(resultUser.appliedAt))
                                                                        }
                                                                    </p>
                                                                </div>
                                                                <div className="flex max-[500px]:flex-col max-[320px]:flex-row items-center justify-between">
                                                                    <button
                                                                        onClick={() => deleteCandidature()}
                                                                        className="bg-red-500 hover:bg-red-600 overflow-hidden flex items-center max-[320px]:justify-center min-[501px]:size-14 max-[500px]:w-full rounded-lg shadow-md transition-all duration-300 ease-linear hover:w-full group"
                                                                    >
                                                                        <span className="flex items-center justify-center size-12 min-[501px]:mx-3 group-hover:mx-0 transition-all duration-300">
                                                                            <i className="pi pi-trash text-white text-2xl transform transition-transform duration-200"></i>
                                                                        </span>
                                                                        <span className="text-white max-[320px]:hidden max-[500px]:w-full font-bold text-sm text-nowrap uppercase transition-opacity duration-300 min-[501px]:ml-1 group-hover:mx-3 delay-200">
                                                                            Ritira candidatura
                                                                        </span>
                                                                    </button>
                                                                    <Button
                                                                        onClick={() => downloadFile()}
                                                                        label={`${width > 320 ? "Visualizza il tuo CV" : ""}`}
                                                                        icon="pi pi-eye"
                                                                        className="flex items-center justify-center max-[899px]:!p-3 min-[900px]:!p-4 !w-full buttonCustom select-none"
                                                                    />
                                                                </div>
                                                            </>
                                                        }
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col items-center justify-center size-full">
                                                        <Loader />
                                                        <p className="text-gray-500 mt-3">Caricamento dei dati della candidatura...</p>
                                                    </div>
                                                )}
                                                <div></div>
                                            </div>
                                        </>
                                    }
                                </main>

                                {/* Waves Background */}
                                <div className="waves-container">
                                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none">
                                        <defs>
                                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                                        </defs>
                                        <g className="parallax">
                                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                                        </g>
                                    </svg>
                                </div>
                            </>
                            :
                            <>
                                <Header />
                                {docs &&
                                    <div className='flex flex-col h-full justify-center items-center w-full bg-white'>
                                        <div className={`flex-col size-full`}>
                                            <div className='w-full overflow-x-scroll py-5 stepsMobile' ref={containerRef}>
                                                <Steps model={steps} activeIndex={indexSteps} readOnly={false} />
                                            </div>
                                            <div className='flex flex-col items-center gap-3 w-full overflow-y-scroll min-h-[calc(100vh-300px)] max-h-[calc(100vh-300px)] shadow-inner-b my-3'>
                                                <>
                                                    {(indexSteps === 0) ? (
                                                        <>
                                                            {docs[0].fields !== null ?
                                                                <div className='w-full p-5 border flex flex-col items-center'>
                                                                    <p className="text-sm text-gray-500 mb-4 w-full text-start">
                                                                        Compila i campi con le tue informazioni personali. Tutti i campi contrassegnati sono obbligatori.
                                                                    </p>
                                                                    <p className="text-sm text-gray-500 mb-4 w-full text-start">
                                                                        <b>Attenzione:</b> i campi con (*) sono obbligatori
                                                                    </p>
                                                                    <div className="grid xl:grid-cols-6 sm:grid-cols-4 grid-cols-2 gap-4 w-full">
                                                                        {docs[0]?.fields.map((field) => (
                                                                            <React.Fragment key={field.id}>
                                                                                {field.show && (
                                                                                    <div className="col-span-2">
                                                                                        <div className="coolinput">
                                                                                            <label
                                                                                                htmlFor={`input-${field.id}`}
                                                                                                className={`text ${inputValues[field.id] ? "text-sky-500" : "text-gray-500"
                                                                                                    }`}
                                                                                            >
                                                                                                {field.label}
                                                                                                {field.required ? " (*)" : ""}
                                                                                            </label>
                                                                                            <input
                                                                                                type="text"
                                                                                                id={`input-${field.id}`}
                                                                                                name={`input-${field.id}`}
                                                                                                placeholder={field.label}
                                                                                                className={`input ${inputValues[field.id]
                                                                                                    ? "border border-sky-500"
                                                                                                    : "border border-gray-500"
                                                                                                    }`}
                                                                                                value={inputValues[field.id] || ""}
                                                                                                onChange={(e) => handleInputChange(field.id, e.target.value)}
                                                                                                required={field.required}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </React.Fragment>
                                                                        ))}
                                                                        <div className="col-span-full">
                                                                            {isAllFieldsFilled ? (
                                                                                <p className="text-green-500">Tutti i campi obbligatori sono compilati!</p>
                                                                            ) : (
                                                                                <p className="text-red-500">Compila tutti i campi obbligatori.</p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <p className='text-center text-gray-500'>
                                                                    Errore: non sono prensenti fields all'interno del contratto
                                                                </p>
                                                            }
                                                        </>
                                                    ) : ((indexSteps === 1) ?
                                                        <>
                                                            {docs[0].documents !== null ?
                                                                <>
                                                                    {docs[0].documents.map((element, index) => {
                                                                        return (
                                                                            <React.Fragment key={index}>
                                                                                {(element.show && element.typeDoc) && (
                                                                                    <div className='w-full p-5 border flex flex-col items-center'>
                                                                                        <div className='text-fluid0 text-start w-full'>{element.label}{element.required ? " (*)" : ""}</div>
                                                                                        <p className="text-sm text-gray-500 mb-4 w-full text-start">
                                                                                            {getDocumentDescription(element.typeDoc)}
                                                                                        </p>
                                                                                        <p className="text-sm text-gray-500 mb-4 w-full text-start">
                                                                                            <b>Attenzione:</b> i campi con (*) sono obbligatori
                                                                                        </p>
                                                                                        <div className='size-full flex flex-row items-center justify-center mt-3 candidate'>
                                                                                            <FileUpload
                                                                                                ref={fileRefs.current[index]}
                                                                                                name="demo[]"
                                                                                                url="/api/upload"
                                                                                                accept="*"
                                                                                                maxFileSize={5000000}
                                                                                                onUpload={(e) => onTemplateUpload(e, index)}
                                                                                                onSelect={(e) => onTemplateSelect(e, index)}
                                                                                                onError={onTemplateClear}
                                                                                                onClear={onTemplateClear}
                                                                                                headerTemplate={(options) => headerTemplate(options, index)}
                                                                                                itemTemplate={(file, props) => itemTemplate(file, props, index)}
                                                                                                emptyTemplate={() => emptyTemplate(index)}
                                                                                                chooseOptions={chooseOptions}
                                                                                                cancelOptions={cancelOptions}
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </React.Fragment>
                                                                        )
                                                                    })}
                                                                </>
                                                                :
                                                                <p className='text-center text-gray-500'>
                                                                    Errore: non sono prensenti documents all'interno del contratto
                                                                </p>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className='w-full h-full flex flex-col items-center justify-between'>
                                                                <div className='flex flex-col items-center justify-start w-full'>
                                                                    <div className='flex flex-col w-full'>
                                                                        <FrameContratto candidato={resultUser} finalContract={docs[0].idDocument} />
                                                                        <div className='flex flex-col justify-start'>
                                                                            <div className='flex flex-row items-center gap-3'>
                                                                                <Checkbox disabled={loading} onChange={e => setPolicyAccepted(e.checked)} checked={policyAccepted}></Checkbox>
                                                                                <label onClick={() => setPolicyAccepted(!policyAccepted)} htmlFor="acceptPolicy" className='text-gray-500 text-start'>
                                                                                    Dichiaro di aver letto e compreso le normative sulla sicurezza dei dati e di assumermi piena responsabilità in merito. *
                                                                                </label>
                                                                            </div>
                                                                            {(!policyAccepted && checkPolicy) &&
                                                                                <p className='text-start text-red-500 text-start'>È necessario accettare le normative sulla sicurezza dei dati.</p>
                                                                            }
                                                                        </div>
                                                                        <div className='flex flex-col justify-start'>
                                                                            <div className='flex flex-row items-center gap-3'>
                                                                                <Checkbox disabled={loading} onChange={e => setDocumentsAccepted(e.checked)} checked={documentsAccepted}></Checkbox>
                                                                                <label onClick={() => setDocumentsAccepted(!documentsAccepted)} htmlFor="acceptDocuments" className='text-gray-500 text-start'>
                                                                                    Dichiaro che tutti i documenti caricati sono autentici e leggibili e confermo di non aver dichiarato il falso. *
                                                                                </label>
                                                                            </div>
                                                                            {(!documentsAccepted && checkPolicy) &&
                                                                                <p className='text-start text-red-500 text-start'>È necessario accettare la dichiarazione di responsabilità sui documenti.</p>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {loading &&
                                                                    <div id="processing" className={`${isComplete ? "complete" : "uncomplete"} w-full`}>
                                                                        <div className="wrapperr flex flex-col items-center justify-center">
                                                                            <Loader />
                                                                            {checkErrorLoadDoc ?
                                                                                <svg className="checkmark checkmark-success" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                                                    <circle className="checkmark-circle" cx="25" cy="25" r="25" fill="none" />
                                                                                    <path className="checkmark-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                                                                                </svg>
                                                                                :
                                                                                <svg className="checkmark checkmark-error" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
                                                                                    <circle className="checkmark-circle" cx="25" cy="25" r="25" fill="none" />
                                                                                    <path className="checkmark-check" fill="none" d="M16,34L25 25 34 16 " />
                                                                                    <path className="checkmark-check" fill="none" d="M16,16L25 25 34 34 " />
                                                                                </svg>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                }
                                                                <div className='w-full flex flex-row justify-end'>
                                                                    <Button type="button" disabled={loading || !documentsAccepted || !policyAccepted} label={`Invia`} className="buttonCustom !mb-5" onClick={() => { ((policyAccepted && documentsAccepted) && handleConfirmUpload()); setCheckPolicy(true); ((policyAccepted && documentsAccepted && stepCompleted.length > 0) && setLoading(true)) }} />
                                                                </div>
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            </div>
                                            <div className='w-full px-6 grid grid-cols-5 max-[1100px]:grid-cols-3 gap-5 items-start'>
                                                <Button
                                                    className={`${indexSteps === 0 ? "!hidden" : ""} max-[1100px]:col-span-1 col-start-1 buttonCustom`}
                                                    label={`${width > 480 ? `Indietro` : ""}`}
                                                    /* disabled={!isAllFieldsFilled} */
                                                    icon={`pi pi-arrow-left`}
                                                    onClick={() => (setIndexSteps(indexSteps - 1))}>
                                                </Button>
                                                <Button
                                                    className={`buttonCustom max-[1100px]:col-span-1 col-start-4 max-[1100px]:col-span-1 max-[1100px]:col-start-3 min-[1101px]:col-start-5`}
                                                    label={`${width > 480 ? `${indexSteps === 2 ? "Firma" : "Avanti"}` : ""}`}
                                                    disabled={indexSteps === 0 ? !isAllFieldsFilled : (indexSteps === 1 ? !uploatedAllDocs : false)}
                                                    icon={`${indexSteps === 2 ? "pi pi-save" : "pi pi-arrow-right"}`}
                                                    onClick={() => (indexSteps === 2 ? {} : setIndexSteps(indexSteps + 1))}>
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        }
                    </>
                    :
                    <div className="flex flex-col items-center justify-center size-full">
                        <Loader />
                        <p className="text-gray-500 mt-3">Caricamento dei dati della candidatura...</p>
                    </div>
                }
            </div >
        </>
    );
}

export default PageCandidato;