import { useDispatch } from "react-redux";
import { useEffect } from "react";
import HeadPage from "../../components/headPage";
import { resetPathCrumb } from "../../store/actions/actions";
import Prestazioni from "./prestazioni";
import Feedback from "./feedback";
import Candidature from "./candidature";
import Rendicontazione from "./rendicontazione";
import Home from "./home";
function Dashboard(props) {
    const dispatch = useDispatch()
    const user = JSON.parse(window.localStorage.getItem("user"))

    useEffect(() => {
        dispatch(resetPathCrumb())
    }, [dispatch])  

    return (
        <div className="flex flex-col">
            <HeadPage />
            <div className="flex flex-col overflow-y-scroll max-h-[89vh]">
                <div className="flex flex-row justify-center items-center bg-[#00b1b1]">
                    <p className="font-semibold pb-3 text-white text-3xl">Benvenuto, {JSON.parse(window.localStorage.getItem("registry")).firstName} {JSON.parse(window.localStorage.getItem("registry")).lastName}</p>
                </div>
                {user.role.label === "ADMIN" ?
                    <Home />
                    :
                    <>
                        {user.role.label === "OPERATOR" ?
                            <Prestazioni isDashboard={true} />
                            :
                            <>
                                {user.role.label === "PATIENT" ?
                                    <Feedback isDashboard={true} />
                                    :
                                    <>
                                        {user.role.label === "SUPERVISOR" ?
                                            <Home />
                                            :
                                            <>
                                                {user.role.label === "HR" ?
                                                    <Candidature isDashboard={true} />
                                                    :
                                                    <>
                                                        {user.role.label === "ACCOUNTANT" ?
                                                            <Rendicontazione isDashboard={true} />
                                                            :
                                                            <>
                                                                {user.role.label === "SECRETARY" ?
                                                                    <></>
                                                                    :
                                                                    <>
                                                                        {user.role.label === "ASL" &&
                                                                            <></>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                    </>
                }
            </div>
        </div>
    )
}

export default Dashboard;
