import React, { useEffect, useState } from "react";
import { APIRequest } from "../services/axios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

function CaricamentoDocs(props) {
    const [results, setResults] = useState(null);
    const [dialogDeleteDocs, setDialogDeleteDocs] = useState(false)
    const [resultDocs, setResultDocs] = useState(null)

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'documentsService/user?userId=' + (props.result?.idUser ? props.result?.idUser?.id : props.result?.id))
                .then((res) => {
                    if (res.data.success) {
                        setResults(res.data.data);
                    } else {
                        console.log(res.data.message)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        trovaRisultato();
    }, []);

    const openDoc = async (doc) => {
        // Se il documento contiene un file
        if (doc.file && doc.file.data) {
            // Crea un blob dal file base64
            const byteCharacters = atob(doc.file.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
                type:
                    doc.url.includes("jpg") ? "image/jpeg" :
                        doc.url.includes("png") ? "image/png" :
                            doc.url.includes("pdf") ? "application/pdf" :
                                ""
            });

            // Crea un URL temporaneo dal blob
            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl, "_blank");
        }
    };

    const deleteDocs = async () => {
        await APIRequest('DELETE', 'documentsService?id=' + resultDocs.id)
            .then((res) => {
                if (res.data.success) {
                    let filter = results.filter(el => el.id !== resultDocs.id)
                    setResults(filter);
                } else {
                    console.log(res.data.message)
                }
            })
            .catch((e) => {
                console.log(e);
            });
    };

    const checkDocs = (obj) => {
        console.log(obj)
    }

    const handleDialogFooter = () => {
        return (
            <React.Fragment>
                <Button label="Sì" onClick={() => { setDialogDeleteDocs(false); deleteDocs() }} className="buttonCustom p-button-success mr-2" />
                <Button label="No" onClick={() => { setDialogDeleteDocs(false) }} className="buttonCustom p-button-secondary" />
            </React.Fragment>
        )
    }


    return (
        <div className="flex flex-col items-center w-full justify-between h-full">
            {results ? (
                <>
                    {results.length > 0 ?
                        <>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 w-full min-[900px]:max-h-[40vh] min-[900px]:overflow-y-scroll">
                                {results.map((obj, index) => (
                                    <React.Fragment key={index}>
                                        <div className="border border-[#00b1b14D] p-2 rounded-lg py-3 flex flex-col items-center justify-start">
                                            <div className="flex flex-col items-center justify-center transition ease-in duration-300" onClick={() => openDoc(obj.idDocument)}>
                                                <i className="pi pi-file text-4xl"></i>
                                                <div className="text-center text-2xl break-all">{obj.idDocument.title}</div>
                                            </div>

                                            {/* Controlla se l'elemento attuale è aperto */}
                                            <div className={`flex flex-row gap-4 mt-2 transition ease-in duration-300`}>
                                                {obj.idDocument?.document_type?.name === "ContrattoFirmato" &&
                                                    <Button icon={"pi pi-check"} className="border border-[#00b1b14D] hover:bg-[#00b1b1] hover:text-white h-10 w-10 rounded-lg" onClick={() => checkDocs(obj.idDocument)}></Button>
                                                }
                                                <Button icon={"pi pi-trash"} className="border border-[#00b1b14D] hover:bg-[#00b1b1] hover:text-white h-10 w-10 rounded-lg" onClick={() => { setResultDocs(obj.idDocument); setDialogDeleteDocs(true) }}></Button>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                ))}
                            </div>
                        </>
                        :
                        <>
                            <p>Non ci sono documenti per questo utente</p>
                        </>
                    }
                </>
            ) : (
                <>
                    <i className="pi pi-spin pi-spinner" style={{ fontSize: "2rem" }}></i>
                </>
            )}
            <Dialog className='text-fluid1 max-w-[70vh]' visible={dialogDeleteDocs} breakpoints={{ '960px': '75vw', '641px': '90vw' }} header={() => <><i className="pi pi-exclamation-triangle mr-3" />Avviso</>} modal footer={handleDialogFooter} onHide={() => setDialogDeleteDocs(false)}>
                <div className="flex flex-col items-start justify-center">
                    <p className='text-xl'><b className='text-red-400'>ATTENZIONE:</b> Sicuro di voler cancellare il documento ?</p>
                </div>
            </Dialog>
        </div>
    );
}

export default CaricamentoDocs;
