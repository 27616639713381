import { Chart } from "primereact/chart";
import { GoScreenFull } from "react-icons/go";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useWindowSize } from "../utils/utils";

const CustomDataChart = (props) => {
    const [data, setData] = useState(null);
    const [dataChart, setDataChart] = useState(null);


    useEffect(() => {
        const dataProvvisorio = props.datasets ? props.datasets : props.type2 ? {
            labels: props.labels,
            datasets:
                [
                    {
                        type: props.type,
                        label: props.label,
                        data: props.data,
                        backgroundColor: props.backgroundColor,
                        borderColor: props.borderColor,
                        borderWidth: 1,
                        pointRadius: 5, // Aggiungi la dimensione dei punti
                        pointHoverRadius: 7, // Aggiungi la dimensione dei punti al passaggio del mouse
                    },
                    {
                        type: props.type2,
                        label: props.label2,
                        data: props.data2,
                        backgroundColor: props.backgroundColor2,
                        borderColor: props.borderColor2,
                        borderWidth: 1,
                        pointRadius: 5, // Aggiungi la dimensione dei punti
                        pointHoverRadius: 7, // Aggiungi la dimensione dei punti al passaggio del mouse
                    }
                ]
        } : {
            labels: props.labels,
            datasets:
                [
                    {
                        type: props.type,
                        label: props.label,
                        data: props.data,
                        backgroundColor: props.backgroundColor,
                        borderColor: props.borderColor,
                        borderWidth: 1,
                        pointRadius: 5, // Aggiungi la dimensione dei punti
                        pointHoverRadius: 7, // Aggiungi la dimensione dei punti al passaggio del mouse
                    }
                ]
        }

        const dataChartProvvisorio = props.aspectRatio ? {
            aspectRatio: props.aspectRatio,
            scales: {
                y: {
                    responsive: true,
                    maintainAspectRatio: false,
                    beginAtZero: true,
                    grid: {
                        color: "gray"
                    },
                    ticks: {
                        color: "gray"
                    }
                },
                x: {
                    grid: {
                        color: "gray"
                    },
                    ticks: {
                        color: "gray"
                    }
                }
            }
        } : {
            scales: {
                y: {
                    responsive: true,
                    maintainAspectRatio: false,
                    beginAtZero: true,
                    grid: {
                        color: "gray"
                    },
                    ticks: {
                        color: "gray"
                    }
                },
                x: {
                    grid: {
                        color: "gray"
                    },
                    ticks: {
                        color: "gray"
                    }
                }
            }
        };
        setData(dataProvvisorio);
        setDataChart(dataChartProvvisorio)
    }, [props])

    useLayoutEffect(() => {
        function updateSize() {
            let _dataChart = { ...dataChart }
            setDataChart(_dataChart)
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);


    return (
        <>
            {data && dataChart &&
                <Chart data={data} options={dataChart} height={props.height} />
            }
        </>

    )
}

export default CustomDataChart;