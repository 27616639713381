import React, { createContext, useEffect, useRef, useState } from "react";
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { RequireAuth } from "./provider/requireAuth";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveId, setRole } from './store/actions/actions.js';
import { AuthProvider } from './provider/authProvider';
import { APIRequest } from "./services/axios.js";
import { getToken } from "firebase/messaging";
import { messaging } from "./firebase/firebaseConfig";
import { onMessage } from "firebase/messaging";
import { Toast } from "primereact/toast";
import Layout from './provider/layout';
import Login from './routes/login';
import Operatori from "./routes/admin/operatori.js";
import Feedback from "./routes/admin/feedback.js";
import Candidature from "./routes/admin/candidature.js";
import Rendicontazione from "./routes/admin/rendicontazione.js";
import Pazienti from "./routes/admin/pazienti.js";
import Prestazioni from "./routes/admin/prestazioni.js";
import Notifiche from "./routes/admin/notifiche.js";
import Configuratore from "./routes/admin/configuratore.js";
import HR from "./routes/admin/hr.js";
import Contabili from "./routes/admin/contabili.js";
import Supervisore from "./routes/admin/supervisore.js";
import Segretari from "./routes/admin/segretari.js";
import ImportFile from "./components/importFile.js";
import AvviaConversazione from "./components/avviaConversazione.js";
import ValutazioneDellaStruttura from "./routes/admin/valutazioneDellaStruttura.js";
import ValutazioneDelServizio from "./routes/admin/valutazioneDelServizio.js";
import QuestionariValutazioneDellaStruttura from "./routes/admin/questionariValutazioneDellaStruttura.js";
import QuestionariValutazioneDelServizio from "./routes/admin/questionariValutazioneDelServizio.js";
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './style/App.css';
import CreaCorso from "./routes/admin/creaCorso.js";
import PageCandidato from "./routes/admin/pageCandidato.js";
import Candidati from "./routes/admin/candidati.js";
import LavoraConNoi from "./routes/lavoraConNoi.js";
import Profile from "./routes/admin/profilo.js";
import Corsi from "./routes/admin/corsi.js";
import Dashboard from "./routes/admin/dashboard.js";
import {
  basePath,
  lavoraConNoiPath,
  pageCandidatoPath,
  dashboardPath,
  operatoriPath,
  pazientiPath,
  profiloPath,
  HRPath,
  contabiliPath,
  supervisorePath,
  segretariPath,
  corsiPath,
  creaCorsoPath,
  employessCarePath,
  valutazioneDellaStrutturaPath,
  valutazioneDelServizioPath,
  questionariValutazioneDellaStrutturaPath,
  questionariValutazioneDelServizioPath,
  candidaturePath,
  candidatiPath,
  rendicontazionePath,
  prestazioniPath,
  notifichePath,
  configuratorePath,
  importPath,
  conversazioniPath,
  aslPath,
  candidatoPath,
  errorPath,
} from "./costant/path.js";
import ASL from "./routes/admin/asl.js";
import Candidate from "./routes/admin/candidate.js";
import ErrorPage from "./routes/errorPage.js";

export let AuthContext = createContext();

function ErrorFallback({ error }) {
  return (
    <div role="alert">
      <p>Qualcosa è andato storto:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  );
}

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/sw.js')
      .then((registration) => {
        console.log('Service Worker registered with scope:', registration.scope);
      })
      .catch((error) => {
        console.log('Service Worker registration failed:', error);
      });
  });
}

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [popup, setPopup] = useState(true)
  const [usernameCopy, setUsernameCopy] = useState(localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.username : undefined)
  const POSTresults = useSelector(state => state.allResults.POSTresults)
  const allResults = useSelector(state => state.allResults.results)
  const toastBC = useRef(null);
  const toastTC = useRef(null);
  const POSTresultsRef = useRef(POSTresults);
  const allResultsRef = useRef(allResults);
  const [token, setToken] = useState(null);
  const [user, setuser] = useState(null);
  let username = localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.username : undefined
  let role = localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.role : undefined
  let idUser = localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.id : null

  async function requestPermission() {
    //requesting permission using Notification API
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey: 'BD0Ab5dxmGz9zMBZAQyCX18ObwA_erBcJ9SAL0v0X2VtFmGER283k5lAgIa3U1PlOU_c7ejvKwqJYLwqqLOLa0Q',
      });
      if (idUser) {
        console.log("Token generated : ", token);
        let body = {
          idUser,
          fcmToken: token
        }
        await APIRequest('POST', `notificationService/saveToken`, body)
          .then(res => {
            console.log(res)
          }).catch((e) => {
            console.log(e);
          })
      }
    } else if (permission === "denied") {
      //notifications are blocked
      alert("You denied for the notification");
    }
  }

  onMessage(messaging, (payload) => {
    toastBC.current.show({
      severity: 'success',
      summary: 'Can you send me the report?',
      life: 3000,
      content: () => (
        <div className="flex flex-col items-left" style={{ flex: '1' }}>
          <div className="flex items-start font-bold text-lg">{payload.notification.title}</div>
          <div className="flex items-start font-medium text-md my-3 text-900">{payload.notification.body}</div>
        </div>
      )
    });
  });

  useEffect(() => {
    requestPermission();
  })

  useEffect(() => {
    async function trovaRisultato() {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/firebase-messaging-sw.js')
            .then((registration) => {
                console.log('Service Worker registered with scope:', registration.scope);

                getToken(messaging, { vapidKey: 'BD0Ab5dxmGz9zMBZAQyCX18ObwA_erBcJ9SAL0v0X2VtFmGER283k5lAgIa3U1PlOU_c7ejvKwqJYLwqqLOLa0Q' })
                    .then(async (currentToken) => {
                        if (currentToken) {
                            if (idUser) {
                                console.log("Token generated : ", currentToken);
                                let body = {
                                    idUser,
                                    fcmToken: currentToken
                                };
                                await APIRequest('POST', `notificationService/saveToken`, body)
                                    .then(res => {
                                        console.log(res);
                                    }).catch((e) => {
                                        console.log(e);
                                    });
                            }
                            console.log('Token:', currentToken);
                        } else {
                            console.log('No registration token available. Request permission to generate one.');
                        }
                    })
                    .catch((err) => {
                        console.error('An error occurred while retrieving token. ', err);
                    });
            })
            .catch((err) => {
                console.error('Service Worker registration failed: ', err);
            });
    }
    }
    trovaRisultato();
  }, [idUser]);


  useEffect(() => {
    async function trovaRisultato() {
      let username = localStorage.getItem('user') && localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user'))?.username : undefined
      if (username) {
        await APIRequest('GET', `authService/checktoken?username=${username}`)
          .then(res => {
            setToken(localStorage.getItem('token'));
            setuser(username);
          }).catch((e) => {
            console.log(e);
            localStorage.setItem('user', '')
            window.location.reload()
          })
      } else {
        localStorage.setItem('user', '')
        localStorage.setItem('token', '')
      }
    }
    trovaRisultato();
  }, [user, token, username])

  const bodyTemplateWarnCompleteProfile = () => {
    return (
      <div className="flex flex-col items-left" style={{ flex: '1' }}>
        <div className="absolute top-4 inset-x-0"><i className="pi pi-bell text-fluid0"></i></div>
        <div className="flex items-center justify-center font-bold text-lg pt-8 cursor-pointer" onClick={() => {navigate(profiloPath); dispatch(setActiveId("/profilo")); setPopup(false); toastTC.current.clear()}}>Completa il tuo profilo <i className="pi pi-arrow-right ml-3"></i></div>
      </div>
    );
  }

  useEffect(() => {
    if(token){
      let completeProfile = JSON.parse(localStorage.getItem('completeProfile'))
      if(username && role.label !== "CANDIDATE" && role.label !== "NON-CANDIDATE"){
        usernameCopy === username ? setPopup(false) : setPopup(true) 
        if (completeProfile?.notExists > 0 && toastTC?.current && popup && usernameCopy !== username) {
          toastTC?.current?.show({
            content: bodyTemplateWarnCompleteProfile,
            sticky: true,
            className:"progressBg"
          });
          setPopup(false)
        }
      } else {
        setUsernameCopy("")
        toastTC?.current?.clear()
      }
    }
  }, [toastTC, token, username])

  useEffect(() => {
    POSTresultsRef.current = POSTresults
  }, [POSTresults])
  useEffect(() => {
    allResultsRef.current = allResults
  }, [allResults])

  useEffect(() => {
    // Funzione che verifica lo stato attuale della connessione
    function checkConnection() {
      if (navigator.onLine) {
        console.log("Sei online");
      } else {
        console.log("Sei offline");
      }
    }

    const bodyTemplateConnection = () => {
      return (
        <div className="flex flex-col items-left" style={{ flex: '1' }}>
          <div className="flex items-start font-bold text-lg">Notifica di connessione</div>
          <div className="flex items-start text-md my-3 text-900">La connessione è stata ripristinata</div>
          <div className="flex flex-row justify-center items-center gap-2 w-full" onClick={() => window.location.reload()} >
            <i className="pi pi-refresh"></i>
            <div className="flex items-center font-medium text-md text-900">Ricarica la pagina</div>
          </div>
        </div>
      );
    }

    // Listener per quando l'utente torna online
    async function handleOnline() {
      console.log(POSTresultsRef.current)
      if (POSTresultsRef.current.length > 0) {
        if (navigator.onLine) {
          for (let i = 0; i < POSTresultsRef.current.length; i++) {
            await APIRequest(POSTresultsRef.current[i].method, POSTresultsRef.current[i].url, POSTresultsRef.current[i].data)
              .then(res => {
                console.log(allResultsRef.current)
              }).catch((e) => {
                console.log(e)
              })
          }
        }
      }
      toastBC.current.show({
        severity: 'success',
        content: bodyTemplateConnection,
        sticky: true,
      });
    }

    // Listener per quando l'utente va offline
    function handleOffline() {
      toastBC.current.show({
        severity: 'warn',
        summary: 'ATTENZIONE!',
        detail: 'Sei offline',
        life: 3000
      });
    }

    // Aggiungi i listener solo quando il componente è montato
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    // Verifica lo stato della connessione quando il componente viene montato
    checkConnection();

    // Cleanup: rimuovi i listener quando il componente viene smontato
    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem('user') !== "" ? JSON.parse(localStorage.getItem('user')) : undefined;
    if (storedUser && storedUser.role) {
      dispatch(setRole(storedUser.role.label));
    }
  }, [dispatch]);

  if (!token && username) {
    return (
      <div className="App">
        <div className="bodyRow d-flex flex-column justify-content-center">
          {/* <Loader /> */}
        </div>
      </div>
    )
  }

  return (
    <div className="App w-full">
      <Toast ref={toastBC} position="bottom-center" />
      <Toast ref={toastTC} position="top-center" />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AuthProvider>
          <Routes>
            <Route path={basePath} element={<Login />} />
            <Route path={errorPath} element={<ErrorPage />} />
            <Route path={lavoraConNoiPath} element={<LavoraConNoi />} />
            <Route path={pageCandidatoPath} element={<RequireAuth token={token}><PageCandidato /></RequireAuth>} />
            <Route path={candidatoPath} element={<RequireAuth token={token}><Candidate /></RequireAuth>} />
            <Route element={<Layout />}>
              <Route path={dashboardPath} element={<RequireAuth token={token}><Dashboard /></RequireAuth>} />
              <Route path={operatoriPath} element={<RequireAuth token={token}><Operatori /></RequireAuth>} />
              <Route path={pazientiPath} element={<RequireAuth token={token}><Pazienti /></RequireAuth>} />
              <Route path={profiloPath} element={<RequireAuth token={token}><Profile /></RequireAuth>} />
              <Route path={HRPath} element={<RequireAuth token={token}><HR /></RequireAuth>} />
              <Route path={contabiliPath} element={<RequireAuth token={token}><Contabili /></RequireAuth>} />
              <Route path={supervisorePath} element={<RequireAuth token={token}><Supervisore /></RequireAuth>} />
              <Route path={segretariPath} element={<RequireAuth token={token}><Segretari /></RequireAuth>} />
              <Route path={corsiPath} element={<RequireAuth token={token}><Corsi /></RequireAuth>} />
              <Route path={creaCorsoPath} element={<RequireAuth token={token}><CreaCorso /></RequireAuth>} />
              <Route path={employessCarePath} element={<RequireAuth token={token}><Feedback /></RequireAuth>} />
              <Route path={valutazioneDellaStrutturaPath} element={<RequireAuth token={token}><ValutazioneDellaStruttura /></RequireAuth>} />
              <Route path={valutazioneDelServizioPath} element={<RequireAuth token={token}><ValutazioneDelServizio /></RequireAuth>} />
              <Route path={questionariValutazioneDellaStrutturaPath} element={<RequireAuth token={token}><QuestionariValutazioneDellaStruttura /></RequireAuth>} />
              <Route path={questionariValutazioneDelServizioPath} element={<RequireAuth token={token}><QuestionariValutazioneDelServizio /></RequireAuth>} />
              <Route path={candidaturePath} element={<RequireAuth token={token}><Candidature /></RequireAuth>} />
              <Route path={candidatiPath} element={<RequireAuth token={token}><Candidati /></RequireAuth>} />
              <Route path={rendicontazionePath} element={<RequireAuth token={token}><Rendicontazione /></RequireAuth>} />
              <Route path={prestazioniPath} element={<RequireAuth token={token}><Prestazioni /></RequireAuth>} />
              <Route path={notifichePath} element={<RequireAuth token={token}><Notifiche /></RequireAuth>} />
              <Route path={configuratorePath} element={<RequireAuth token={token}><Configuratore /></RequireAuth>} />
              <Route path={importPath} element={<RequireAuth token={token}><ImportFile /></RequireAuth>} />
              <Route path={conversazioniPath} element={<RequireAuth token={token}><AvviaConversazione /></RequireAuth>} />
              <Route path={aslPath} element={<RequireAuth token={token}><ASL /></RequireAuth>} />
            </Route>
          </Routes>
        </AuthProvider>
      </ErrorBoundary>
    </div>
  );
}

export default App;
