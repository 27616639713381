import React, { useEffect, useRef, useState } from 'react';
import logoText from "../assets/img/logo_salus_text.png";
import wallpaper from "../assets/img/working.jpg";
import { Toast } from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dropdown } from 'primereact/dropdown';
import { LiaUserNurseSolid } from 'react-icons/lia';
import { FaMobileAlt, FaRegHospital } from 'react-icons/fa';
import { FileUpload } from 'primereact/fileupload';
import { APIRequest } from '../services/axios';
import { PiReadCvLogo } from 'react-icons/pi';
import { Link } from 'react-router-dom';

function LavoraConNoi() {
    const toast = useRef(null);
    const fileRef = useRef(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cellnum, setCellNum] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [messaggio, setMessaggio] = useState('');
    const [checked, setChecked] = useState(false);
    const [fileBuffer, setFileBuffer] = useState(null);
    const [fileUpload, setFileUpload] = useState(null);
    const [titleDoc, setTitleDoc] = useState('');
    const [redirectThx, setRedirectThx] = useState(false);
    const [resultsTypeDocs, setResultsTypeDocs] = useState(null);
    const [resultsRole, setResultsRole] = useState(null);

    const asl = [
        { name: '--', code: 0 },
        { name: 'asl rm 1', code: 1 },
        { name: 'asl rm 2', code: 2 },
        { name: 'asl rm 3', code: 3 },
        { name: 'asl rm 5', code: 4 },
    ]
    const [aslSelected, setAslSelected] = useState(asl[0])
    const [status, setStatus] = useState(null)
    const [posizioni, setPosizioni] = useState(null)
    const [posizioneSelected, setPosizioneSelected] = useState(null);

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'documentsService/documentType')
                .then((res) => {
                    if (res.data.success) {
                        setResultsTypeDocs(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            await APIRequest('GET', 'authService/role')
                .then((res) => {
                    if (res.data.success) {
                        setResultsRole(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            await APIRequest('GET', 'recruitmentService/jobPosition')
                .then((res) => {
                    if (res.data.success) {
                        let _posizioni = [...res.data.data]
                        _posizioni.unshift({ title: '--', code: 0 });
                        setPosizioni(_posizioni);
                        setPosizioneSelected(_posizioni[0])
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
            await APIRequest('GET', 'recruitmentService/status')
                .then((res) => {
                    if (res.data.success) {
                        setStatus(res.data.data)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        }
        trovaRisultato();
    }, []);

    const onTemplateSelect = (e) => {
        if (e.files) {
            setTitleDoc(e.files[0].name.split(".")[0]);
            setFileUpload(e.files[0]);
            setFileBuffer(e.files[0]);  // Salva il file per la conferma di upload
        }
    };

    const onTemplateUpload = (e) => {
        toast.current.show({ severity: 'info', summary: 'Successo', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setFileBuffer(null);
        setFileUpload(null);
        setTitleDoc('');
        callback();
    };

    const onTemplateClear = () => {
        setFileBuffer(null);
        setFileUpload(null);
        setTitleDoc('');
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;

        return (
            <div className={`${className}`} style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                {chooseButton}
                {cancelButton}
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                    </span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div
                className="flex items-center justify-start flex-row gap-5"
                onClick={() => {
                    const input = fileRef.current && fileRef.current.getInput();
                    if (input) input.click();
                }}
            >
                <i className="pi pi-image p-5" style={{ fontSize: '1.5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span className='w-3/4' style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }}>
                    Carica Curriculum
                </span>
            </div>
        );
    };

    const handleConfirm = async () => {
        let _user = {
            "username": email,
            "password": password,
            "role": resultsRole?.find(el => el?.label === "CANDIDATE")?.id,
            "idRegistry": {
                "firstName": firstName,
                "lastName": lastName,
                "cellnum": cellnum,
                "email": email,
            }
        }
        await APIRequest("POST", "authService/user-registry", _user)
            .then(async res => {
                if (res.data.success) {
                    let jobPosition = {}

                    setFirstName("")
                    setLastName("")
                    setCellNum("")
                    setEmail("")
                    setMessaggio("")
                    setChecked(false)
                    setPosizioneSelected(posizioni[0])
                    setAslSelected(asl[0])

                    const formData = new FormData();
                    formData.append('file', new Blob([fileBuffer], { type: fileUpload?.type }), fileUpload?.name);
                    formData.append('document_type', resultsTypeDocs.find(el => el.name === "Curriculum")?.id);
                    formData.append('metadata', "{}");
                    formData.append('description', "");
                    formData.append('title', `${titleDoc}`);
                    formData.append('url', "/");
                    formData.append('idUser', res.data.data.id);
                    formData.append('path', "/");

                    await APIRequest('POST', 'documentsService', formData)
                        .then(async res => {
                            if (res.data.success) {
                                setFileBuffer(null);
                                setFileUpload(null);
                                fileRef.current.clear();
                                setTitleDoc('');
                                jobPosition = {
                                    "idDocument": res.data.data.id,
                                    "jobPositionId": posizioneSelected.id,
                                    "statusId": status.find(el => el.label === "Candidato").id,
                                    "note": messaggio,
                                }
                                await APIRequest("POST", "recruitmentService", jobPosition)
                                    .then(res => {
                                        if (res.data.success) {
                                            toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'La registrazione è avvenuta con successo', life: 3000 });
                                            setRedirectThx(true)
                                        } else {
                                            console.log(res.data.message)
                                        }
                                    })
                                    .catch(e => {
                                        console.log(e);
                                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile completare la registrazione. RecruitmentError. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                                    });
                                toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'Il file è stato importato con successo', life: 3000 });
                            } else {
                                toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                            }
                        })
                        .catch(e => {
                            console.log(e);
                            toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
                        });
                } else {
                    console.log(res.data.message)
                }
            })
            .catch(e => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile completare la registrazione. UserError. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
            });
    }

    const chooseOptions = { icon: 'pi pi-fw pi-plus', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined h-7 w-7 hidden' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined h-7 w-7 hidden' };

    return (
        <>
            <div className='absolute h-screen w-screen'>
                <img
                    src={wallpaper}
                    alt='wallpaper'
                    className='h-screen w-screen'
                />
                <div className='h-screen w-screen backdrop-blur-sm bg-[#017878ad] absolute top-0 right-0'></div>
            </div>
            <div className="overflow-y-scroll flex flex-col items-center min-[600px]:justify-center h-screen relative backdrop-blur-sm bg-gray-300/20">
                <Toast ref={toast} />
                <div className='flex flex-row items-center justify-center px-5 w-full z-[999] my-5 min-[900px]:my-10'>
                    <div className="flex items-center justify-center max-[899px]:py-4 px-2">
                        <img
                            src={logoText}
                            alt="Logo"
                            className={`logo-image max-h-20 cursor-pointer select-none`}
                        />
                    </div>
                </div>
                {redirectThx ?
                    <div className='flex flex-col w-10/12 items-center justify-center my-auto'>
                        <div className='flex flex-col justify-center items-center rounded-md backdrop-blur-sm bg-[#ffffff63] '>
                            <div className='p-4 px-10 rounded-lg w-full flex flex-col items-center'>
                                <div className="image">
                                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 7L9.00004 18L3.99994 13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                </div>
                                <h2 className='text-3xl text-center text-gray-800 mt-2'>Grazie per la tua registrazione!</h2>
                                <p className='text-center text-gray-600'>Riceverai una email di conferma per attivare il tuo account.</p>
                                <Link className='text-xl underline text-sky-500 my-2' to='/'>Accedi</Link>
                                <p className='text-center text-gray-600'>Se non ricevi l'email di conferma, controlla la tua casella di posta elettronica o la cartella spam.</p>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='flex flex-col w-10/12 items-center justify-center my-auto'>
                        <div className='text-white text-start mb-5'>
                            <p className='font-bold text-fluid2 mb-2'>Presenta la tua candidatura.</p>
                            <p className='text-xl'>ADI Salus è alla continua ricerca di personale motivato a lavorare nell’ambito socioassistenziale. Se ritieni di possedere le qualità e le competenze idonee per lavorare nella nostra struttura, invia ora la tua candidatura.</p>
                        </div>
                        <div className='flex flex-col justify-center items-center rounded-md backdrop-blur-sm bg-[#ffffff63] '>
                            <div className="grid grid-cols-2 p-4 px-10 rounded-lg max-[670px]:grid-cols-2 max-[520px]:grid-cols-1 max-[899px]:w-full w-10/12 gap-3">
                                <div className="mt-3">
                                    <label htmlFor="firstName" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                        <i className="pi pi-user mr-3"></i>Nome *
                                    </label>
                                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='firstName' placeholder="Nome *" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                                </div>
                                <div className="mt-3">
                                    <label htmlFor="lastName" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                        <i className="pi pi-user mr-3"></i>Cognome *
                                    </label>
                                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='lastName' placeholder="Cognome *" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                                </div>
                                <div className='mt-3 col-span-2 grid grid-cols-3 gap-3'>
                                    <div>
                                        <label htmlFor="email" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                            <i className="pi pi-at mr-3"></i>Email *
                                        </label>
                                        <InputText className="w-full select-none p-2 border focus:shadow-none" id="email" keyfilter="email" placeholder="Email *" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="password" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                            <i className="pi pi-at mr-3"></i>Password *
                                        </label>
                                        <InputText className="w-full select-none p-2 border focus:shadow-none" id="password" placeholder="Password *" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </div>
                                    <div>
                                        <label htmlFor="cellnum" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                            <FaMobileAlt size={23} className='mr-3' />Cellulare *
                                        </label>
                                        <InputText className="w-full select-none p-2 border focus:shadow-none" id='cellnum' placeholder="Cellulare *" value={cellnum} onChange={(e) => setCellNum(e.target.value)} />
                                    </div>
                                </div>
                                <div className="mt-3 flex flex-col justify-between">
                                    <label htmlFor="posizione" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                        <LiaUserNurseSolid size={23} className='mr-3' />Posizione *
                                    </label>
                                    <Dropdown disabled={!posizioneSelected} showClear value={posizioneSelected} onChange={(e) => setPosizioneSelected(e.value)} options={posizioni} optionLabel="title"
                                        filter className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                                </div>
                                <div className="mt-3 flex flex-col justify-between">
                                    <label htmlFor="asl" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                        <FaRegHospital size={23} className='mr-3' />ASL di preferenza
                                    </label>
                                    <Dropdown showClear value={aslSelected} onChange={(e) => setAslSelected(e.value)} options={asl} optionLabel="name"
                                        filter className="w-full md:w-14rem" checkmark={true} highlightOnSelect={false} />
                                </div>
                            </div>
                            <div className='my-3 px-10 max-[899px]:w-full w-10/12 loadCurriculm flex flex-row justify-start'>
                                <div className='w-1/2 max-[900px]:w-full pr-2'>
                                    <label htmlFor="curriculum" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                                        <PiReadCvLogo size={25} className='mr-3' />Curriculum
                                    </label>
                                    <FileUpload
                                        ref={fileRef}
                                        name="demo[]"
                                        url="/api/upload"
                                        accept="*"
                                        maxFileSize={5000000}
                                        onUpload={onTemplateUpload}
                                        onSelect={onTemplateSelect}
                                        onError={onTemplateClear}
                                        onClear={onTemplateClear}
                                        headerTemplate={headerTemplate}
                                        itemTemplate={itemTemplate}
                                        emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions}
                                        cancelOptions={cancelOptions}
                                    />
                                </div>
                            </div>
                            <div className="mt-3 px-10 max-[899px]:w-full w-10/12">
                                <InputTextarea className="w-full select-none p-2 border focus:shadow-none" placeholder="Messaggio..." value={messaggio} onChange={(e) => setMessaggio(e.target.value)} maxLength={250} rows={5} cols={30} />
                            </div>
                            <div className="flex align-items-center px-10 max-[899px]:w-full w-10/12 my-5">
                                <Checkbox onChange={e => setChecked(e.checked)} checked={checked}></Checkbox>
                                <label onClick={() => setChecked(!checked)} htmlFor="mex" className="ml-2 font-bold uppercase text-start text-[#017878] text-sm">(*) Dichiaro di aver preso visione dell’Informativa sul trattamento dei dati personali fornita da Salus srl ai sensi degli artt. 13 e 14 del GDPR ed esprimo il consenso esplicito, libero ed informato al trattamento dei miei dati personali.</label>
                            </div>
                            <Button type="button" disabled={!firstName || !lastName || !cellnum || !email || posizioneSelected?.code <= 0 || !checked} label="Invia" className="buttonCustom !my-5" onClick={handleConfirm} />
                        </div>
                    </div>
                }
            </div>
        </>
    );
}

export default LavoraConNoi;