import { useLayoutEffect, useState } from "react";
import { delPathCrumb, setResultsDispatch } from "../store/actions/actions";
import { APIRequest } from "../services/axios";

export const formatPermission = (roles, permissions, route) => {
    let services = route.map(el => el.service);
    services = [...new Set(services)];
    let formattedPermissions = services.map((service, serviceIndex) => {
        let serviceNode = {
            key: `service-${service}`,
            data: { service },
            children: []
        };

        let filter = route.filter(el => el.service === service);
        if (filter.length > 0) {
            let paths = filter.map(el => el.path);
            paths = [...new Set(paths)];
            for (const path of paths) {
                let methods = filter.filter(el => el.path === path).map(obj => obj.method);
                let pathNode = {
                    key: `path-${service}-${path}`,
                    data: { path },
                    children: []
                };

                for (const method of methods) {
                    let perm = permissions.filter(el => el.route.service === service && el.route.path === path && el.route.method === method);
                    let permissionRoles = perm.map(el => el.role.label);
                    let activeRoles = roles.map(el => el.label);
                    let methodNode = {
                        key: `method-${service}-${path}-${method}`,
                        data: { method }
                    };
                    activeRoles.forEach(rol => {
                        let idRole = roles.find(el => el.label === rol).id
                        let idRoute = route.find(el => el.path === path && el.method === method && el.service === service).id
                        let id = permissions.find(el => el.route.service === service && el.route.path === path && el.route.method === method && el.role.label === rol)?.id
                        methodNode.data[rol] = { value: permissionRoles.includes(rol), id, idRole, idRoute };
                    });

                    pathNode.children.push(methodNode);
                }
                serviceNode.children.push(pathNode);
            }
        }
        return serviceNode;
    });
    return formattedPermissions;
}

export function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
}

// breadcrumbUtils.js
export const handleDialogAndBreadcrumbs = (setDialog, dispatch) => {
    if (setDialog) {
        setDialog(false);
    }
    const path = window.location.pathname;
    switch (path) {
        case "/operatori":
            dispatch(delPathCrumb("Nuovo operatore"));
            break;
        case "/pazienti":
            dispatch(delPathCrumb("Nuovo paziente"));
            break;
        case "/hr":
            dispatch(delPathCrumb("Nuovo HR"));
            break;
        case "/contabili":
            dispatch(delPathCrumb("Nuovo contabile"));
            break;
        case "/supervisore":
            dispatch(delPathCrumb("Nuovo supervisore"));
            break;
        case "/segretari":
            dispatch(delPathCrumb("Nuovo segretario"));
            break;
        case "/candidati":
            dispatch(delPathCrumb("Nuovo candidato"));
            break;
        case "/conversazioni":
            dispatch(delPathCrumb("Conversazioni"));
            break;
        case "/notifiche":
            dispatch(delPathCrumb("Invia notifica"));
            dispatch(delPathCrumb("Info notifica"));
            break;
        case "/valutazioneDellaStruttura":
            dispatch(delPathCrumb("Info Employess Care"));
            break;
        case "/valutazioneDelServizio":
            dispatch(delPathCrumb("Info Employess Care"));
            break;
        case "/questionariValutazioneDellaStruttura":
            dispatch(delPathCrumb("Crea questionario"));
            dispatch(delPathCrumb("Info questionario"));
            break;
        case "/questionariValutazioneDelServizio":
            dispatch(delPathCrumb("Crea questionario"));
            dispatch(delPathCrumb("Info questionario"));
            break;
        case "/creaCorso":
            dispatch(delPathCrumb("Crea Corso"));
            break;
        case "/corsi":
            dispatch(delPathCrumb("Corsi"));
            break;
        case "/asl":
            dispatch(delPathCrumb("Nuova ASL"));
            break;
        case "/candidature":
            dispatch(delPathCrumb("Candidature"));
            dispatch(delPathCrumb("Configurazione data entry"));
            break;
        default:
            break;
    }
    dispatch(delPathCrumb("Info Profilo"));
};

export const capitalizePathName = (path) => {
    const formattedPath = path.split("/")[1].split(/(?=[A-Z])/).join(" ");
    return formattedPath.charAt(0).toUpperCase() + formattedPath.slice(1);
};


export const exportExcel = async (fieldsXLSX, pathFieldsXLSX, results, nameFileExport) => {
    let body = {
        "level": "download",
        "message": `Download di ${nameFileExport} eseguito da ${JSON.parse(localStorage.getItem('user')).role.label}: ${JSON.parse(localStorage.getItem('registry'))?.firstName} ${JSON.parse(localStorage.getItem('registry'))?.lastName}`,
        "metadata": {},
        "userId": JSON.parse(localStorage.getItem('user'))?.id,
    }
    await APIRequest("POST", `logService`, body)
            .then(res => {
                import('xlsx').then((xlsx) => {
                    let fileXLSX = []
                    fileXLSX.push(Object.values(fieldsXLSX)); // Aggiunge l'intestazione
                    results?.forEach((element) => {
                        let _results = []; // Reset della variabile _results per ogni nuovo elemento
                        fieldsXLSX.forEach((el, key) => {
                            let partPath = pathFieldsXLSX[key].split(".");
                            let value = element;
                            if (partPath.length > 0) {
                                partPath.forEach(part => {
                                    value = value[part] ? value[part] : "null"; // Se non esiste il valore, metti "null"
                                });
                            } else {
                                value = element[pathFieldsXLSX[key]];
                            }
                            _results.push(value); // Aggiunge il valore a _results
                        });
                        fileXLSX.push(_results); // Aggiunge i risultati alla matrice finale
                    });
                    const worksheet = xlsx.utils.json_to_sheet(fileXLSX);
                    const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
                    const excelBuffer = xlsx.write(workbook, {
                        bookType: 'xlsx',
                        type: 'array'
                    });
            
                    saveAsExcelFile(excelBuffer, nameFileExport);
                });
            }).catch((e) => {
                console.log(e)
            })
};

const saveAsExcelFile = (buffer, fileName) => {
    import('file-saver').then((module) => {
        if (module && module.default) {
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data = new Blob([buffer], {
                type: EXCEL_TYPE
            });

            module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        }
    });
};

export const selectResultsByUrl = (state, url) => {
    return state.allResultsReducer.results.find(item => item.title === url);
};
