const initialState = {
    results: [],
    POSTresults: [],
};

const allResultsReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_RESULTS": {
            const { results } = action.payload;

            // Aggiungi controlli per assicurarti che results esista
            if (!results || !results.config || !results.data || !results.data.data) {
                console.error("Results data is missing or malformed");
                return state; // Ritorna lo stato attuale se i dati non sono validi
            }

            const { url } = results.config;
            const existingIndex = state.results.findIndex(item => item.title === (url.includes("3001/") ? url.split("3001/")[1] : (url.includes("/api/") ? url.split("/api/")[1] : url)));

            // Se esiste già, crea una nuova copia dell'array e aggiorna solo l'elemento specifico
            if (existingIndex !== -1) {
                return {
                    ...state,
                    results: state.results.map((item, index) =>
                        index === existingIndex
                            ? {
                                ...item,
                                results: Array.isArray(results.data.data) ? [...results.data.data] : {...results.data.data}  // Copia immutabile dei dati
                            }
                            : item
                    )
                };
            }

            // Altrimenti, aggiungi un nuovo oggetto in modo immutabile
            return {
                ...state,
                results: [
                    ...state.results,
                    {
                        title: url.includes("3001/") ? url.split("3001/")[1] : (url.includes("/api/") ? url.split("/api/")[1] : url) ,
                        results: Array.isArray(results.data.data) ? [...results.data.data] : {...results.data.data} // Assicurati di copiare anche qui i dati
                    }
                ]
            };
        }
        case "POST_RESULTS": {
            const { method, data, url } = action.payload;

            // Controlli per verificare che i dati necessari esistano
            if (!method || !data || !url) {
                console.error("Method, data, or URL is missing");
                return state; // Ritorna lo stato attuale se i dati non sono validi
            }

            const existingIndex = state.POSTresults.findIndex(item => item.url === url.includes("3001/") ? url.split("3001/")[1] : (url.includes("/api/") ? url.split("/api/")[1] : url) );

            // Se esiste già una richiesta per lo stesso URL, aggiorna l'elemento esistente
            if (existingIndex !== -1) {
                return {
                    ...state,
                    POSTresults: state.POSTresults.map((item, index) =>
                        index === existingIndex
                            ? {
                                ...item,
                                method: method,  // Aggiorna il metodo (che sarà sempre "POST" in questo caso)
                                data: { ...data },  // Aggiorna i dati della POST
                                url: url.includes("3001/") ? url.split("3001/")[1] : (url.includes("/api/") ? url.split("/api/")[1] : url)  // Aggiorna l'URL se necessario
                            }
                            : item
                    )
                };
            }

            // Altrimenti, aggiungi un nuovo oggetto in modo immutabile
            return {
                ...state,
                POSTresults: [
                    ...state.POSTresults,
                    {
                        method: method,  // Salva il metodo (POST)
                        data: { ...data },  // Salva i dati della POST
                        url: url.includes("3001/") ? url.split("3001/")[1] : (url.includes("/api/") ? url.split("/api/")[1] : url)  // Salva l'URL della richiesta
                    }
                ]
            };
        }
        default:
            return state;
    }
};

export default allResultsReducer;
