import React, { useState, useEffect, useRef } from 'react';
import HeadPage from '../../components/headPage';
import { APIRequest } from "../../services/axios";
import { Toast } from 'primereact/toast';
import FeedbackFrame from '../../components/feedbackFrame';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';

function Feedback(props) {
    const [resultDialog1, setResultDialog1] = useState(true)
    const [resultsQuestion, setResultsQuestion] = useState(null)
    const dispatch = useDispatch()
    const allResults = useSelector(state => state.allResults.results)

    const toast = useRef(null);
    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'feedbackService', null, dispatch)
                .then(res => {
                    setResultsQuestion(res.data.data);
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            setResultsQuestion(find.results)
                        }
                    }
                    console.log(e)
                })
            /* await APIRequest('GET', 'feedbackService/question')
                .then(res => {
                    setResultsQuestion(res.data.data);
                }).catch((e) => {
                    console.log(e)
                }) */
        }
        trovaRisultato();
        setResultDialog1(true)
        dispatch(resetPathCrumb())
    }, [dispatch])

    return (
        <div className="flex flex-col h-full relative bg-stone-100">
            <Toast ref={toast} />
            {!props.isDashboard &&
                <HeadPage />
            }
            {resultsQuestion?.length > 0 &&
                <div className={`flex-grow transition-all duration-300`}>
                    <div className='flex flex-row max-[1100px]:flex-col items-start justify-around h-full'>
                        <FeedbackFrame resultsQuestion={resultsQuestion} setResultsQuestion={setResultsQuestion} resultDialog1={resultDialog1} setResultDialog1={setResultDialog1} />
                    </div>
                </div>
            }
        </div>
    );
}

export default Feedback;
