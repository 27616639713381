import React, { useEffect } from 'react';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import Loader from './loader';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

function FrameContratto(props) {

    // Funzione per sostituire segnaposto "$fieldName" con i valori di props.candidato
    const replacePlaceholders = (content, candidato) => {
        if (typeof content === "string") {
            // Sostituisce i segnaposto con i valori appropriati
            return content.replace(/\$(\w+)/g, (_, key) => {
                const value = candidato[key];
                return value !== undefined ? value : `<span class="bg-yellow-200 p-1 rounded">_________________</span>`;
            });
        }
        return content;
    };

    // Funzione per pulire e sostituire i contenuti, aggiungendo la gestione dei segnaposto
    const cleanContent = (content, candidato) => {
        if (Array.isArray(content)) {
            // Ricorsivamente pulisce ogni elemento dell'array
            return content.map((item) => cleanContent(item, candidato));
        }
        if (typeof content === "string") {
            // Sostituisce i segnaposto con i valori appropriati
            return replacePlaceholders(content, candidato);
        }
        if (typeof content === "object" && content !== null) {
            // Ricorsivamente pulisce ogni valore negli oggetti
            const cleanedObject = {};
            for (const key in content) {
                cleanedObject[key] = cleanContent(content[key], candidato);
            }
            return cleanedObject;
        }
        return content;
    };

    // Funzione per il rendering dei contenuti
    const renderContent = (item, candidato) => {
        const cleanedContent = cleanContent(item.content, candidato);

        switch (item.tipo) {
            case "intestazione":
                return <p className="italic text-gray-600">{cleanedContent}</p>;

            case "titolo":
                return <h2 className="font-bold text-lg mb-2">{cleanedContent}</h2>;

            case "paragrafo":
                return (
                    <div>
                        {item.titolo && <p className="font-bold mb-2">{item.titolo}</p>}
                        {Array.isArray(cleanedContent) ? (
                            cleanedContent.map((line, index) => (
                                <p
                                    key={index}
                                    className="mb-2"
                                    dangerouslySetInnerHTML={{ __html: line }}
                                />
                            ))
                        ) : (
                            <p
                                className="mb-2"
                                dangerouslySetInnerHTML={{ __html: cleanedContent }}
                            />
                        )}
                    </div>
                );

            case "tabella":
                const cleanedTableData = Object.entries(cleanedContent).map(([key, value]) => ({
                    key,
                    value: Array.isArray(value)
                        ? value.map((v) => `<span class="p-1 rounded">${v}</span>`).join(", ")
                        : value,
                }));

                return (
                    <DataTable value={cleanedTableData} className="w-full mt-4">
                        <Column
                            field="key"
                            header="Campo"
                            body={(rowData) => <span className="font-bold">{rowData.key}</span>}
                        />
                        <Column
                            field="value"
                            header="Valore"
                            body={(rowData) => (
                                <span dangerouslySetInnerHTML={{ __html: rowData.value }} />
                            )}
                        />
                    </DataTable>
                );
            default:
                return null;
        }
    };

    return (
        <div className='flex flex-col justify-center items-center h-full'>
            <div className='w-full my-2 flex flex-col'>
                {props.finalContract &&
                    <p className="text-start text-base mb-2 w-full">
                        In questa sezione, puoi visualizzare un'anteprima del contratto che il candidato dovrà compilare e firmare:
                    </p>
                }
            </div>
            <div className={`flex flex-row items-center justify-center ${props.finalContract ? "bg-gray-100" : ""} min-[1200px]:w-1/2 h-full`}>
                {props.finalContract ? (
                    <>
                        <div style={{ fontFamily: "Arial, sans-serif", padding: "20px", lineHeight: "1.6" }}>
                            <h1 style={{ textAlign: "center", marginBottom: "20px" }}>{props.finalContract.title}</h1>
                            {props.finalContract?.content.map((item, index) => (
                                <div key={index} className='text-start' style={{ marginBottom: "20px" }}>
                                    {renderContent(item, props?.candidato?.idRegistry)}
                                </div>
                            ))}
                        </div>
                    </>
                ) : (
                    <Loader />
                )}
            </div>
        </div >
    );
}

export default FrameContratto;
