import React, { useState, useEffect, useRef } from 'react';
import { Toast } from 'primereact/toast';
import { useDispatch } from 'react-redux';
import CustomDataTable from './customDataTable';
import { NavLink } from 'react-router-dom';
import { setPathCrumb } from '../store/actions/actions';
import { Button } from 'primereact/button';
import { exportExcel, handleDialogAndBreadcrumbs, useWindowSize } from '../utils/utils';
import RenderQuestionary from '../common/renderQuestionary';

function ResponseQuest(props) {
    const [resultQuestionary, setResultQuestionary] = useState(null)
    const [formQuest, setFormQuest] = useState(null)
    const toast = useRef(null);
    const dt = useRef(null);
    const dispatch = useDispatch()
    const [width] = useWindowSize();

    useEffect(() => {
        async function trovaRisultato() {

        }
        trovaRisultato();
    }, [])

    useEffect(() => {
        let x = {
            "idUser": JSON.parse(window.localStorage.getItem("user")).id,
            "idFeedback": resultQuestionary ? resultQuestionary?.idFeedback?.id : null,
            "questionValue": resultQuestionary ? resultQuestionary.questionValue : null,
        }
        setFormQuest(x)
    }, [resultQuestionary])

    const OpenDialog2 = async (e) => {
        let element = { ...e }
        props.setDialog1(true)
        element.idFeedback = props.resultsQuestion.find(el => el.id === parseInt(e.idFeedback))
        setResultQuestionary(element)
        dispatch(setPathCrumb(
            {
                "label": "Info Employess Care",
                "template": () => <NavLink to={props.pathname}>Info Employess Care</NavLink>
            }
        ))
    }

    const indietro2 = () => {
        handleDialogAndBreadcrumbs(props.setDialog1, dispatch)
    }



    const fields = [
        {
            field: "firstName",
            header: "Nome",
            body: "nome",
            sortable: true,
            showHeader: true,
        },
        {
            field: "lastName",
            header: "Cognome",
            body: "cognome",
            sortable: true,
            showHeader: true,
        },
        {
            field: "role",
            header: "Ruolo",
            body: "role",
            sortable: true,
            showHeader: true,
        },
    ];
    const actionFields = [
        {
            name: (rowData) => "Info",
            icon: (rowData) => <i className="pi pi-eye" />,
            handler: OpenDialog2,
        },
    ];

    const fieldsXLSX = ["Nome", "Cognome", "Ruolo", "Data"]
    const pathFieldsXLSX = ["idUser.idRegistry.firstName", "idUser.idRegistry.lastName", "idUser.role.label", "createAt"]
    const nameFileExport = "FeedbackResponse"

    const header = (
        <div className="flex flex-row justify-between items-center">
            <Button className="buttonCustom" type="button" icon="pi pi-file-excel" label={`${width > 340 ? "Esporta XLS" : ""}`} severity="success" rounded onClick={() => exportExcel(fieldsXLSX, pathFieldsXLSX, props.resultsResponse, nameFileExport)} />
        </div>
    );

    return (
        <div className="flex flex-col h-full w-full relative">
            <Toast ref={toast} />
            {props.dialog1 ?
                <div className='w-full h-full flex flex-col justify-start items-start bg-stone-100 px-3'>
                    <div className='w-full flex flex-row justify-between p-4'>
                        <Button className='buttonCustom' label='Indietro' onClick={() => indietro2()}></Button>
                    </div>
                    <div className='w-full h-full min-[1101px]:px-10 min-[1201px]:px-20 min-[1301px]:px-30 min-[1401px]:px-40 min-[1501px]:px-60 min-[1601px]:px-80'>
                        <div className='w-full flex flex-col justify-start bg-white px-3 shadow shadox-xl border rounded-lg border-[#00b1b1] h-full'>
                            <div className='bg-[#ffff88] my-5 p-3 mb-5 text-start flex flex-col gap-4'>
                                <div className='w-full text-xl font-bold'>{resultQuestionary?.idFeedback && resultQuestionary?.idFeedback?.title}</div>
                                <div className='w-full text-lg font-semibold'>{resultQuestionary?.idFeedback && resultQuestionary?.idFeedback?.subTitle}</div>
                                <div className='w-full '>{resultQuestionary?.idFeedback && resultQuestionary?.idFeedback?.note}</div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                {resultQuestionary && resultQuestionary?.idFeedback?.question?.map((el, key) => {
                                    return (
                                        <React.Fragment key={key}>
                                            <RenderQuestionary el={el} id={key} formQuest={formQuest} setFormQuest={setFormQuest} dialog1={props.dialog1} setDialog1={props.setDialog1} />
                                        </React.Fragment>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className='px-3'>
                    <CustomDataTable
                        dataKey="id"
                        loading={!props.resultsResponse}
                        refer={dt}
                        value={props.resultsResponse}
                        actionFields={actionFields}
                        fields={fields}
                        showHeader={true}
                        header={header}
                    />
                </div>
            }
        </div>
    );
}

export default ResponseQuest;
