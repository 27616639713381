import React, { useEffect, useRef, useState } from 'react';
import { Toast } from 'primereact/toast';
import logoText from "../../assets/img/logo_salus_text.png";
import { useAuth } from '../../provider/useAuth';
import { Link, useNavigate } from 'react-router-dom';
import { FaSignOutAlt, FaRegEye } from 'react-icons/fa';
import { useWindowSize } from '../../utils/utils';
import { APIRequest } from '../../services/axios';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../components/loader';
import "../../style/wave.css"; // Link to your custom wave CSS file
import { Button } from 'primereact/button';

function Candidate() {
    const auth = useAuth();
    const navigate = useNavigate();
    const toast = useRef(null);
    const [width] = useWindowSize();
    const [resultUser, setResultUser] = useState(null);
    const [resultDoc, setResultDoc] = useState(null);
    const dispatch = useDispatch();
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteConfirmed, setDeleteConfirmed] = useState(false)
    const allResults = useSelector(state => state.allResults.results);

    useEffect(() => {
        const fetchData = async () => {
            let userResult = null
            try {
                const res = await APIRequest("GET", `recruitmentService`, null, dispatch);
                if (res.data.success) {
                    userResult = res.data.data.find(el => el.idUser.id === JSON.parse(localStorage.getItem('user')).id);
                    setResultUser(userResult);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            } catch (e) {
                if (e.code === "ERR_NETWORK") {
                    const find = allResults.find(el => el.title === e.config.url.split("/").pop());
                    toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                    if (find) setResultUser(find.results);
                } else {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                }
                console.error(e);
            }

            await APIRequest('GET', 'documentsService')
                .then((res) => {
                    if (res.data.success) {
                        setResultDoc(res.data.data.find(el => el?.id === userResult?.idDocument?.id));
                    } else {
                        console.log(res.data.message)
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        };

        fetchData();
    }, [dispatch, allResults]);

    const downloadFile = async () => {
        if (resultDoc?.file && resultDoc?.file?.data) {
            // Crea un blob dal file base64
            const byteCharacters = atob(resultDoc?.file?.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], {
                type:
                    resultDoc?.url.includes("jpg") ? "image/jpeg" :
                        resultDoc?.url.includes("png") ? "image/png" :
                            resultDoc?.url.includes("pdf") ? "application/pdf" :
                                ""
            });

            // Crea un URL temporaneo dal blob
            const fileUrl = URL.createObjectURL(blob);
            window.open(fileUrl, "_blank");
        }
    }

    const statusCandidatura = (rowData) => {
        const statusMapping = {
            "Candidato": "è in attesa di valutazione",
            "Valutazione": "è in attesa di approvazione",
            "Approvato": "è stata approvata",
            "Rifiutato": "è stata rifiutata",
            "Assegnato": "il contratto è in revisione"
        };
        return `Lo stato della tua candidatura ${statusMapping[rowData?.status?.label] || "non è determinabile"}`;
    };

    const deleteCandidature = () => {
        setDeleteConfirm(true);
    }

    const deleteConfirmedFunction = () => {
        setDeleteConfirmed(true)
        /* APIRequest('DELETE', `recruitmentService/${resultUser.id}`)
            .then((res) => {
                if (res.data.success) {
                    toast.current.show({ severity: 'success', summary: 'Candidatura eliminata con successo!', detail: '', life: 3000 });
                    setDeleteConfirm(false);
                    auth.signout(() => navigate("/"));
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            })
            .catch((e) => {
                console.error(e);
            }); */
    }

    return (
        <div className="flex flex-col h-screen relative">
            <Toast ref={toast} />

            {/* Header */}
            <div className='bg-white max-[899px]:backdrop-blur-sm flex flex-row items-center justify-between p-5 w-full z-[999] shadow-md'>
                <div className="flex items-center justify-center max-[899px]:py-4 px-2">
                    <img
                        src={logoText}
                        alt="Logo"
                        className={`logo-image max-[899px]:h-9 h-12 cursor-pointer select-none`}
                    />
                </div>
                <div className={`max-[899px]:mb-2`}>
                    <button
                        onClick={() => {
                            auth.signout(() => navigate("/"));
                        }}
                        className="!flex items-center justify-center max-[899px]:!p-3 min-[900px]:!p-4 !w-full buttonCustom select-none gap-2"
                    >
                        {width > 340 && "Esci"}
                        <FaSignOutAlt className="select-none" size={20} />
                    </button>
                </div>
            </div>

            {/* Main Content */}
            <p>Account vecchio con il ruolo ancora NON-CANDIDATO</p>
            {/* <main className="flex flex-col items-center justify-center flex-grow">
                {deleteConfirmed ?
                    <>
                        <div className='flex flex-col w-10/12 items-center justify-center my-auto'>
                            <div className='flex flex-col justify-center items-center rounded-md backdrop-blur-sm bg-[#ffffff63] '>
                                <div className='p-4 px-10 rounded-lg w-full flex flex-col items-center'>
                                    <div className="image">
                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M20 7L9.00004 18L3.99994 13" stroke="#000000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg>
                                    </div>
                                    <h2 className='text-3xl text-center text-gray-800 mt-2'>Grazie per la tua registrazione!</h2>
                                    <p className='text-center text-gray-600'>Riceverai una email di conferma per attivare il tuo account.</p>
                                    <Link className='text-xl underline text-sky-500 my-2' to='/'>Accedi</Link>
                                    <p className='text-center text-gray-600'>Se non ricevi l'email di conferma, controlla la tua casella di posta elettronica o la cartella spam.</p>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="bg-[#00b1b1] w-full py-3">
                            <p className="font-semibold pb-3 text-white text-3xl">Benvenuto, {JSON.parse(window.localStorage.getItem("registry")).firstName} {JSON.parse(window.localStorage.getItem("registry")).lastName}</p>
                        </div>
                        <div className="flex flex-col items-center justify-center size-full">
                            {resultUser ? (
                                <div className="content bg-white p-6 rounded-lg shadow-lg w-4/5 max-w-lg mt-5">
                                    {deleteConfirm ?
                                        <>
                                            <p className="text-lg font-medium text-gray-700 mb-4">
                                                Sei sicuro di voler ritirare la tua candidatura?
                                            </p>
                                            <div className="flex items-center justify-center gap-4">
                                                <button
                                                    onClick={() => deleteConfirmedFunction()}
                                                    className="bg-gray-500 hover:bg-green-500 shadow-lg shadow-gray-400 hover:shadow-green-300 overflow-hidden flex items-center md:size-14 max-[425px]:size-14 px-2 md:p-0 rounded-lg shadow-md transition-all duration-300 ease-linear hover:w-full group"
                                                >
                                                    <span className="flex items-center justify-center size-12 md:mx-4 max-[425px]:mx-2 group-hover:mx-2 transition-all duration-300">
                                                        <i className="pi pi-check text-white text-2xl transform transition-transform duration-200"></i>
                                                    </span>
                                                    <span className="text-white font-bold md:text-2xl text-nowrap uppercase transition-opacity duration-300 md:ml-2 max-[425px]:ml-2 group-hover:mx-3 delay-200 w-full">
                                                        Conferma cancellazione
                                                    </span>
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <p className="text-lg font-medium text-gray-700 mb-4">
                                                {statusCandidatura(resultUser)}
                                            </p>
                                            <div className="mb-4">
                                                <p className="text-gray-600">
                                                    <strong>Posizione:</strong> {resultUser.jobPosition.title}
                                                </p>
                                                <p className="text-gray-600">
                                                    <strong>Data candidatura:</strong> {
                                                        new Intl.DateTimeFormat("it-IT", {
                                                            day: "2-digit",
                                                            month: "2-digit",
                                                            year: "numeric",
                                                        }).format(new Date(resultUser.appliedAt))
                                                    }
                                                </p>
                                            </div>
                                            <div className="flex max-[500px]:flex-col max-[320px]:flex-row items-center justify-between">
                                                <button
                                                    onClick={() => deleteCandidature()}
                                                    className="bg-red-500 hover:bg-red-600 overflow-hidden flex items-center max-[320px]:justify-center min-[501px]:size-14 max-[500px]:w-full rounded-lg shadow-md transition-all duration-300 ease-linear hover:w-full group"
                                                >
                                                    <span className="flex items-center justify-center size-12 min-[501px]:mx-3 group-hover:mx-0 transition-all duration-300">
                                                        <i className="pi pi-trash text-white text-2xl transform transition-transform duration-200"></i>
                                                    </span>
                                                    <span className="text-white max-[320px]:hidden max-[500px]:w-full font-bold text-sm text-nowrap uppercase transition-opacity duration-300 min-[501px]:ml-1 group-hover:mx-3 delay-200">
                                                        Ritira candidatura
                                                    </span>
                                                </button>
                                                <Button
                                                    onClick={() => downloadFile()}
                                                    label={`${width > 320 ? "Visualizza il tuo CV" : ""}`}
                                                    icon="pi pi-eye"
                                                    className="flex items-center justify-center max-[899px]:!p-3 min-[900px]:!p-4 !w-full buttonCustom select-none"
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center size-full">
                                    <Loader />
                                    <p className="text-gray-500 mt-3">Caricamento dei dati della candidatura...</p>
                                </div>
                            )}
                        </div>
                    </>
                }
            </main> */}

            {/* Waves Background */}
            <div className="waves-container">
                <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none">
                    <defs>
                        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                    </defs>
                    <g className="parallax">
                        <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                        <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                        <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                        <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                    </g>
                </svg>
            </div>
        </div>
    );
}

export default Candidate;
