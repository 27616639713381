import { FaSignOutAlt } from "react-icons/fa";
import logoText from "../assets/img/logo_salus_text.png";
import { useAuth } from '../provider/useAuth';
import { useNavigate } from 'react-router-dom';
import { useWindowSize } from "../utils/utils";
function Header(props) {
    let auth = useAuth();
    const [width] = useWindowSize();
    const navigate = useNavigate();
    return (
        <div className='bg-white max-[899px]:backdrop-blur-sm flex flex-row items-center justify-between p-5 w-full z-[999] shadow-md'>
            <div className="flex items-center justify-center max-[899px]:py-4 px-2">
                <img
                    src={logoText}
                    alt="Logo"
                    className={`logo-image max-[899px]:h-9 h-12 cursor-pointer select-none`}
                />
            </div>
            <div className={`max-[899px]:mb-2`}>
                <button
                    onClick={() => {
                        auth.signout(() => navigate("/"));
                    }}
                    className="!flex items-center justify-center max-[899px]:!p-3 min-[900px]:!p-4 !w-full buttonCustom select-none gap-2"
                >
                    {width > 340 && "Esci"}
                    <FaSignOutAlt className="select-none" size={20} />
                </button>
            </div>
        </div>
    );
}

export default Header;
