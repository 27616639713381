
import React, { useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Password } from 'primereact/password';
import { Button } from 'primereact/button';
import { APIRequest } from '../services/axios';
import { Toast } from 'primereact/toast';
import { useDispatch, useSelector } from 'react-redux';
import { handleDialogAndBreadcrumbs } from '../utils/utils';
import { ProgressBar } from 'primereact/progressbar';

export default function UserForm(props) {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [cellnum, setCellNum] = useState('');
    const [tellNum, setTellNum] = useState('');
    const [pIva, setPIva] = useState('');
    const [codFisc, setCodFisc] = useState('');
    const [city, setCity] = useState('');
    const [email, setEmail] = useState('');
    const [pec, setPEC] = useState('');
    const [address, setAddress] = useState('');
    const [cap, setCap] = useState('');
    const [password, setPassword] = useState('');
    const [progress, setProgress] = useState(0);
    const dispatch = useDispatch()
    const toast = useRef(null);

    useEffect(() => {
        if (props?.result) {
            setFirstName(props?.result ? (props?.result?.idRegistry?.firstName ? props?.result?.idRegistry?.firstName : "") : "")
            setLastName(props?.result ? (props?.result?.idRegistry?.lastName ? props?.result?.idRegistry?.lastName : "") : "")
            setCellNum(props?.result ? (props?.result?.idRegistry?.cell ? props?.result?.idRegistry?.cell : "") : "")
            setTellNum(props?.result ? (props?.result?.idRegistry?.tel ? props?.result?.idRegistry?.tel : "") : "")
            setPIva(props?.result ? (props?.result?.idRegistry?.pIva ? props?.result?.idRegistry?.pIva : "") : "")
            setCodFisc(props?.result ? (props?.result?.idRegistry?.codFisc ? props?.result?.idRegistry?.codFisc : "") : "")
            setCity(props?.result ? (props?.result?.idRegistry?.city ? props?.result?.idRegistry?.city : "") : "")
            setEmail(props?.result ? (props?.result?.username ? props?.result?.username : "") : "")
            setPEC(props?.result ? (props?.result?.idRegistry?.pec ? props?.result?.idRegistry?.pec : "") : "")
            setAddress(props?.result ? (props?.result?.idRegistry?.address ? props?.result?.idRegistry?.address : "") : "")
            setCap(props?.result ? (props?.result?.idRegistry?.cap ? props?.result?.idRegistry?.cap : "") : "")
            setPassword('')
        }
    }, [props])

    const handleSaveUser = async () => {
        let body = password ? {
            idRegistry: {
                firstName: firstName,
                lastName: lastName,
                pIva: pIva,
                codFisc: codFisc,
                city: city,
                address: address,
                cap: cap,
                email: email,
                pec: pec,
                telnum: tellNum,
                cellnum: cellnum,
                isValid: true
            },
            password: password,
            username: email,
            role: props.idRole
        } : {
            idRegistry: {
                firstName: firstName,
                lastName: lastName,
                pIva: pIva,
                codFisc: codFisc,
                city: city,
                address: address,
                cap: cap,
                email: email,
                pec: pec,
                telnum: tellNum,
                cellnum: cellnum,
                isValid: true
            },
            username: email,
            role: props.idRole
        }
        let _results
        if (props.results) {
            _results = [...props.results]
        }
        let method = props?.result ? 'PUT' : 'POST';
        let url = props?.result ? `authService/user-registry?id=${props?.result?.id}&idRegistry=${props?.result?.idRegistry?.id}` : 'authService/user-registry'
        await APIRequest(method, url, body, dispatch)
            .then(res => {
                if (_results?.length > 0) {
                    let findIndex = _results.findIndex(el => el.id === res.data.data.id)
                    if (findIndex >= 0) {
                        _results[findIndex] = res.data.data
                    } else {
                        _results.push(res.data.data)
                        handleDialogAndBreadcrumbs(props.setDialog, dispatch);
                    }
                    props.setResults(_results)
                }
                setPassword("")
                toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: `${`L'utente è stato ${props?.result ? `modificato` : `aggiunto`} correttamente`}`, life: 3000 });
            }).catch((e) => {
                console.log(e)
                if (e.code !== "ERR_NETWORK") {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile modificare l'utente", life: 3000 });
                }
            })
    }

    const calculateProgress = () => {
        let completedFields = 1;
        let totalFields = 11; // il numero totale di campi obbligatori

        if (firstName /* && firstName?.match(/^[a-zA-Zàèìòù����������áéíóúÁÉÍÓ��]+$/) */) {
            completedFields++;
        }
        if (lastName /* && lastName?.match(/^[a-zA-Zàèìòù����������áéíóúÁÉÍÓ��]+$/) */) {
            completedFields++;
        }
        if (codFisc && codFisc?.length === 16 && codFisc?.match(/^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/)) {
            completedFields++;
        }
        if (pIva && pIva?.length === 11 && pIva?.match(/^[0-9]{11}$/)) {
            completedFields++;
        }
        if (address) {
            completedFields++;
        }
        if (city) {
            completedFields++;
        }
        if (cap && cap?.length === 5 && cap?.match(/^[0-9]{5}$/)) {
            completedFields++;
        }
        if (pec && pec?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            completedFields++;
        }
        if (email && email?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
            completedFields++;
        }

        return ((completedFields / totalFields) * 100).toFixed(2);
    };

    useEffect(() => {
        setProgress(calculateProgress());
    }, [firstName, lastName, codFisc, pIva, address, city, cap, cellnum, tellNum, pec, email]);

    return (
        <div className="card">
            <Toast ref={toast} />
            {window.location.pathname === "/profilo" &&
                <div className='w-full flex flex-col items-center justify-center mb-2'>
                    <p className='text-lg text-start w-full'>Completamento del profilo</p>
                    <div className="liquid-progress">
                        <ProgressBar value={progress} showValue={false}></ProgressBar>
                        <div className="progressbar-complete" style={{ width: `${progress}%` }}>
                            <div className="progressbar-liquid"></div>
                            <span className="progress text-fluid0">{progress}%</span>
                        </div>
                    </div>
                </div>
            }
            <div className="grid grid-cols-3 max-[670px]:grid-cols-2 max-[520px]:grid-cols-1 gap-3 mb-4">
                <div className="mt-3">
                    <label htmlFor="firstName" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-user mr-3"></i>Nome *
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='firstName' placeholder="Nome" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    {/* {(firstName !== "" && firstName &&
                        firstName?.length > 3 && !firstName?.match(/^[a-zA-Zàèìòù����������áéíóúÁÉÍÓ��]+$/)) &&
                        <p className="text-start text-red-500">Il nome può contenere solo lettere</p>
                    } */}
                    {(firstName === "" || !firstName) &&
                        <p className='text-start text-red-500'>Inserire il nome</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="lastName" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-user mr-3"></i>Cognome *
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='lastName' placeholder="Cognome" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    {/* {(lastName !== "" && lastName &&
                        lastName?.length > 3 && !lastName?.match(/^[a-zA-Zàèìòù����������áéíóúÁÉÍÓ��]+$/)) &&
                        <p className="text-start text-red-500">Il nome può contenere solo lettere</p>
                    } */}
                    {(lastName === "" || !lastName) &&
                        <p className='text-start text-red-500'>Inserire il nome</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="pIva" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-id-card mr-3"></i>Cod. fiscale
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" maxLength={16} id='codFisc' placeholder="Codice fiscale" value={codFisc} onChange={(e) => setCodFisc(e.target.value)} />
                    {(codFisc !== "" && codFisc && codFisc?.length < 16) &&
                        <p className="text-start text-red-500">Il codice fiscale deve essere di 16 caratteri</p>
                    }
                    {(codFisc !== "" && codFisc &&
                        codFisc?.length > 16 && !codFisc?.match(/^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/)) &&
                        <p className="text-start text-red-500">Il codice fiscale non è valido</p>
                    }
                    {(codFisc === "" || !codFisc) &&
                        <p className='text-start text-amber-500'>Inserire il codice fiscale</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="pIva" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-credit-card mr-3"></i>p. IVA
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" maxLength={11} id='pIva' placeholder="Partita IVA" value={pIva} onChange={(e) => setPIva(e.target.value)} />
                    {(pIva !== "" && pIva && pIva?.length < 11) &&
                        <p className="text-start text-red-500">La partita IVA deve essere di 11 caratteri</p>
                    }
                    {(pIva !== "" && pIva && pIva?.length > 11 && !pIva?.match(/^[0-9]{11}$/)) &&
                        <p className="text-start text-red-500">La partita IVA non è valida</p>
                    }
                    {(pIva === "" || !pIva) &&
                        <p className='text-start text-amber-500'>Inserire la partita IVA</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="address" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-map-marker mr-3"></i>Indirizzo
                    </label>
                    <InputText className='w-full select-none p-2 border focus:shadow-none' id='address' placeholder="Indirizzo" value={address} onChange={(e) => setAddress(e.target.value)} />
                    {(address === "" || !address) &&
                        <p className='text-start text-amber-500'>Inserire l'indirizzo</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="city" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-map mr-3"></i>Città
                    </label>
                    <InputText className='w-full select-none p-2 border focus:shadow-none' id='city' placeholder="Città" value={city} onChange={(e) => setCity(e.target.value)} />
                    {(city === "" || !city) &&
                        <p className='text-start text-amber-500'>Inserire la città</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="cap" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-map-marker mr-3"></i>CAP
                    </label>
                    <InputText className='w-full select-none p-2 border focus:shadow-none' maxLength={5} id='cap' placeholder="CAP" value={cap} onChange={(e) => setCap(e.target.value)} />
                    {(cap !== "" && cap && cap?.length < 5) &&
                        <p className="text-start text-red-500">Il CAP deve essere di 5 caratteri</p>
                    }
                    {(cap !== "" && cap && cap?.length > 5 && !cap?.match(/^[0-9]{5}$/)) &&
                        <p className="text-start text-red-500">Il CAP non è valido</p>
                    }
                    {(cap === "" || !cap) &&
                        <p className='text-start text-amber-500'>Inserire il CAP</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="cellnum" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-mobile mr-3"></i>Cellulare
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='cellnum' placeholder="Cellulare" value={cellnum} onChange={(e) => setCellNum(e.target.value)} />
                    {(cellnum !== "" && cellnum && !cellnum?.match(/^[0-9]{10}$/)) &&
                        <p className="text-start text-red-500">Il numero di cellulare non è valido</p>
                    }
                    {(cellnum === "" || !cellnum) &&
                        <p className='text-start text-amber-500'>Inserire il numero di cellulare</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="tellNum" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-phone mr-3"></i>Telefono
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id='tellNum' placeholder="Telefono" value={tellNum} onChange={(e) => setTellNum(e.target.value)} />
                    {(tellNum !== "" && !tellNum?.match(/^[0-9]{8}$/)) &&
                        <p className="text-start text-red-500">Il numero di telefono non è valido</p>
                    }
                    {(tellNum === "" || !tellNum) &&
                        <p className='text-start text-amber-500'>Inserire il numero di telefono</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="pec" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-envelope mr-3"></i>PEC
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id="pec" placeholder="PEC" value={pec} onChange={(e) => setPEC(e.target.value)} />
                    {(pec === "" || !pec) && (
                        <p className='text-start text-red-500'>Inserire la PEC</p>
                    )}
                    {(pec !== "" && pec && !pec?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) && (
                        <p className="text-start text-amber-500">L'indirizzo PEC non è valido</p>
                    )}
                </div>
                <div className="mt-3">
                    <label htmlFor="email" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-at mr-3"></i>Email *
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none" id="email" keyfilter="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {(email === "" || !email) &&
                        <p className='text-start text-red-500'>Inserire l'email</p>
                    }
                    {(email !== "" && email && !email?.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) &&
                        <p className="text-start text-red-500">L'indirizzo email non è valido</p>
                    }
                </div>
                <div className="mt-3">
                    <label htmlFor="password" className="select-none font-bold block flex flex-row items-center justify-start mb-2">
                        <i className="pi pi-key mr-3"></i>Password *
                    </label>
                    <div className="card flex justify-content-center">
                        <Password className='w-full select-none p-2 border focus:shadow-none' id='password' minLength={8} placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)}
                            promptLabel="Scegli una password" weakLabel="Fragile" mediumLabel="Media" strongLabel="Sicura"
                            toggleMask
                        />
                    </div>
                    {(password === "" || !password) &&
                        <p className='text-start text-red-500'>Inserire una password</p>
                    }
                    {(password !== "" && password && password?.length < 8) &&
                        <p className="text-start text-red-500">La password deve essere lunga almeno 8 caratteri</p>
                    }
                </div>
            </div>
            <div className="flex justify-center items-end">
                <Button disabled={firstName === "" || lastName === "" || email === "" || password === ""}
                    className='buttonCustom ' tooltipOptions={{ showDelay: 1000, hideDelay: 300 }} label="Salva" onClick={() => handleSaveUser()} />
            </div>
        </div>
    )
}
