import { useDispatch, useSelector } from "react-redux";
import Profilo from "../../components/profile";
import { useEffect, useState } from "react";
import { resetPathCrumb } from "../../store/actions/actions";
import { APIRequest } from "../../services/axios";

function Profile() {
    const dispatch = useDispatch();
    const [result, setResult] = useState(null)
    const allResults = useSelector(state => state.allResults.results)

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', 'authService/user-registry?id='+ JSON.parse(window.localStorage.getItem("user")).id, null, dispatch)
                .then((res) => {
                    if (res.data.success) {
                        setResult(res.data.data);
                    } else {
                        console.log(res.data.message)
                    }
                })
                .catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        if (find) {
                            setResult(find.results)
                        }
                    }
                    console.log(e);
                });
        }
        trovaRisultato();
    },[])

    useEffect(() => {
        dispatch(resetPathCrumb())
    }, [dispatch])

    return (
        <>
            <div className="w-full h-full flex flex-row items-center justify-start ">
                <div className={`h-full w-full`}>
                    <Profilo result={result ? (result.length > 0 ? result[0] : result) : null} role={result ? (result.length > 0 ? result[0]?.role?.id : result) : null} />
                </div>
            </div>
        </>
    )
}

export default Profile;
