
import React, { useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { APIRequest } from '../services/axios';
import { Toast } from 'primereact/toast';
import { Dropdown } from 'primereact/dropdown';

export default function RootForm(props) {
    const [path, setPath] = useState('');
    const [service, setService] = useState('');
    const [method, setMethod] = useState(null);
    const toast = useRef(null);
    const optionMethod = [{ name: "GET", code: "GET" }, { name: "PUT", code: "PUT" }, { name: "POST", code: "POST" }, { name: "DELETE", code: "DELETE" }]

    const handleSaveRoot = async () => {
        let body = {
            path,
            method: method.code,
            service
        }
        await APIRequest('POST', `authService/route`, body)
            .then(res => {
                let _route = [...props.route]
                _route.push(res.data.data)
                props.setRoute(_route)
                props.setDialog(false)
                toast.current.show({ severity: 'success', summary: 'Ottimo!', detail: `La rotta è stata aggiunta correttamente`, life: 3000 });
            }).catch((e) => {
                console.log(e)
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile aggiungere la rotta", life: 3000 });
            })
    }

    return (
        <div className="card">
            <Toast ref={toast} />
            <div className="flex flex-wrap gap-3 mb-4">
                <div className="flex-auto">
                    <label htmlFor="path" className="select-none font-bold block mb-2 select-none">
                        Path
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none select-none" id='path' placeholder="Path" value={path} onChange={(e) => setPath(e.target.value)} />
                </div>
                <div className="flex-auto">
                    <label htmlFor="method" className="select-none font-bold block mb-2 select-none">
                        Metodo
                    </label>
                    <Dropdown value={method} showClear onChange={(e) => setMethod(e.value)} options={optionMethod} optionLabel="name"
                        placeholder="Seleziona metodo" className="w-full md:w-14rem" />
                    {/* <InputText className="w-full select-none p-2 border focus:shadow-none select-none" id='method' placeholder="Metodo" value={method} onChange={(e) => setMethod(e.target.value)} /> */}
                </div>
                <div className="flex-auto">
                    <label htmlFor="service" className="select-none font-bold block mb-2 select-none">
                        Servizio
                    </label>
                    <InputText className="w-full select-none p-2 border focus:shadow-none select-none" id='service' placeholder="Servizio" value={service} onChange={(e) => setService(e.target.value)} />
                </div>
            </div>
            <div className="flex justify-center items-end">
                <Button className='buttonCustom !w-2/12' tooltipOptions={{ showDelay: 1000, hideDelay: 300 }} label="Salva" onClick={() => handleSaveRoot()} />
            </div>
        </div>
    )
}
