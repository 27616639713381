import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../components/headPage';
import { Toast } from 'primereact/toast';
import { resetPathCrumb } from '../store/actions/actions';
import { useDispatch } from 'react-redux';
import { MdOutlineInfo, MdTextFields } from 'react-icons/md';
import { CiImport } from 'react-icons/ci';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { APIRequest } from '../services/axios';
import { Tooltip } from 'primereact/tooltip';
import { ProgressBar } from 'primereact/progressbar';
import { Tag } from 'primereact/tag';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useWindowSize } from '../utils/utils';

function ImportFile() {

    const typeSeparetorList = [{ value: 0, name: ";" }, { value: 1, name: "|" }]
    const typeValueSeparetorList = [{ value: 0, name: "," }, { value: 1, name: "." }]
    const [typeSeparetor, setTypeSeparetor] = useState(null)
    const [typeValueSeparetor, setTypeValueSeparetor] = useState(null)
    const [typeImport, setTypeImport] = useState(null)
    const [selectedTypeImport, setSelectedTypeImport] = useState(null)
    const [options, setOptions] = useState(null)
    const [optionsEntities, setOptionsEntities] = useState(null)
    const [selectedEntities, setSelectedEntities] = useState(null)
    const [fields, setFields] = useState(null)
    const [totalSize, setTotalSize] = useState(0);
    const [csvData, setCsvData] = useState([]);
    const [header, setHeader] = useState([]);
    const [fileBuffer, setFileBuffer] = useState(null);
    const [headerMapping, setHeaderMapping] = useState({});
    const dispatch = useDispatch()
    const [width] = useWindowSize();
    const toast = useRef(null);
    const fileRef = useRef(null);

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest('GET', `importExportService/service`)
                .then(res => {
                    if (res.data.success) {
                        let x = []
                        res.data.data.forEach(element => {
                            x.push({ name: element.name, code: element.id })
                        });
                        setOptions(x)
                        setTypeImport(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile caricare la formazione.`, life: 3000 });
                });
        }
        trovaRisultato();
    }, [dispatch])

    useEffect(() => {
        dispatch(resetPathCrumb())
    }, [dispatch])

    const haldleSelectTypeImport = (e) => {
        setSelectedTypeImport(e.value)
        let find = typeImport.find(el => el.id === e.value.code)
        let x = []
        find.entities.forEach(element => {
            x.push({ name: element.name, code: element.id })
        })
        setOptionsEntities(x)
        setFields(null)
    }

    const haldleSelectEntities = (e) => {
        let find = typeImport.find(el => el.id === selectedTypeImport.code)
        let findEntities = find.entities.find(el => el.id === e.value.code)
        setFields(findEntities.fields)
        setSelectedEntities(e.value)
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;

        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, uploadButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
                {chooseButton}
                {uploadButton}
                {cancelButton}
                <div className="flex items-center gap-3 ml-auto">
                    <span>{formatedValue} / 1 MB</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                        <small>{new Date().toLocaleDateString()}</small>
                    </span>
                </div>
                <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };

    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Carica file
                </span>
            </div>
        );
    };

    const customUploadHandler = async (file) => {
        if (file) {

            const text = await file.files[0].text();
            const rows = text.split('\n').map(row => row.split(typeSeparetorList.find(el => el.value === typeSeparetor)?.name));
            const header = rows[0];
            const data = rows.slice(1).map(row => {
                const obj = {};
                row.forEach((cell, i) => {
                    obj[header[i]] = cell;
                });
                return obj;
            });

            const initialMapping = header.reduce((acc, curr) => ({ ...acc, [curr]: curr }), {});
            setHeaderMapping(initialMapping);

            setHeader(header);
            setCsvData(data);
            setFileBuffer(text);  // Save the file content for later upload confirmation
        }
    };

    const handleHeaderChange = (e, col) => {
        const newMapping = { ...headerMapping, [col]: e.value };

        // Rimuovi la precedente mappatura se il nuovo valore è già in uso
        for (const key in newMapping) {
            if (key !== col && newMapping[key] === e.value) {
                newMapping[key] = ""; // Rimuove la precedente mappatura
            }
        }

        setHeaderMapping(newMapping);
    };

    const handleConfirmUpload = async () => {
        let find = typeImport.find(el => el.id === selectedTypeImport?.code);
        let findEntities = find?.entities?.find(el => el.id === selectedEntities?.code);
        // Re-send the saved buffer to the backend for final processing
        const formData = new FormData();
        formData.append('csv', new Blob([fileBuffer], { type: 'text/csv' }));
        formData.append('separator', typeSeparetorList.find(el => el.value === typeSeparetor)?.name);
        formData.append('decimalDelimeter', typeValueSeparetorList.find(el => el.value === typeValueSeparetor)?.name);
        formData.append('type', fields[0]?.name);
        formData.append('route', selectedTypeImport?.name);
        formData.append('entity', findEntities?.path);

        await APIRequest('POST', 'importExportService/import', formData)
            .then(res => {
                console.log(res);
                toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'Il file è stato importato con successo', life: 3000 });
            })
            .catch(e => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile importare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
            });
    }

    const handleClickDownload = async () => {
        let find = typeImport.find(el => el.id === selectedTypeImport?.code);
        let findEntities = find.entities.find(el => el.id === selectedEntities?.code);
        console.log(findEntities);
        let body = { separator: ';', typeImport: selectedTypeImport?.name, entity: findEntities?.path };

        await APIRequest('POST', 'importExportService/export', body, { responseType: 'blob' })
            .then(res => {
                const csvContent = res.data;
                const blob = new Blob([csvContent], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = `${findEntities.name}.csv`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                toast.current.show({ severity: 'success', summary: 'Ottimo', detail: 'Il file è stato scaricato con successo', life: 3000 });
            })
            .catch(e => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Non è stato possibile scaricare il file. Messaggio errore: ${e.response?.data !== undefined ? e.response?.data : e.message}`, life: 3000 });
            });
    };

    const validateData = () => {
        let isValid = true;
        csvData.forEach(row => {
            fields.forEach(field => {
                const value = row[headerMapping[field.name]];
                if (field.is_required && !value) {
                    isValid = false;
                }
                if (field.data_type === 'number' && isNaN(Number(value))) {
                    isValid = false;
                }
            });
        });
        return isValid;
    }

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success rounded-full border border-green-700 p-button-outlined' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined' };

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className={`flex-grow transition-all duration-300 bg-stone-100`}>
                <div className='flex flex-row'>

                    <div className='max-[700px]:w-full w-8/12 m-5'>
                        <div className='flex flex-col bg-white border border-[#00b1b14D] rounded-lg'>
                            <div className='w-full text-start px-4 pt-5 flex flex-row items-center gap-2 text-xl font-bold'><CiImport size={25} /> Import</div>
                            <div className='px-4 flex flex-col'>
                                <hr className='border border-[#00b1b14D] my-5' />
                                <div className=' flex flex-col'>
                                    <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona la tipologia di import</div>
                                    <Dropdown showClear value={selectedTypeImport} onChange={(e) => haldleSelectTypeImport(e)} options={options} optionLabel='name' placeholder='Seleziona la tipologia' className='border border-[#00b1b14D] rounded-md removeFocus' />
                                    {selectedTypeImport &&
                                        <p className={'w-full text-start text-red-800 ml-2 my-4'}>
                                            *{typeImport.find(el => el.id === selectedTypeImport.code).description}
                                        </p>
                                    }
                                </div>
                                <div className=' flex flex-col'>
                                    <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona la categoria per il tipo di import</div>
                                    <Dropdown showClear value={selectedEntities} onChange={(e) => haldleSelectEntities(e)} options={optionsEntities} optionLabel='name' placeholder='Seleziona la tipologia' className='border border-[#00b1b14D] rounded-md removeFocus' disabled={!selectedTypeImport} />
                                    {selectedEntities &&
                                        <p className={'w-full text-start text-red-800 ml-2 mt-4 mb-2'}>
                                            *{typeImport?.find(el => el.id === selectedTypeImport?.code)?.entities?.find(el => el.id === selectedEntities?.code)?.description}
                                        </p>
                                    }
                                </div>
                                {selectedEntities &&
                                    <>
                                        <hr className='border border-[#00b1b14D] my-5' />
                                        <div className='flex flex-col bg-white border border-[#00b1b14D] rounded-lg w-full min-[700px]:hidden'>
                                            <div className='w-full text-start px-4 pt-5 flex flex-row items-center gap-2 text-xl font-bold'><MdTextFields size={25} /> Fields</div>
                                            <hr className='border border-[#00b1b14D] my-5' />
                                            <div className='w-full px-4'>
                                                {fields &&
                                                    <>
                                                        <div className='border-2 border-[#00b1b1] w-full flex flex-row'>
                                                            <div className='w-2/12 bg-[#00b1b14D] flex flex-row items-center justify-center'><MdOutlineInfo size={30} color='#00b1b1' /></div>
                                                            <div className='w-10/12 text-start px-4 py-2'>
                                                                <ul>
                                                                    {fields.map((el, key) => {
                                                                        return (
                                                                            <React.Fragment key={key}>
                                                                                <li className='flex flex-row items-center justify-between'>
                                                                                    <span className='flex flex-row items-center'>
                                                                                        {el.name}
                                                                                        <p className={`${el.is_required ? 'text-red-800 ml-1' : ''}`}>
                                                                                            {`${el.is_required ? '*' : ''}`}
                                                                                        </p>
                                                                                    </span>
                                                                                    {el.data_type}
                                                                                </li>
                                                                            </React.Fragment>
                                                                        )
                                                                    })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className='w-full text-start text-sm p-2 font-semibold'>* Fields richiesti</div>
                                                        <div className='w-full text-start text-sm p-2 font-semibold flex justify-center items-center'>Scarica csv di esempio <Button type="button" icon="pi pi-download" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={handleClickDownload} /></div>
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </>
                                }
                                <hr className='border border-[#00b1b14D] my-5' />
                                <div className='flexflex-col justify-between'>
                                    <div className='flex flex-col'>
                                        <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona il separatore di colonne</div>
                                        <div className='flex flex-row justify-start'>
                                            <Dropdown showClear value={typeSeparetor} onChange={(e) => setTypeSeparetor(e.value)} options={typeSeparetorList} optionLabel='name' placeholder='Seleziona la tipologia' className='border border-[#00b1b14D] rounded-md w-full removeFocus' />
                                        </div>
                                    </div>
                                    <div className='flex flex-col'>
                                        <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona il separatore di numeri multipli</div>
                                        <div className='flex flex-row justify-start'>
                                            <Dropdown showClear value={typeValueSeparetor} onChange={(e) => setTypeValueSeparetor(e.value)} options={typeValueSeparetorList} optionLabel='name' placeholder='Seleziona la tipologia' className='border border-[#00b1b14D] rounded-md w-full removeFocus' />
                                        </div>
                                    </div>
                                </div>
                                <hr className='border border-[#00b1b14D] my-5' />
                                <div className=' flex flex-col'>
                                    <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Seleziona il file da importare</div>
                                    <Tooltip target=".custom-choose-btn" content="Seleziona" position="bottom" />
                                    <Tooltip target=".custom-upload-btn" content="Carica" position="bottom" />
                                    <Tooltip target=".custom-cancel-btn" content="Rimuovi" position="bottom" />

                                    <FileUpload ref={fileRef} name="demo[]" url="/api/upload" accept=".CSV" maxFileSize={1000000}
                                        onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                                        customUpload uploadHandler={customUploadHandler}
                                    />
                                    <div className='w-full text-start text-sm p-2'>Solo file con estensione .csv</div>
                                </div>
                                <hr className='border border-[#00b1b14D] my-5' />
                            </div>
                        </div>
                    </div>
                    <div className='w-4/12 relative m-5 max-[700px]:hidden'>
                        <div className='sticky top-[6.25rem]'>
                            <div className='flex flex-col bg-white border border-[#00b1b14D] rounded-lg'>
                                <div className='w-full text-start px-4 pt-5 flex flex-row items-center gap-2 text-xl font-bold'><MdTextFields size={25} /> Fields</div>
                                <hr className='border border-[#00b1b14D] my-5' />
                                <div className='w-full px-4'>
                                    {fields &&
                                        <>
                                            <div className='border-2 border-[#00b1b1] w-full flex flex-row'>
                                                <div className='w-2/12 bg-[#00b1b14D] flex flex-row items-center justify-center'><MdOutlineInfo size={30} color='#00b1b1' /></div>
                                                <div className='w-10/12 text-start px-4 py-2'>
                                                    <ul>
                                                        {fields.map((el, key) => {
                                                            return (
                                                                <React.Fragment key={key}>
                                                                    <li className='flex flex-row items-center justify-between'>
                                                                        <span className='flex flex-row items-center'>
                                                                            {el.name}
                                                                            <p className={`${el.is_required ? 'text-red-800 ml-1' : ''}`}>
                                                                                {`${el.is_required ? '*' : ''}`}
                                                                            </p>
                                                                        </span>
                                                                        {el.data_type}
                                                                    </li>
                                                                </React.Fragment>
                                                            )
                                                        })
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className='w-full text-start text-sm p-2 font-semibold'>* Fields richiesti</div>
                                            <div className='w-full text-start text-sm p-2 font-semibold flex justify-center items-center'>Scarica csv di esempio <Button type="button" icon="pi pi-download" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={handleClickDownload} /></div>
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {csvData.length > 0 && (
                    <div className='w-full p-5'>
                        <div className='flex flex-col bg-white border border-[#00b1b14D] rounded-lg p-5'>
                            <div className="w-full flex flex-col">
                                <div className='w-full text-start py-2 flex flex-row items-center gap-2 text-md font-semibold'>Preview del file CSV</div>
                                <DataTable value={csvData}>
                                    {header.map((col, index) => (
                                        <Column key={index} field={col} header={
                                            <>
                                                <Dropdown
                                                showClear
                                                    value={headerMapping[col]}
                                                    options={fields.map(f => ({ label: f.name, value: f.name }))}
                                                    onChange={(e) => handleHeaderChange(e, col)}
                                                    placeholder="Seleziona Campo"
                                                />
                                            </>
                                        } />
                                    ))}
                                </DataTable>
                                <hr className='border border-[#00b1b14D] my-5' />
                                <div className="w-full flex flex-row items-center justify-center">
                                    <Button type="button" label="Conferma" className="!w-3/12 buttonCustom" onClick={handleConfirmUpload} />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div >
    );
}

export default ImportFile;
