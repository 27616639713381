import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { APIRequest } from '../services/axios';
import { useDispatch, useSelector } from 'react-redux';
import { OverlayPanel } from 'primereact/overlaypanel';

function CreazioneContratto(props) {
    const dispatch = useDispatch()
    const [localRal, setLocalRal] = useState(props.ral);
    const [isManualRal, setIsManualRal] = useState(false);
    const op = useRef(null);
    const allResults = useSelector(state => state.allResults.results)


    useEffect(() => {
        if (props.oreLavoro !== null) {
            let rapportoOrario = props.oreLavoro / 38;

            // Controllo per aggiornare la RAL automaticamente solo se non è manuale
            if (!isManualRal) {
                let orarioLordo = (22705 / 52) / 38;
                let nuovaRAL = orarioLordo * props.oreLavoro * 52;

                // Aggiorna la RAL solo se il nuovo valore è diverso
                if (props.ral !== nuovaRAL) {
                    props.setRal(nuovaRAL);
                }
            }

            // Calcolo del rimborso carburante
            let rapportoRal = props.ral / 22705;
            let rimborsoCarburante = 150 * rapportoOrario * rapportoRal;
            props.setRimborsoCarb(rimborsoCarburante);
            props.setBuoniPasto(rimborsoCarburante);
        }
    }, [props.oreLavoro, props.ral, isManualRal]);

    useEffect(() => {
        setIsManualRal(false); // Permetti aggiornamenti automatici
    }, [props.oreLavoro]);

    // Funzione per aggiornare manualmente la RAL
    const handleRalChange = (newRal) => {
        setIsManualRal(true); // Imposta la modifica manuale
        props.setRal(newRal); // Aggiorna la RAL manualmente
    };

    const chosenContract = async (value) => {
        if(value){
            await APIRequest("GET", `recruitmentService/contractsByJobPosition?jobPositionId=${props.candidato?.jobPosition?.id}&contractTypeId=${value.id}`, null, dispatch)
                .then(res => {
                    if (res.data.success) {
                        props.setContratto(value)
                        props.setInputText(res.data.data[0].fields)
                        props.setAttachments(res.data.data[0].attachments)
                        
                        props.setFinalContract(res.data.data[0])
                    } else {
                        props.toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        props.toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            props.setContratto(value)
                            props.setInputText(find[0].fields)
                            props.setAttachments(find[0].attachments)
                            props.setFinalContract(find[0])
                        }
                    } else {
                        props.toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare il contratto", life: 3000 });
                    }
                    console.log(e)
                })
        } else {
            props.setContratto(null)
            props.setInputText(null)
            props.setAttachments(null)
            props.setFinalContract(null)
        }
    }


    return (
        <div className="grid grid-cols-2 max-[720px]:grid-cols-1 max-[720px]:grid-flow-row max-[720px]:auto-rows-max gap-5 h-full">
            <div className="col-span-2">
                <div className='grid grid-cols-2 gap-5 w-full'>
                    <div className='grid grid-cols-7 col-span-2 gap-5 items-center justify-between border-b-2 pb-5 border-[#00b1b166]'>
                        <div className='col-span-3 max-[1200px]:col-span-7 max-[900px]:hidden flex flex-col gap-2 items-center w-full'>
                            <p className="text-start text-xl font-semibold mb-2 w-full">Seleziona un modulo</p>
                            <p className="text-start text-base mb-2 w-full">
                                Qui puoi scegliere il tipo di contratto più adatto al candidato in base al ruolo e alla durata del rapporto di lavoro. Di seguito una spiegazione di ciascun modulo per aiutarti nella selezione:
                                <br /><br />
                                <strong>1. Partita IVA (Libero Professionista):</strong> Indicato per collaborazioni con professionisti autonomi, questo modulo consente massima flessibilità e indipendenza, con il candidato che lavora in proprio e offre servizi a più clienti. È utile per chi richiede totale autonomia nella gestione fiscale e contributiva e per incarichi di consulenza continuativa.
                                <br /><br />
                                <strong>2. Contratto di Lavoro Subordinato:</strong> La scelta ideale per posizioni che richiedono stabilità e subordinazione. Il candidato viene inserito come dipendente e ha diritto a ferie, malattia e contributi previdenziali pagati dall’azienda. È adatto per incarichi con orari definiti e per chi cerca sicurezza e continuità.
                                <br /><br />
                                <strong>3. Collaborazione Coordinata e Continuativa (Co.Co.Co.):</strong> Ideale per rapporti di collaborazione specifici e a progetto, senza vincolo di subordinazione. Questo contratto è adatto a professionisti che gestiscono le proprie attività in autonomia, ma che necessitano di coordinamento su obiettivi chiari. Viene solitamente utilizzato per incarichi che non richiedono una presenza continua.
                            </p>
                        </div>
                        <div className='col-span-1 max-[1200px]:hidden flex flex-col gap-2 items-center w-full'>
                            <i className='pi pi-chevron-right text-fluid1'></i>
                        </div>
                        <div className='col-span-3 max-[1200px]:col-span-7 flex flex-col gap-2 items-center w-full'>
                            <p className="text-start text-xl font-semibold mb-2 w-full flex flex-row gap-3">Seleziona tipologia contratto <Button className='flex flex-row items-center justify-center bg-gray-300 size-7 min-[899px]:hidden rounded-full' onClick={(e) => op.current.toggle(e)} icon="pi pi-info" /></p>
                            <OverlayPanel ref={op} onClick={() => op.current.hide()}>
                                <p className="text-start text-xl font-semibold mb-2 w-full">Seleziona un modulo</p>
                                <p className="text-start text-base mb-2 w-full">
                                    Qui puoi scegliere il tipo di contratto più adatto al candidato in base al ruolo e alla durata del rapporto di lavoro. Di seguito una spiegazione di ciascun modulo per aiutarti nella selezione:
                                    <br /><br />
                                    <strong>1. Collaborazione Coordinata e Continuativa (Co.Co.Co.):</strong> Ideale per rapporti di collaborazione specifici e a progetto, senza vincolo di subordinazione. Questo contratto è adatto a professionisti che gestiscono le proprie attività in autonomia, ma che necessitano di coordinamento su obiettivi chiari. Viene solitamente utilizzato per incarichi che non richiedono una presenza continua.
                                    <br /><br />
                                    <strong>2. Partita IVA (Libero Professionista):</strong> Indicato per collaborazioni con professionisti autonomi, questo modulo consente massima flessibilità e indipendenza, con il candidato che lavora in proprio e offre servizi a più clienti. È utile per chi richiede totale autonomia nella gestione fiscale e contributiva e per incarichi di consulenza continuativa.
                                    <br /><br />
                                    <strong>3. Contratto di Lavoro Subordinato:</strong> La scelta ideale per posizioni che richiedono stabilità e subordinazione. Il candidato viene inserito come dipendente e ha diritto a ferie, malattia e contributi previdenziali pagati dall’azienda. È adatto per incarichi con orari definiti e per chi cerca sicurezza e continuità.
                                </p>
                            </OverlayPanel>
                            <Dropdown
                                value={props.contratto}
                                onChange={(e) => chosenContract(e.value)}
                                options={props.contratti}
                                showClear
                                optionLabel="label"
                                placeholder='Tipologia contratto'
                                filter
                                className="w-full rounded-md border border-[#00b1b1]"
                                checkmark={true}
                                highlightOnSelect={false}
                            />
                        </div>
                    </div>
                    {props.contratto &&
                        <div className='grid grid-cols-7 col-span-2 gap-5 mb-10 items-center justify-between'>
                            <div className='col-span-3 max-[1200px]:col-span-7 max-[900px]:hidden flex flex-col gap-2 items-center w-full'>
                                <p className="text-start text-xl font-semibold mb-2 w-full">Guida alla compilazione delle condizioni contrattuali</p>
                                <p className="text-start text-base mb-2 w-full">
                                    Di seguito puoi specificare alcune condizioni contrattuali per il candidato selezionato. Ecco cosa rappresentano i vari campi e come devono essere compilati:
                                </p>
                                <ul className="text-start text-base list-disc list-inside">
                                    <li>
                                        <strong>Ore lavoro settimanali</strong>: Indica il numero di ore di lavoro previste ogni settimana. Compila questo campo in base all’orario contrattuale del candidato.
                                    </li>
                                    <li>
                                        <strong>RAL (Retribuzione Annua Lorda)</strong>: Specifica l’importo annuo lordo della retribuzione del candidato. Il valore deve essere espresso in euro e include tutte le componenti salariali.
                                    </li>
                                    <li>
                                        <strong>Rimborso carburante</strong>: Indica un eventuale importo per il rimborso carburante, se previsto. Compila questo campo solo se il contratto prevede spostamenti lavorativi a carico del dipendente.
                                    </li>
                                    <li>
                                        <strong>Buoni pasto</strong>: Specifica l'importo mensile previsto per i buoni pasto, se applicabile. È indicato per contratti che prevedono l'erogazione di buoni pasto come benefit.
                                    </li>
                                </ul>
                            </div>
                            <div className='col-span-1 max-[1200px]:hidden flex flex-col gap-2 items-center w-full'>
                                <i className='pi pi-chevron-right text-fluid1'></i>
                            </div>
                            <div className='col-span-3 max-[1200px]:col-span-7 flex flex-col gap-2 items-center w-full'>
                                <p className="text-start text-xl font-semibold mb-2 w-full flex flex-row gap-3">Aggiunta condizioni contrattuali <Button className='flex flex-row items-center justify-center bg-gray-300 size-7 min-[899px]:hidden rounded-full' onClick={(e) => op.current.toggle(e)} icon="pi pi-info" /></p>
                                <OverlayPanel ref={op} onClick={() => op.current.hide()}>
                                    <p className="text-start text-xl font-semibold mb-2 w-full">Guida alla compilazione delle condizioni contrattuali</p>
                                    <p className="text-start text-base mb-2 w-full">
                                        Di seguito puoi specificare alcune condizioni contrattuali per il candidato selezionato. Ecco cosa rappresentano i vari campi e come devono essere compilati:
                                    </p>
                                    <ul className="text-start text-base list-disc list-inside">
                                        <li>
                                            <strong>Ore lavoro settimanali</strong>: Indica il numero di ore di lavoro previste ogni settimana. Compila questo campo in base all’orario contrattuale del candidato.
                                        </li>
                                        <li>
                                            <strong>RAL (Retribuzione Annua Lorda)</strong>: Specifica l’importo annuo lordo della retribuzione del candidato. Il valore deve essere espresso in euro e include tutte le componenti salariali.
                                        </li>
                                        <li>
                                            <strong>Rimborso carburante</strong>: Indica un eventuale importo per il rimborso carburante, se previsto. Compila questo campo solo se il contratto prevede spostamenti lavorativi a carico del dipendente.
                                        </li>
                                        <li>
                                            <strong>Buoni pasto</strong>: Specifica l'importo mensile previsto per i buoni pasto, se applicabile. È indicato per contratti che prevedono l'erogazione di buoni pasto come benefit.
                                        </li>
                                    </ul>
                                </OverlayPanel>
                                <div className='flex flex-col items-start justify-center w-full gap-5'>
                                    <div className='flex flex-col items-start justify-center w-full'>
                                        <p className='w-full text-start'>Ore lavoro settimanali:</p>
                                        <InputNumber
                                            showButtons
                                            min={0}
                                            placeholder="Ore lavoro settimanali"
                                            onValueChange={(e) => props.setOreLavoro(e.value)}
                                            value={props.oreLavoro}
                                            className="flex-grow border rounded-md px-5 py-1 w-full"
                                        />
                                    </div>
                                    <div className='flex flex-col items-start justify-center w-full'>
                                        <p className='w-full text-start'>RAL:</p>
                                        <InputNumber
                                            mode={"currency"}
                                            showButtons
                                            min={0}
                                            minFractionDigits={2}
                                            currency={"EUR"}
                                            placeholder="RAL"
                                            onValueChange={(e) => handleRalChange(Number(e.target.value))}
                                            value={props.ral}
                                            className="flex-grow border rounded-md px-5 py-1 w-full"
                                        />
                                    </div>
                                    <div className='flex flex-col items-start justify-center w-full'>
                                        <p className='w-full text-start'>Rimborso carburante:</p>
                                        <InputNumber
                                            mode={"currency"}
                                            showButtons
                                            min={0}
                                            minFractionDigits={2}
                                            currency={"EUR"}
                                            placeholder="Rimborso carburante"
                                            onValueChange={(e) => props.setRimborsoCarb(e.value)}
                                            value={props.rimborsoCarb}
                                            className="flex-grow border rounded-md px-5 py-1 w-full"
                                            disabled={props.oreLavoro === null || props.ral === null}
                                        />
                                    </div>
                                    <div className='flex flex-col items-start justify-center w-full'>
                                        <p className='w-full text-start'>Buoni pasto:</p>
                                        <InputNumber
                                            mode={"currency"}
                                            showButtons
                                            min={0}
                                            minFractionDigits={2}
                                            currency={"EUR"}
                                            placeholder="Buoni pasto"
                                            onValueChange={(e) => props.setBuoniPasto(e.value)}
                                            value={props.buoniPasto}
                                            className="flex-grow border rounded-md px-5 py-1 w-full"
                                            disabled={props.oreLavoro === null || props.ral === null}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}

export default CreazioneContratto;
