import { Outlet, useLocation } from "react-router-dom";
import { Sidebar } from "./sidebar";
import { useSelector } from "react-redux";
import { useWindowSize } from "../utils/utils";

function Layout() {
    const location = useLocation();
    const role = useSelector(state => state.menu.role);
    const [width] = useWindowSize();

    const hideSidebarPaths = ["/"]; // Aggiungi qui tutti i percorsi in cui vuoi nascondere la sidebar

    const shouldShowSidebar = role && !hideSidebarPaths.includes(location.pathname);

    return (
        <div className="flex flex-row max-[899px]:flex-col relative">
            {(shouldShowSidebar && width >= 901) &&
                <div className={`sticky top-0 z-30 min-[900px]:h-screen min-[900px]:!h-[calc(100vh-10px)]`}>
                    <Sidebar theme="light" />
                </div>
            }
            <div className={`w-screen h-full ${window.location.pathname === "/conversazioni" ? "" : "max-[899px]:mb-16"}`}>
                <Outlet />
            </div>
            {width < 901 && shouldShowSidebar &&
                <div className={`fixed bottom-0 w-full flex flex-col items-center justify-center`}>
                    <Sidebar theme="light" />
                </div>
            }
        </div>
    )
}

export default Layout;
