import React, { useState, useEffect, useRef } from 'react';
import HeadPage from '../../components/headPage';
import { APIRequest } from "../../services/axios";
import { Toast } from 'primereact/toast';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import FeedbackFrame from '../../components/feedbackFrame';

function QuestionariValutazioneDelServizio() {
    const [resultsResponse, setResultsResponse] = useState(null)
    const [resultsQuestion, setResultsQuestion] = useState(null)
    const [resultsQuestionType, setResultsQuestionType] = useState(null)
    const [dialog1, setDialog1] = useState(false)
    const [dialog2, setDialog2] = useState(false)
    const toast = useRef(null);
    const dispatch = useDispatch()
    const allResults = useSelector(state => state.allResults.results)

    useEffect(() => {
        async function trovaRisultato() {
            let questionType = null
            await APIRequest('GET', 'feedbackService/questionaryType', null, dispatch)
                .then(res => {
                    setResultsQuestionType(res.data.data.find(el => el.label === 'SERVIZIO').id);
                    questionType = res.data.data.find(el => el.label === 'SERVIZIO').id
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            setResultsQuestionType(find.results.find(el => el.label === 'SERVIZIO').id)
                            questionType = find.results.find(el => el.label === 'SERVIZIO').id
                        }
                    }
                    console.log(e)
                })
            if (questionType) {
                await APIRequest('GET', `feedbackService?typeId=${questionType}`, null, dispatch)
                    .then(res => {
                        setResultsQuestion(res.data.data);
                    }).catch((e) => {
                        if (e.code === "ERR_NETWORK") {
                            let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                            toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                            if (find) {
                                setResultsQuestion(find.results)
                            }
                        }
                        console.log(e)
                    })
            } else {
                setResultsQuestion([]);
                console.log("Ancora nessun questionario creato per questa categoria")
            }
            await APIRequest('GET', 'feedbackService/questionResponse', null, dispatch)
                .then(res => {
                    setResultsResponse(res.data.data);
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            setResultsResponse(find.results)
                        }
                    }
                    console.log(e)
                })
            /* await APIRequest('GET', 'feedbackService/question')
                .then(res => {
                    setResultsQuestion(res.data.data);
                }).catch((e) => {
                    console.log(e)
                }) */
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage setDialog={dialog1 ? "dialog1" : (dialog2 ? "dialog2" : undefined)} />
            <div className={`flex-grow transition-all duration-300`}>
                <div className='flex flex-row max-[1100px]:flex-col items-start justify-around h-full'>
                    <FeedbackFrame resultsQuestionType={resultsQuestionType} dialog1={dialog1} setDialog1={setDialog1} dialog2={dialog2} setDialog2={setDialog2} resultsQuestion={resultsQuestion} pathname={"/questionariValutazioneDelServizio"} setResultsQuestion={setResultsQuestion} resultsResponse={resultsResponse} setResultsResponse={setResultsResponse} />
                </div>
            </div>
        </div>
    );
}

export default QuestionariValutazioneDelServizio;
