export const basePath = "/"
export const lavoraConNoiPath = "/lavoraConNoi"
export const pageCandidatoPath = "/pageCandidato"
export const dashboardPath = "/dashboard"
export const operatoriPath = "/operatori"
export const pazientiPath = "/pazienti"
export const profiloPath = "/profilo"
export const HRPath = "/HR"
export const contabiliPath = "/contabili"
export const supervisorePath = "/supervisore"
export const segretariPath = "/segretari"
export const corsiPath = "/corsi"
export const creaCorsoPath = "/creaCorso"
export const employessCarePath = "/employessCare"
export const valutazioneDellaStrutturaPath = "/valutazioneDellaStruttura"
export const valutazioneDelServizioPath = "/valutazioneDelServizio"
export const questionariValutazioneDellaStrutturaPath = "/questionariValutazioneDellaStruttura"
export const questionariValutazioneDelServizioPath = "/questionariValutazioneDelServizio"
export const candidaturePath = "/candidature"
export const candidatiPath = "/candidati"
export const rendicontazionePath = "/rendicontazione"
export const prestazioniPath = "/prestazioni"
export const notifichePath = "/notifiche"
export const configuratorePath = "/configuratore"
export const importPath = "/import"
export const conversazioniPath = "/conversazioni"
export const aslPath = "/asl"
export const contrattiPath = "/contratti"
export const candidatoPath = "/candidate"
export const errorPath = "/error"