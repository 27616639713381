const initialState = {
    activeId: "",
    hidden: false
};

const menuMobileReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACTIVE_ID":
            return {
                ...state,
                activeId: action.payload.activeId
            };
        case "HIDDEN_MENU":
            return {
                ...state,
                hidden: !action.payload.hidden
            };
        default:
            return state;
    }
};

export default menuMobileReducer;
