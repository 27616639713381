import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { differenceInDays } from 'date-fns';

function CustomDataTable(props) {

    const locale = 'it-IT'
    const options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
    }

    const firstName = (rowData) => {
        return (
            <span>{rowData?.idRegistry?.firstName}</span>
        );
    }
    //FirstName e nome sono diversi è normale, vengono utilizzati in due tabelle diverse (vale lo stesso per lastName e cognome)
    const nome = (rowData) => {
        return (
            <span>{rowData?.idUser?.idRegistry?.firstName}</span>
        );
    }
    const lastName = (rowData) => {
        return (
            <span>{rowData?.idRegistry?.lastName}</span>
        );
    }
    const cognome = (rowData) => {
        return (
            <span>{rowData?.idUser?.idRegistry?.lastName}</span>
        );
    }
    const cellNumber = (rowData) => {
        return (
            <span>{rowData?.idRegistry?.tel ? rowData?.idRegistry?.tel : rowData?.idRegistry?.cell}</span>
        );
    }
    const username = (rowData) => {
        return (
            <span>{rowData?.idUser?.username}</span>
        );
    }
    const role = (rowData) => {
        return (
            <span>{rowData?.idUser?.role?.label}</span>
        );
    }
    const contestoTemplate = (rowData) => {
        if (rowData?.body.substring().length > 10) {
            return (
                <span>{rowData?.body.substring(0, 10) + "..."}</span>
            );
        } else {
            return (
                <span>{rowData?.body}</span>
            );
        }
    }
    const dataFormat = (rowData) => {
        return (
            <span>{
                new Intl.DateTimeFormat("it-IT", {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                }).format(new Date(rowData?.createAt ? rowData?.createAt : rowData?.appliedAt))
            }</span>
        );
    }
    const roleTemplateHandler = (rowData) => {
        if (rowData?.jobPosition) {
            return (
                <span>{rowData?.jobPosition?.title}</span>
            );
        } else {
            return (
                <span>{rowData?.role?.label}</span>
            );
        }
    };

    const statusTemplate = (rowData) => {
        if (rowData.status) {
            let bgColor = "";
            switch (rowData?.status?.label) {
                case "Candidato":
                    bgColor = "bg-sky-600";
                    break;
                case "Valutazione":
                    bgColor = "bg-yellow-400";
                    break;
                case "Approvato":
                    bgColor = "bg-green-600";
                    break;
                case "Firmato":
                    bgColor = "bg-green-600";
                    break;
                case "Controfirmato":
                    bgColor = "bg-green-600";
                    break;
                case "Avallato":
                    bgColor = "bg-green-600";
                    break;
                case "Rifiutato":
                    bgColor = "bg-red-600";
                    break;
                case "Errore":
                    bgColor = "bg-red-600";
                    break;
                case "Ritirata":
                    bgColor = "bg-red-600";
                    break;
                default:
                    bgColor = "bg-gray-600";
                    break;
            }
            return (
                <span className={`rounded p-2 text-white font-semibold uppercase ${bgColor}`}>{rowData?.status?.label}</span>
            )
        }
    }

    const progressTemplate = (rowData) => {
        if (rowData.status.label !== "Approvato") {
            return (
                <span className='rounded p-2 text-gray-400 font-semibold uppercase border-2 border-gray-400'>N/A</span>
            );
        }

        const today = new Date();
        const dateDifference = rowData.date ? differenceInDays(today, new Date(rowData.date)) : null;

        let color;
        let label;

        switch (true) {
            case dateDifference === null:
                color = "bg-gray-400";
                label = "Non calcolabile";
                break;
            case dateDifference <= 30:
                color = "bg-green-500";
                label = "Completato";
                break;
            case dateDifference > 30 && dateDifference <= 60:
                color = "bg-yellow-500";
                label = "Incompleto";
                break;
            case dateDifference > 60:
                color = "bg-red-500";
                label = "In ritardo";
                break;
            default:
                color = "bg-gray-400";
                label = "Non calcolabile";
                break;
        }

        return (
            <span className={`rounded p-2 text-white font-semibold uppercase ${color}`}>{label}</span>
        );
    };

    const userId = (rowData) => {
        console.log(rowData)
        return (rowData.idUser)
    }

    const percentuale = (rowData) => {
        let x = 0
        rowData.questionValue.forEach(element => {
            x = x + element.value
        });
        let percent = (x / (rowData.questionValue.length * 5)) * 100
        return (percent.toFixed(2) + "%")
    }
    const startTemplate = (rowData) => {
        return (
            <span>{new Date(rowData?.start).toLocaleDateString(locale, options)}</span>
        );
    };
    const endTemplate = (rowData) => {
        return (
            <span>{new Date(rowData?.end).toLocaleDateString(locale, options)}</span>
        );
    };
    const dataResponse = (rowData) => {
        return (
            <span>{new Date(rowData?.createAt).toLocaleDateString(locale, options)}</span>
        );
    };

    const statusCandidaturaTemplate = (rowData) => {
        return (
            <span className='bg-red-600 rounded-full flex flex-row size-5'></span>
        )
    };

    const jobPosition = (rowData) => {
        return (
            <span>{rowData?.jobPosition?.title}</span>
        )
    }

    const ASLPreference = (rowData) => {
        return (
            <span>{rowData?.ASLPreference}</span>
        )
    }

    const tipestampTemplate = (rowData) => {
        return (
            <span>{new Date(rowData?.timestamp).toLocaleTimeString(locale, options)}</span>
        );
    };

    const levelTemplate = (rowData) => {
        return (
            <span className='capitalize font-bold'>{rowData?.level === "download" ? <i className='pi pi-download'></i> : ""}{` ${rowData?.level}`}</span>
        );
    };

    const handlers = {
        roleTemplate: roleTemplateHandler,
        firstName: firstName,
        nome: nome,
        lastName: lastName,
        cognome: cognome,
        cellNumber: cellNumber,
        startTemplate: startTemplate,
        endTemplate: endTemplate,
        statusTemplate: statusTemplate,
        progressTemplate: progressTemplate,
        dataFormat: dataFormat,
        username: username,
        role: role,
        contestoTemplate: contestoTemplate,
        userId: userId,
        percentuale: percentuale,
        dataResponse: dataResponse,
        statusCandidaturaTemplate: statusCandidaturaTemplate,
        jobPosition: jobPosition,
        ASLPreference: ASLPreference,
        tipestampTemplate: tipestampTemplate,
        levelTemplate: levelTemplate,
    };

    const actionBodyTemplate = (rowData) => {
        const actions = Array.isArray(props.actionFields) ? props.actionFields : props.actionFields(rowData) || []; // Assicura che sia un array
        return (
            <div className='flex gap-1'>
                {actions.map((el, key) => (
                    <React.Fragment key={key}>
                        <Button
                            className='rounded-full p-2 mr-1 w-auto bg-[#00b1b1] text-white'
                            tooltip={el.name(rowData)}
                            tooltipOptions={{ position: "top" }}
                            onClick={() => el.handler(rowData)}
                        >
                            {el.icon(rowData)}
                        </Button>
                    </React.Fragment>
                ))}
            </div>
        );
    };

    return (
        <div className='card w-full relative z-[0]'>
            <DataTable
                className=""
                responsiveLayout='stack'
                ref={props.refer}
                value={props.value}
                loading={props.loading}
                dataKey={props.dataKey}
                header={props.showHeader ? props.header : null}
                paginator
                rows={props.rows ? props.rows : 5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                emptyMessage='Non ci sono elementi disponibili al momento'
                rowGroupMode={props.rowGroupMode}
                groupRowsBy={props.groupRowsBy}
                sortMode={props.sortMode}
                sortField={props.sortField}
                sortOrder={props.sortOrder}
                expandableRowGroups={props.expandableRowGroups}
                expandedRows={props.expandedRows}
                onRowToggle={props.onRowToggle}
                rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
                rowGroupFooterTemplate={props.rowGroupFooterTemplate}
            >
                {props.fields?.map((el, index) => (
                    <Column
                        key={el.field}
                        className={`column-${index} ${el.className}`}
                        field={el.field}
                        header={el.showHeader ? el.header : null}
                        sortable={el.sortable}
                        body={el.body !== undefined ? (rowData) => handlers[el.body](rowData) : null}
                        headerStyle={el.headerStyle}
                    />
                ))}
                {props.actionFields && (
                    <Column
                        field='action'
                        key="action-column"
                        className="fixed-action-column"
                        body={(rowData) => actionBodyTemplate(rowData)}
                        exportable={false}
                    ></Column>
                )}
            </DataTable>
        </div>
    );
}

export default CustomDataTable;
