import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWindowSize } from "../../utils/utils";
import { resetPathCrumb, setActiveId } from "../../store/actions/actions";
import { BsClipboardPulse } from "react-icons/bs";
import { LiaUserNurseSolid } from "react-icons/lia";
import { GrMoney } from "react-icons/gr";
import { format, subDays, subMonths } from 'date-fns';
import CustomDataChart from "../../components/customDataChart";
import CustomDataTable from "../../components/customDataTable";
import { APIRequest } from "../../services/axios";
import { Dropdown } from "primereact/dropdown";
function Home(props) {
    const [resultsPazienti, setResultsPazienti] = useState([]);
    const [resultsPrestazioni, setResultsPrestazioni] = useState([]);
    const [selectedTimeChart, setSelectedTimeChart] = useState(2);
    const [timeRangeChart, setTimeRangeChart] = useState(null);
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const dt = useRef(null);
    const [width] = useWindowSize();
    const allResults = useSelector(state => state.allResults.results)
    const timeRange = [
        {
            "name": "--",
            "value": 0
        },
        {
            "name": "Oggi",
            "value": 1
        },
        {
            "name": "1 Settimana",
            "value": 2
        },
        {
            "name": "1 Mese",
            "value": 3
        },
        {
            "name": "6 Mesi",
            "value": 4
        },
        {
            "name": "1 Anno",
            "value": 5
        },
    ]
    const user = JSON.parse(window.localStorage.getItem("user"))

    useEffect(() => {
        async function trovaRisultato() {
            setTimeRangeChart(generateChartLabels(2));
            await APIRequest('GET', 'calendarService', null, dispatch)
                .then(res => {
                    setResultsPrestazioni(res.data.data);
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        if (find) {
                            setResultsPrestazioni(find.results)
                        }
                    }
                    console.log(e)
                })
                await APIRequest('GET', 'authService/user-registry?role=PATIENT', null, dispatch)
                .then(res => {
                    setResultsPazienti(res.data.data);
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        if (find) {
                            setResultsPazienti(find.results)
                        }
                    }
                    console.log(e)
                })
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    useEffect(() => {
        dispatch(resetPathCrumb())
    }, [dispatch])

    const fieldsPrestazioni = [
        {
            field: "idUser.username",
            header: "Nome utente",
            sortable: true,
            showHeader: true,
        },
        {
            field: "title",
            header: "Titolo",
            sortable: true,
            showHeader: true,
        },
        {
            field: "start",
            header: "Inizio",
            body: "startTemplate",
            sortable: true,
            showHeader: true,
        },
        {
            field: "end",
            header: "Fine",
            body: "endTemplate",
            sortable: true,
            showHeader: true,
        },
    ];

    const fieldsPazienti = [
        {
            field: "firstName",
            header: "Nome",
            body: "firstName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "lastName",
            header: "Cognome",
            body: "lastName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "username",
            header: "Email",
            sortable: true,
            showHeader: true,
        },
        {
            field: "role",
            header: "Role",
            body: "roleTemplate",
            sortable: true,
            showHeader: true,
        },
    ];

    const generateChartLabels = (value) => {
        const labels = [];
        const today = new Date();
        let interval;

        switch (value) {
            case 1: // Oggi
                const startOfDay = new Date(today);
                startOfDay.setHours(0, 0, 0, 0); // Imposta l'ora a mezzanotte
                const currentHour = today.getHours(); // Ora corrente
                interval = currentHour / 6; // Suddividi l'intervallo tra mezzanotte e l'ora corrente in 6 label
                for (let i = 0; i <= 6; i++) {
                    const labelTime = new Date(startOfDay);
                    labelTime.setHours(labelTime.getHours() + i * interval);
                    labels.push(format(labelTime, 'HH:mm')); // Aggiungi la label formattata
                }
                break;
            case 2: // 1 Settimana
                for (let i = 6; i >= 0; i--) {
                    labels.push(format(subDays(today, i * 1), 'dd/MM'));
                }
                break;
            case 3: // 1 Mese
                interval = 30 / 6;
                for (let i = 6; i >= 0; i--) {
                    labels.push(format(subDays(today, i * interval), 'dd/MM'));
                }
                break;
            case 4: // 6 Mesi
                for (let i = 5; i >= 0; i--) {
                    labels.push(format(subMonths(today, i), 'MM/yyyy'));
                }
                break;
            case 5: // 1 Anno
                for (let i = 6; i >= 0; i--) {
                    labels.push(format(subMonths(today, i * 2), 'MM/yyyy'));
                }
                break;
            default: // Default, nessuna selezione
                labels.push('--');
                break;
        }
        return labels;
    }

    const changeTimeRange = (value) => {
        setSelectedTimeChart(value);
        const labels = generateChartLabels(value);
        setTimeRangeChart(labels);
    }

    return (
        <div className="flex flex-col h-full">
            <div className="flex flex-col gap-2 p-5 pb-10 sm:pb-5">
                <div className="flex flex-row lg:gap-10 gap-2 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4">
                    <div className="flex flex-row items-center justify-between border border-gray-200 rounded-lg text-start p-4" onClick={() => { /* navigate("/pazienti"); */ dispatch(setActiveId("")) }}>
                        <div className="flex flex-col xl:p-4">
                            <p className="text-fluid1 font-semibold">---</p>
                            <p className="text-lg text-gray-600">---</p>
                        </div>
                        <div className="flex flex-row items-center justify-center bg-[#00b1b1] text-white p-5 rounded-md">
                            <BsClipboardPulse className="text-fluid1" />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between border border-gray-200 rounded-lg text-start p-4" onClick={() => { /* navigate("/pazienti"); */ dispatch(setActiveId("")) }}>
                        <div className="flex flex-col xl:p-4">
                            <p className="text-fluid1 font-semibold">---</p>
                            <p className="text-lg text-gray-600">---</p>
                        </div>
                        <div className="flex flex-row items-center justify-center bg-[#00b1b1] text-white p-5 rounded-md">
                            <BsClipboardPulse className="text-fluid1" />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between border border-gray-200 rounded-lg text-start p-4" onClick={() => { /* navigate("/operatori"); */ dispatch(setActiveId("")) }}>
                        <div className="flex flex-col xl:p-4">
                            <p className="text-fluid1 font-semibold">---</p>
                            <p className="text-lg text-gray-600">---</p>
                        </div>
                        <div className="flex flex-row items-center justify-center bg-[#00b1b1] text-white p-5 rounded-md">
                            <LiaUserNurseSolid className="text-fluid1" />
                        </div>
                    </div>
                    <div className="flex flex-row items-center justify-between border border-gray-200 rounded-lg text-start p-4" onClick={() => { /* navigate("/rendicontazione"); */ dispatch(setActiveId("")) }}>
                        <div className="flex flex-col xl:p-4">
                            <p className="text-fluid1 font-semibold">---</p>
                            <p className="text-lg text-gray-600">---</p>
                        </div>
                        <div className="flex flex-row items-center justify-center bg-[#00b1b1] text-white p-5 rounded-md">
                            <GrMoney className="text-fluid1" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row grid xl:grid-cols-3 grid-cols-1 gap-3">
                    <div className="border border-gray-200 rounded-lg xl:col-span-2">
                        <div className="w-full flex flex-row justify-between">
                            <p className="font-bold text-fluid0 text-start pl-5 py-2">Stato pazienti</p>
                            <Dropdown className="flex flex-row items-center" value={selectedTimeChart} onChange={(e) => changeTimeRange(e.value)} options={timeRange} optionLabel="name" placeholder="" checkmark={true} highlightOnSelect={false} />
                        </div>
                        <CustomDataChart
                            theme={props.theme}
                            labels={timeRangeChart}
                            type={"line"}
                            label={"Ricoverati"}
                            data={[65, 59, 80, 81, 56, 65, 70]}
                            backgroundColor={['#00b1b166']}
                            borderColor={['#00b1b1']}
                            type2={"line"}
                            label2={"Morti"}
                            data2={[7, 10, 4, 6, 1, 0, 2]}
                            backgroundColor2={['#32323266']}
                            borderColor2={['#323232']}
                            aspectRatio={width < 650 ? 1 : 2} />
                    </div>
                    <div className="border border-gray-200 bg-[#00b1b1] rounded-lg">
                        <div className="w-full h-full flex flex-col justify-between text-white p-3 sm:p-6 pt-0 ">
                            <div className="flex flex-row justify-between items-center">
                                <p className="font-bold text-fluid0 text-start py-2">Miglior operatore</p>
                                <p className="font-bold text-end py-2">Quest'anno</p>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <img src="https://static-00.iconduck.com/assets.00/profile-circle-icon-2048x2048-cqe5466q.png" className="min-[1201px]:w-2/3 max-[1200px]:w-1/4" alt='Foto profilo'></img>
                                <p className="font-bold text-fluid0 text-center pt-2">Alessandro Avallone</p>
                                <p className="text-lg text-center">Fisioterapista</p>
                            </div>
                            <div className="flex flex-row gap-2 justify-between grid grid-cols-3">
                                <div className="flex flex-col items-center justify-center">
                                    <p className="font-semibold pb-1">Esperienza</p>
                                    <div className="flex flex-row justify-center items-center bg-white w-full rounded-lg">
                                        <p className="text-[#00b1b1] font-bold sm:text-sm md:text-md lg:text-lg p-4">8 Anni</p>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                    <p className="font-semibold pb-1">Pazienti</p>
                                    <div className="flex flex-row justify-center items-center bg-white w-full rounded-lg">
                                        <p className="text-[#00b1b1] font-bold sm:text-sm md:text-md lg:text-lg p-4">2598</p>
                                    </div>
                                </div>
                                <div className="flex flex-col items-center justify-center">
                                    <p className="font-semibold pb-1">Recensioni</p>
                                    <div className="flex flex-row justify-center items-center bg-white w-full rounded-lg">
                                        <p className="text-[#00b1b1] font-bold sm:text-sm md:text-md lg:text-lg p-4">1537</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row grid lg:grid-cols-2 grid-cols-1 gap-3">
                    <div className="border border-gray-200 rounded-lg px-5">
                        <p className="font-bold text-fluid0 text-start py-2">Appuntamenti richiesti</p>
                        <CustomDataTable
                            dataKey="id"
                            loading={!resultsPrestazioni}
                            refer={dt}
                            value={resultsPrestazioni}
                            fields={fieldsPrestazioni}
                        />
                    </div>
                    <div className="border border-gray-200 rounded-lg px-5">
                        <p className="font-bold text-fluid0 text-start py-2">Pazienti recenti</p>
                        <CustomDataTable
                            dataKey="id"
                            loading={!resultsPazienti}
                            refer={dt}
                            value={resultsPazienti}
                            fields={fieldsPazienti}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home;
