import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import { resetPathCrumb } from '../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { APIRequest } from '../../services/axios';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { useWindowSize } from '../../utils/utils';
import { Steps } from 'primereact/steps';
import { MdDragHandle, MdOndemandVideo, MdTextFields } from 'react-icons/md';
import { GoRepoPush } from 'react-icons/go';
import { InputTextarea } from 'primereact/inputtextarea';
import { Dialog } from 'primereact/dialog';

function CreaCorso() {
    const [title, setTitle] = useState("")
    const [description, setDescription] = useState("")
    const [subTitle, setSubTitle] = useState("")
    const [subDescription, setSubDescription] = useState("")
    const [totalSize, setTotalSize] = useState(0);
    const [file, setFile] = useState(null)
    const [activeIndex, setActiveIndex] = useState(0);
    const [contentLesson, setContentLesson] = useState([])
    const [contentCopy, setContentCopy] = useState(null)
    const [draggedItemIndex, setDraggedItemIndex] = useState(null);
    const [editingContent, setEditingContent] = useState(null);
    const [dragOverIndex, setDragOverIndex] = useState(null);
    const [isDragging, setIsDragging] = useState(false);
    const [dragIndicatorStyle, setDragIndicatorStyle] = useState({ display: 'none' }); // Indicatore per il drag su mobile
    const [fileUrl, setFileUrl] = useState(null);
    const [alertDialog, setAlertDialog] = useState(false);
    const [confirmNext, setConfirmNext] = useState(false)
    const toast = useRef(null);
    const fileRef = useRef(null);
    const [width] = useWindowSize();
    const dispatch = useDispatch();

    const itemRenderer = (item, itemIndex) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? '#00b1b1' : 'var(--surface-b)';
        const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';
        const borderColor = isActiveItem ? 'solid 0.3px #00b1b1' : 'solid 0.3px var(--text-color-secondary)';

        return (
            <span
                className="flex flex-row gap-2 items-center justify-center items-center rounded-full p-3 z-999"
                style={{ backgroundColor: backgroundColor, color: textColor, border: borderColor, marginTop: '-25px' }}
            >
                {itemIndex === 0 ?
                    <MdTextFields size={28} className={`w-8`} />
                    :
                    itemIndex === 1 ?
                        <MdOndemandVideo size={28} className={`w-8`} />
                        :
                        itemIndex === 2 ?
                            <GoRepoPush size={28} className={`w-8`} />
                            :
                            <></>
                }
                {width > 900 &&
                    item.label
                }
            </span>
        );
    };

    const items = [
        {
            label: 'Intestazione',
            template: (item) => itemRenderer(item, 0)
        },
        {
            label: 'Lezioni',
            template: (item) => itemRenderer(item, 1)
        },
        {
            label: 'Pubblicazione',
            template: (item) => itemRenderer(item, 2)
        },
    ];

    useEffect(() => {
        /* dispatch(chiudimenu()); */
        dispatch(resetPathCrumb());
    }, [dispatch]);

    const goNext = () => {
        if (activeIndex === 0) {
            if (!title || !description) {
                toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: 'Prima di proseguire riempire prima tutti i campi *' });
            } else {
                let _activeIndex = activeIndex + 1
                setActiveIndex(_activeIndex)
            }
        } else if (activeIndex === 1) {
            if (contentLesson.length === 0) {
                toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: 'Aggiungi prima una lezione al corso "+"' });
            } else if (!confirmNext && (subTitle !== "" || subDescription !== "" || file !== null)) {
                setAlertDialog(true)
                setConfirmNext(true)
            } else {
                setAlertDialog(false)
                setConfirmNext(false)
                setSubTitle("")
                setSubDescription("")
                setFile(null)
                setTotalSize(0)
                let _activeIndex = activeIndex + 1
                setActiveIndex(_activeIndex)
            }
        } else {
            setConfirmNext(false)
            setSubTitle("")
            setSubDescription("")
            setFile(null)
            setTotalSize(0)
            let _activeIndex = activeIndex + 1
            setActiveIndex(_activeIndex)
        }
    }
    const goBack = () => {
        let _activeIndex = activeIndex - 1
        setActiveIndex(_activeIndex)
    }

    const onTemplateSelect = (e) => {
        let _totalSize = totalSize;
        let files = e.files;
        setFile(files[0])
        Object.keys(files).forEach((key) => {
            _totalSize += files[key].size || 0;
        });

        setTotalSize(_totalSize);
    };

    const onTemplateUpload = (e) => {
        let _totalSize = 0;

        e.files.forEach((file) => {
            _totalSize += file.size || 0;
        });

        setTotalSize(_totalSize);
        toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Caricato' });
    };

    const onTemplateRemove = (file, callback) => {
        setTotalSize(totalSize - file.size);
        setFile(null)
        callback();
    };

    const onTemplateClear = () => {
        setTotalSize(0);
        setFile(null)
    };

    const headerTemplate = (options) => {
        const { className, chooseButton, cancelButton } = options;
        const value = totalSize / 10000;
        const formatedValue = fileRef && fileRef.current ? fileRef.current.formatSize(totalSize) : '0 B';

        return (
            <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', justifyContent: "space-between", alignItems: "center" }}>
                {chooseButton}
                {cancelButton}
                <div className="flex max-[380px]:flex-col items-center min-[380px]:gap-3 max-[400px]:w-9/12">
                    <span>{formatedValue}</span>
                    <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
                </div>
            </div>
        );
    };

    const itemTemplate = (file, props) => {
        return (
            <div className="flex items-center flex-wrap">
                <div className="flex items-center" style={{ width: '40%' }}>
                    <span className="flex flex-col text-left ml-3">
                        {file.name}
                        <small className='text-gray-400'>{props.formatSize}</small>
                    </span>
                </div>
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} />
            </div>
        );
    };
    const emptyTemplate = () => {
        return (
            <div className="flex items-center flex-col">
                <i className="pi pi-image mt-3 p-5"
                    style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}
                    onClick={() => {
                        const input = fileRef.current && fileRef.current.getInput();
                        if (input) input.click();
                    }}
                ></i>
                <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
                    Carica file
                </span>
            </div>
        );
    };

    const addContent = () => {
        if (!subTitle || !subDescription || !file) {
            toast.current.show({ severity: 'warn', summary: 'Attenzione!', detail: 'Prima di aggiungere la lezione al corso riempire tutti i campi *' });
        } else {
            let x = [...contentLesson]
            x.push({ "id": Math.random(), "subTitle": subTitle, "subDescription": subDescription, "file": file })
            setContentLesson(x)
            setSubTitle("")
            setSubDescription("")
            setFile(null)
            fileRef.current.clear();
            setTotalSize(0)
        }
    }

    const handleConfirmUpload = async () => {
        let _resultCourse;
        let lezioni = []
        for (let i = 0; i < contentLesson.length; i++) {
            let el = contentLesson[i];
            lezioni.push({
                "title": el?.subTitle,
                "description": el?.subDescription,
                "url": "/",
                "type": el?.file?.type
            })
        }
        let _corso = {
            "title": title,
            "description": description,
            "contents": lezioni
        };

        await APIRequest('POST', 'coursesService', _corso)
            .then(async res => {
                if (res.data.success) {
                    _resultCourse = res.data.data
                } else {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Corso non creato`, life: 3000 });
                }
            })
            .catch(e => {
                console.log(e);
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `Corso non creato`, life: 3000 });
            });

        for (let j = 0; j < _resultCourse.contents.length; j++) {
            const formData = new FormData();
            formData.append('file', new Blob([contentLesson[j]?.file], { type: contentLesson[j]?.file?.type }), contentLesson[j]?.file?.name);
            formData.append('title', contentLesson[j]?.file?.name);
            formData.append('url', "/");
            formData.append('path', "/attachments");
            await APIRequest('POST', 'coursesService/content/upload?contentId=' + _resultCourse.contents[j].id, formData)
                .then(res => {
                    if (res.data.success) {
                        console.log(res);
                    } else {
                        console.log(res.data.message);
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File della lezione ${_resultCourse.contents[j].title} non caricato`, life: 3000 });
                    }
                })
                .catch(e => {
                    console.log(e);
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti', detail: `File della lezione ${_resultCourse.contents[j].title} non caricato`, life: 3000 });
                });
        }


        /* setTitle("")
        setDescription("")
        setContentLesson([])
        setEditingContent(null)
        setActiveIndex(0) */
    };

    const removeContentElement = (el) => {
        const x = [...contentLesson]
        x.splice(x.indexOf(el), 1)
        setContentLesson(x)
    }

    // Inizio del trascinamento
    const handleDragStart = (index) => {
        setDraggedItemIndex(index);
    };

    // Permetti il trascinamento sopra altri elementi
    const handleDragOver = (e) => {
        e.preventDefault(); // Necessario per consentire il drop
    };

    // Quando un elemento viene rilasciato
    const handleDrop = (index) => {
        const items = [...contentLesson];
        const [draggedItem] = items.splice(draggedItemIndex, 1);
        items.splice(index, 0, draggedItem);

        setContentLesson(items);
        setDraggedItemIndex(null);
    };

    // Funzione per gestire la modifica
    const handleEditClick = (content) => {
        setEditingContent(content);
        setContentCopy(content)
        /* if (content.file && content.file.data) {
            const byteCharacters = atob(content.file.data);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            const blob = new Blob([byteArray], { type: 'application/pdf' });

            const fileUrl = URL.createObjectURL(blob);
            setFileUrl(fileUrl);  // Aggiorna lo stato per impostare l'URL nell'iFrame
        } */
    };

    // Funzione per gestire il salvataggio delle modifiche
    const handleSaveEdit = () => {
        let _contentLesson = [...contentLesson]
        for (let i = 0; i < _contentLesson.length; i++) {
            if (_contentLesson[i].id === editingContent.id) {
                _contentLesson[i].subTitle = editingContent.subTitle;
                _contentLesson[i].subDescription = editingContent.subDescription;
                _contentLesson[i].file = file ? file : _contentLesson[i].file
                break;
            }
        }
        setContentLesson(_contentLesson);
        setFile(null)
        fileRef.current.clear();
        setTotalSize(0)
        setEditingContent(null); // Resetta il form di modifica
    };

    const handleTouchStart = (index) => {
        setDraggedItemIndex(index);
        setIsDragging(true);
        document.body.style.overflow = 'hidden';

        setDragIndicatorStyle({
            display: 'block',
            position: 'fixed',
            left: '50%',
            top: '50%',
            width: '20px',
            height: '20px',
            backgroundColor: 'rgba(0, 123, 255, 0.5)',
            borderRadius: '50%',
            pointerEvents: 'none'
        });
    };

    const handleTouchMove = (event) => {
        if (!isDragging) return;

        event.preventDefault();
        const touchLocation = event.targetTouches[0];

        setDragIndicatorStyle((prev) => ({
            ...prev,
            left: `${touchLocation.pageX - 10}px`,
            top: `${touchLocation.pageY - 30}px`
        }));

        const touchedElement = document.elementFromPoint(touchLocation.pageX, touchLocation.pageY);
        if (touchedElement && touchedElement.getAttribute('data-index')) {
            const overIndex = parseInt(touchedElement.getAttribute('data-index'), 10);
            setDragOverIndex(overIndex);
        }
    };

    const handleTouchEnd = () => {
        if (dragOverIndex !== null && draggedItemIndex !== null && dragOverIndex !== draggedItemIndex) {
            const items = [...contentLesson];
            const [draggedItem] = items.splice(draggedItemIndex, 1);
            items.splice(dragOverIndex, 0, draggedItem);

            setContentLesson(items);
        }

        setDraggedItemIndex(null);
        setDragOverIndex(null);
        setIsDragging(false);
        document.body.style.overflow = 'auto';
        setDragIndicatorStyle({ display: 'none' });
    };

    const handleAlertDialogFooter = () => {
        return (
            <React.Fragment>
                <div className='flex flex-row justify-between items-center'>
                    <Button label="Annulla" onClick={() => { setAlertDialog(false); setConfirmNext(false) }} className="buttonCustom p-button-secondary" />
                    <Button label="Avanti" onClick={() => { goNext(); setConfirmNext(true) }} className="buttonCustom p-button-success mr-2" />
                </div>
            </React.Fragment>
        )
    }

    const chooseOptions = { icon: 'pi pi-fw pi-plus', iconOnly: true, className: 'custom-choose-btn rounded-full border border-blue-500 p-button-outlined h-7 w-7 hidden' };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger rounded-full border border-red-700 p-button-outlined h-7 w-7' };

    const steps = () => {
        switch (activeIndex) {
            case 0:
                return (
                    <>
                        <div className={`col-span-3 max-[1200px]:col-span-7`}>
                            <h2 className="text-2xl font-bold uppercase mb-2">Dettagli del Corso</h2>
                            {/* Nome dello Step */}
                            <div className="text-left">
                                <p className="text-base text-gray-600">
                                    Nel primo step, devi fornire le informazioni principali sul corso di formazione. Completa i seguenti campi per definire il titolo e la descrizione del corso:
                                </p>
                                <ul className="list-disc pl-5 mt-2">
                                    <li><strong>Titolo del Corso:</strong> Inserisci un titolo chiaro e sintetico che descriva il contenuto del corso. Questo titolo sarà visibile agli operatori della piattaforma e li aiuterà a identificare facilmente il corso.</li>
                                    <li><strong>Descrizione del Corso:</strong> Scrivi una descrizione dettagliata che spieghi gli obiettivi del corso, il pubblico a cui è rivolto e gli argomenti principali che verranno trattati. Assicurati che sia completa e comprensibile.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-span-1 max-[1200px]:hidden flex flex-col gap-2 items-center justify-center w-full'>
                            <i className='pi pi-chevron-right text-fluid1'></i>
                        </div>
                        <div className={`col-span-3 max-[1200px]:col-span-7 flex flex-col gap-5`}>
                            {/* Campo Titolo Corso */}
                            <div className='flex flex-col items-start gap-2'>
                                <div className='font-semibold uppercase'>Titolo corso *</div>
                                <InputText
                                    className="w-full select-none p-2 border focus:shadow-none"
                                    id='title'
                                    placeholder="Titolo lezione"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                />
                            </div>
                            {/* Campo Descrizione Corso */}
                            <div className='flex flex-col items-start gap-2'>
                                <div className='font-semibold uppercase'>Descrizione corso *</div>
                                <InputTextarea
                                    className="w-full select-none p-2 border focus:shadow-none"
                                    id='description'
                                    rows={10}
                                    placeholder="Descrizione lezione"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                />
                            </div>
                        </div>
                    </>
                );
            case 1:
                return (
                    <>
                        <div className='col-span-3 max-[1200px]:col-span-7'>
                            <h2 className='text-2xl font-bold uppercase'>Creazione delle Lezioni</h2>
                            <p className="text-base text-start text-gray-600 mt-2">
                                In questo step, devi creare le lezioni che comporranno il corso di formazione. Per ciascuna lezione, è necessario:
                            </p>
                            <ul className="text-base text-start text-gray-600 list-disc pl-5 mt-2">
                                <li><strong>Inserire un titolo:</strong> Dai un nome chiaro e descrittivo alla lezione, che permetta agli operatori di identificare facilmente l'argomento trattato.</li>
                                <li><strong>Scrivere una descrizione:</strong> Fornisci un'anteprima dettagliata del contenuto della lezione, specificando i punti chiave e gli obiettivi.</li>
                                <li><strong>Allegare un file:</strong> Carica un video o un documento che rappresenta il contenuto della lezione.</li>
                            </ul>
                            <p className="text-base text-start text-gray-600 mt-2">
                                Dopo aver completato tutti i campi, premi il pulsante <strong>"Aggiungi Lezione"</strong> per salvare la lezione nel corso. <br /><span className="font-semibold">Nota:</span> Se non clicchi su "Aggiungi Lezione", i dati inseriti non verranno salvati.
                            </p>
                        </div>
                        <div className='col-span-1 max-[1200px]:hidden flex flex-col gap-2 items-center justify-center w-full'>
                            <i className='pi pi-chevron-right text-fluid1'></i>
                        </div>
                        {/* Campi per la nuova lezione */}
                        <div className='col-span-3 max-[1200px]:col-span-7 flex flex-col gap-5'>
                            {/* Campi per titolo e descrizione */}
                            <div className={`flex gap-4 flex-col`}>
                                <div className='flex flex-col items-start gap-2 w-full px-4'>
                                    <div className='font-semibold uppercase text-start'>Titolo lezione *</div>
                                    <InputText
                                        className="w-full select-none p-2 border focus:shadow-none"
                                        id='subTitle'
                                        placeholder="Inserisci il titolo della lezione"
                                        value={subTitle}
                                        onChange={(e) => setSubTitle(e.target.value)}
                                    />
                                    <p className="text-sm text-gray-500 mt-1">
                                        Inserisci un titolo chiaro e descrittivo per la lezione.
                                    </p>
                                </div>
                                <div className='flex flex-col items-start gap-2 w-full px-4'>
                                    <div className='font-semibold uppercase text-start'>Descrizione lezione *</div>
                                    <InputTextarea
                                        className="w-full select-none p-2 border focus:shadow-none"
                                        id='subDescription'
                                        rows={10}
                                        placeholder="Inserisci una descrizione dettagliata della lezione"
                                        value={subDescription}
                                        onChange={(e) => setSubDescription(e.target.value)}
                                    />
                                    <p className="text-sm text-gray-500 mt-1">
                                        Fornisci una descrizione che spieghi gli obiettivi e i contenuti principali della lezione.
                                    </p>
                                </div>
                            </div>

                            {/* Sezione per allegare file */}
                            <div className='flex flex-row justify-center w-full'>
                                <div className='flex flex-col px-10 mb-20'>
                                    <div className='font-semibold uppercase text-start mb-2'>Allega file *</div>
                                    <FileUpload
                                        ref={fileRef}
                                        name="demo[]"
                                        url="/api/upload"
                                        accept="*"
                                        onUpload={onTemplateUpload}
                                        onSelect={onTemplateSelect}
                                        onError={onTemplateClear}
                                        onClear={onTemplateClear}
                                        headerTemplate={headerTemplate}
                                        itemTemplate={itemTemplate}
                                        emptyTemplate={emptyTemplate}
                                        chooseOptions={chooseOptions}
                                        cancelOptions={cancelOptions}
                                    />
                                    <p className="text-sm text-gray-500 mt-2">
                                        Carica un file che rappresenti il contenuto della lezione. Può essere un video o un documento.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case 2:
                return (
                    <>
                        <div className="col-span-3 max-[1200px]:col-span-7">
                            <h2 className="text-2xl font-bold uppercase">{editingContent ? "Modifica Lezione" : "Resoconto del Corso"}</h2>
                            <p className="text-base text-start text-gray-600 mt-2">
                                In questo step, puoi visualizzare il resoconto del corso e delle lezioni che hai creato.
                                Puoi modificare l'ordine delle lezioni, modificarne il contenuto o eliminarle. Utilizza le seguenti icone per interagire con le lezioni:
                            </p>
                            <ul className="text-base text-start text-gray-600 list-disc pl-5 mt-2">
                                <li>
                                    <i className="pi pi-equals text-gray-700 mx-2"></i> <strong>Modifica ordine:</strong> Trascina l'icona per cambiare l'ordine delle lezioni.
                                </li>
                                <li>
                                    <i className="pi pi-pencil text-gray-700 mx-2"></i> <strong>Modifica lezione:</strong> Clicca per modificare il titolo, la descrizione o il file allegato di una lezione.
                                </li>
                                <li>
                                    <i className="pi pi-trash text-gray-700 mx-2"></i> <strong>Elimina lezione:</strong> Clicca per rimuovere una lezione dal corso.
                                </li>
                            </ul>
                        </div>
                        <div className='col-span-1 max-[1200px]:hidden flex flex-col gap-2 items-center justify-center w-full'>
                            <i className='pi pi-chevron-right text-fluid1'></i>
                        </div>
                        {/* Resoconto delle lezioni */}
                        {!editingContent ? (
                            <div className="col-span-3 max-[1200px]:col-span-7">
                                <div className={`flex flex-row flex-wrap gap-3 w-full`}>
                                    {contentLesson.map((el, key) => {
                                        return (
                                            <React.Fragment key={key}>
                                                <div
                                                    className="w-full text-xl font-semibold max-[550px]:col-span-2 grid grid-cols-2 gap-2 justify-between max-[1200px]:justify-center max-[1400px]:justify-center items-center rounded-lg border shadow-md"
                                                    draggable
                                                    onDragStart={() => handleDragStart(key)}
                                                    onDragOver={handleDragOver}
                                                    onDrop={() => handleDrop(key)}
                                                    onTouchMove={handleTouchMove}
                                                    onTouchEnd={handleTouchEnd}
                                                    data-index={key}
                                                >
                                                    <div className='col-span-1 grid grid-cols-5 max-[1200px]:col-span-1 max-[600px]:col-span-2 max-[1400px]:col-span-2 flex flex-row items-center gap-1'>
                                                        <div className='col-span-1 flex flex-row justify-center'>
                                                            <MdDragHandle
                                                                className='mx-3 hover:cursor-pointer'
                                                                onTouchStart={() => handleTouchStart(key)}
                                                            />
                                                        </div>
                                                        <div className='col-span-3 flex flex-row justify-start items-center gap-2'>
                                                            <p className='text-start py-3'>{`${key + 1}) `}</p>
                                                            <p className='text-start py-3'>{` ${el.subTitle}`}</p>
                                                        </div>
                                                    </div>
                                                    <div className="grid grid-cols-2 col-span-1 max-[1200px]:col-span-1 max-[600px]:col-span-2 max-[1400px]:col-span-2 gap-2 bg-[#E2FFFE] h-full max-[600px]:rounded-none max-[1200px]:rounded-l-full min-[1399px]:rounded-l-full py-4 px-2">
                                                        <div className="col-span-1 flex flex-row gap-2 justify-center max-[550px]:gap-2 items-center">
                                                            <Button className='!font-normal' label='Modifica' icon="pi pi-pencil" onClick={() => handleEditClick(el)} />
                                                        </div>
                                                        <div className="col-span-1 flex flex-col items-center justify-center">
                                                            <Button className='!font-normal' label='Elimina' icon="pi pi-trash" onClick={() => removeContentElement(el)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        );
                                    })}

                                    {/* Indicatore di drag */}
                                    <div style={dragIndicatorStyle}></div>
                                </div>
                            </div>
                        ) : (
                            <div className="col-span-3 max-[1200px]:col-span-7">
                                {/* Modifica lezione */}
                                <div className={`flex flex-col gap-6 mt-5`}>
                                    <div className={`flex gap-4 flex-col px-6 w-full ${width < 900 ? "px-4" : ""}`}>
                                        <div className="flex flex-col items-start gap-2 w-full px-4">
                                            <div className="font-semibold uppercase text-start">Titolo lezione</div>
                                            <InputText
                                                className="w-full select-none p-2 border focus:shadow-none"
                                                placeholder="Titolo lezione"
                                                value={editingContent.subTitle}
                                                onChange={(e) => setEditingContent({ ...editingContent, subTitle: e.target.value })}
                                            />
                                        </div>
                                        <div className="flex flex-col items-start gap-2 w-full px-4">
                                            <div className="font-semibold uppercase text-start">Descrizione lezione</div>
                                            <InputText
                                                className="w-full select-none p-2 border focus:shadow-none"
                                                placeholder="Descrizione lezione"
                                                value={editingContent.subDescription}
                                                onChange={(e) => setEditingContent({ ...editingContent, subDescription: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-center w-full">
                                        {editingContent.file && (
                                            <div className="flex flex-col items-center">
                                                <div className="text-gray-500 text-start w-full">File attuale:</div>
                                                {fileUrl && (
                                                    <div className="mt-5">
                                                        <iframe
                                                            src={fileUrl}
                                                            title="File Preview"
                                                            className="w-full h-96 border-2 border-gray-300"
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                        <FileUpload
                                            ref={fileRef}
                                            name="demo[]"
                                            url="/api/upload"
                                            accept="*"
                                            onUpload={onTemplateUpload}
                                            onSelect={onTemplateSelect}
                                            onError={onTemplateClear}
                                            onClear={onTemplateClear}
                                            headerTemplate={headerTemplate}
                                            itemTemplate={itemTemplate}
                                            emptyTemplate={emptyTemplate}
                                            chooseOptions={chooseOptions}
                                            cancelOptions={cancelOptions}
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                );
            default: return (
                <></>
            );
        }
    }

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            <HeadPage />
            <div className="w-full relative h-full">
                <Steps className='py-10' model={items} activeIndex={activeIndex} />
                <div className="grid grid-cols-8 px-10 pb-7">
                    {editingContent ? <Button className="col-span-2 col-start-1 buttonCustom !p-3 !px-6" onClick={() => setEditingContent(null)} icon="pi pi-arrow-left" label={`${width > 550 ? "Annulla" : ""}`}></Button> : <Button className={`col-span-2 col-start-1 buttonCustom !p-3 !px-6 ${activeIndex === 0 ? "!hidden" : ""}`} icon="pi pi-arrow-left" label={`${width > 550 ? "Indietro" : ""}`} onClick={() => goBack()}></Button>}
                    <Button className={`col-span-2 col-start-4 buttonCustom !p-3 !px-6 ${activeIndex === 1 ? "" : "!hidden"}`} icon="pi pi-plus" label={`${width > 550 ? "Aggiungi lezione" : ""}`} onClick={() => addContent()} />
                    {activeIndex === 2 ?
                        (editingContent ?
                            <Button className="col-span-2 col-start-7 buttonCustom !p-3 !px-6" disabled={contentCopy.subTitle === editingContent.subTitle && contentCopy.subDescription === editingContent.subDescription && !file} onClick={handleSaveEdit} icon="pi pi-save" label={`${width > 550 ? "Salva Modifiche" : ""}`}></Button>
                            :
                            <Button type="button" label={`${width > 550 ? "Pubblica corso" : ""}`} icon="pi pi-save" className="col-span-2 col-start-7 buttonCustom !p-3 !px-6" disabled={contentLesson.length === 0} onClick={() => handleConfirmUpload()} />
                        )
                        :
                        <Button className={`col-span-2 col-start-7 buttonCustom !p-3 !px-6 ${activeIndex}`} icon="pi pi-arrow-right" label={`${width > 550 ? "Avanti" : ""}`} onClick={() => goNext()}></Button>
                    }

                </div>
                <div className='grid grid-cols-7 gap-5 px-5 min-h-[calc(100vh-300px)] max-h-[calc(100vh-300px)] overflow-y-scroll'>
                    {steps()}
                </div>
            </div>
            <Dialog
                visible={alertDialog}
                onHide={() => setAlertDialog(false)}
                header="Attenzione"
                footer={handleAlertDialogFooter}
            >
                <p>Sembra tu stessi creando una nuova lezione. <br /> Prima di procedere aggiungi la lezione al corso (+) se non vuoi perderla.</p>
            </Dialog>
        </div>
    );
}

export default CreaCorso;
