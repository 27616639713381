import { combineReducers } from "redux";
import menuReducer from "./menuReducer";
import menuMobileReducer from "./menuMobileReducer";
import allResultsReducer from "./allResultsReducer";

const rootReducer = combineReducers({
    menu: menuReducer,
    menuMobile: menuMobileReducer,
    allResults: allResultsReducer
});

export default rootReducer;
