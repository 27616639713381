import { useDispatch, useSelector } from "react-redux";
import { chiudimenu, setActiveId, setPathCrumb } from "../store/actions/actions";
import { BreadCrumb } from "primereact/breadcrumb";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { capitalizePathName, handleDialogAndBreadcrumbs, useWindowSize } from "../utils/utils";
import { MdNotifications } from "react-icons/md";

function HeadPage(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const [width] = useWindowSize();
    const path = useSelector(state => state.menu.path)
    useEffect(() => {
        if (!path.find(el => el.label === window.location.pathname.split("/")[1].split(/(?=[A-Z])/).join(" "))) {
            dispatch(setPathCrumb(
                {
                    "label": window.location.pathname,
                    "template": () => <NavLink to={window.location.pathname} onClick={() => {
                        handleDialogAndBreadcrumbs(props.setDialog, dispatch);
                    }}>
                        {capitalizePathName(window.location.pathname)}
                    </NavLink>,
                    "props": {}
                }
            ))
        }
    }, [dispatch, path, props])
    return (
        <div className={`w-full h-auto bg-[#00b1b1] sticky top-0 z-[5] ${width < 901 ? "flex flex-row justify-between items-center" : ""}`}>
            <div className="w-10/12 min-[900px]:w-full overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100">
                <BreadCrumb className="first-letter:uppercase font-semibold text-white text-fluid1 p-4 bg-transparent text-start select-none" model={path} />
            </div>
            {/* <div className="first-letter:uppercase font-semibold text-white text-4xl p-4 text-start">{window.location.pathname.split("/")[1].split(/(?=[A-Z])/).join(" ")}</div> */}
            {width < 901 &&
                <MdNotifications size={30} className={`w-2/12 pr-2 text-white`} onClick={() => { navigate("/notifiche"); dispatch(chiudimenu()); dispatch(setActiveId("")) }} />
            }
        </div>

    )
}

export default HeadPage;
