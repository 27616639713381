import { useEffect, useState } from 'react';
import "../style/wave.css"; // Link to your custom wave CSS file

const ErrorPage = () => {
    const [digit1, setDigit1] = useState(0);
    const [digit2, setDigit2] = useState(0);
    const [digit3, setDigit3] = useState(0);
    const [errorDetails, setErrorDetails] = useState(null);

    useEffect(() => {
        const randomNum = () => Math.floor(Math.random() * 9) + 1;

        const animateDigit = (digitSetter, finalValue, delay) => {
            const error = JSON.parse(sessionStorage.getItem("errorDetails"));
            setErrorDetails(error);
            let count = 0;
            const interval = setInterval(() => {
                digitSetter(randomNum());
                count++;
                if (count > delay) {
                    clearInterval(interval);
                    digitSetter(finalValue);
                }
            }, 50);
        };

        animateDigit(setDigit3, parseInt(JSON.parse(sessionStorage.getItem("errorDetails")).status.toString()[0]), 40);
        animateDigit(setDigit2, parseInt(JSON.parse(sessionStorage.getItem("errorDetails")).status.toString()[1]), 80);
        animateDigit(setDigit1, parseInt(JSON.parse(sessionStorage.getItem("errorDetails")).status.toString()[2]), 100);
    }, []);

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-10 rounded-lg shadow-lg text-center">
                <div className="flex justify-center mb-6">
                    <div className="w-16 h-16 bg-[#00b1b1] text-white text-5xl flex items-center justify-center rounded-full transform -rotate-12">
                        {digit3}
                    </div>
                    <div className="w-16 h-16 bg-[#00b1b1] text-white text-5xl flex items-center justify-center rounded-full transform -rotate-12">
                        {digit2}
                    </div>
                    <div className="w-16 h-16 bg-[#00b1b1] text-white text-5xl flex items-center justify-center rounded-full transform -rotate-12">
                        {digit1}
                    </div>
                </div>
                <h1 className="text-3xl font-bold mb-4">Ops! Qualcosa è andato storto.</h1>
                <p className="text-lg text-gray-600">Errore: {errorDetails?.message || 'Si è verificato un errore inatteso.'}</p>
                <button
                    onClick={() => window.location.href = '/'}
                    className="mt-6 bg-[#00b1b1] text-white px-4 py-2 rounded hover:bg-[#007c7d] transition-all"
                >
                    Torna alla Homepage
                </button>
            </div>
        </div>
    );
};

export default ErrorPage;
