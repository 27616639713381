import React, { useEffect, useRef, useState } from 'react';
import HeadPage from '../../components/headPage';
import { Toast } from 'primereact/toast';
import CustomDataTable from '../../components/customDataTable';
import { APIRequest } from '../../services/axios';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { resetPathCrumb, setPathCrumb } from '../../store/actions/actions';
import { useDispatch, useSelector } from 'react-redux';
import { exportExcel, handleDialogAndBreadcrumbs, useWindowSize } from '../../utils/utils';
import CaricamentoDocs from '../../components/caricamentoDocs';
import { Dropdown } from 'primereact/dropdown';
import { LiaUserNurseSolid } from 'react-icons/lia';
import { NavLink } from 'react-router-dom';
import { Steps } from 'primereact/steps';
import FormCandidato from '../../components/formCandidato';
import { InputText } from 'primereact/inputtext';
import AssegnazioneDocs from '../../components/assegnazioneDocs';
import CreazioneContratto from '../../components/creazioneContratto';
import FrameContratto from '../../components/frameContratto';
import { candidaturePath } from '../../costant/path';

function Candidature(props) {
    const toast = useRef(null);
    const dt = useRef(null);
    const [results, setResults] = useState(null);
    const [resultsCopy, setResultsCopy] = useState(null);
    const [result, setResult] = useState(null);
    const [resultsTypeDocs, setResultsTypeDocs] = useState(null);
    const [candidato, setCandidato] = useState(null);
    const [resultDialog, setResultDialog] = useState(null);
    const [resultsStatus, setResultsStatus] = useState(null);
    const [statusSelected, setStatusSelected] = useState(null);
    const [posizioneSelected, setPosizioneSelected] = useState(null);
    const [contractDialog, setContractDialog] = useState(false);
    const [showContract, setShowContract] = useState(false);
    const [posizioni, setPosizioni] = useState([]);
    const dispatch = useDispatch()
    const [width] = useWindowSize();
    const allResults = useSelector(state => state.allResults.results)
    const [showAddInput, setShowAddInput] = useState(null);
    const [labelInput, setLabelInput] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [finalContract, setFinalContract] = useState(null)
    const [contratto, setContratto] = useState(null);
    const [contratti, setContratti] = useState(null);
    const [inputText, setInputText] = useState(null);
    const [attachments, setAttachments] = useState(null);
    const [oreLavoro, setOreLavoro] = useState(null)
    const [ral, setRal] = useState(null)
    const [buoniPasto, setBuoniPasto] = useState(null)
    const [rimborsoCarb, setRimborsoCarb] = useState(null)


    const itemRenderer = (item, itemIndex) => {
        const isActiveItem = activeIndex === itemIndex;
        const backgroundColor = isActiveItem ? 'var(--primary-color)' : (activeIndex > itemIndex ? "#00b300" : 'var(--surface-b)');
        const textColor = isActiveItem ? 'var(--surface-b)' : (activeIndex > itemIndex ? "white" : 'var(--text-color-secondary)');

        return (
            <span
                className="flex flex-row gap-2 items-center justify-center items-center rounded-full p-3 z-999"
                style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '-25px' }}
            >
                <i className={item.icon} />
            </span>
        );
    };

    const items = [
        {
            label: "Item 1",
            icon: "pi pi-file",
            template: (item) => itemRenderer(item, 0)
        },
        /* {
            label: "Item 2",
            icon: "pi pi-file",
            template: (item) => itemRenderer(item, 1)
        },
        {
            label: "Item 3",
            icon: "pi pi-pencil",
            template: (item) => itemRenderer(item, 2)
        }, */
        {
            label: "Item 4",
            icon: "pi pi-file-edit",
            template: (item) => itemRenderer(item, 3)
        },
    ];

    useEffect(() => {
        async function trovaRisultato() {
            await APIRequest("GET", `recruitmentService`, null, dispatch)
                .then(res => {
                    if (res.data.success) {
                        setResults(res.data.data)
                        setResultsCopy(res.data.data)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            setResults(find.results)
                            setResultsCopy(find.results)
                        }
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                    }
                    console.log(e)
                })
            await APIRequest("GET", `recruitmentService/status`, null, dispatch)
                .then(res => {
                    if (res.data.success) {
                        let _status = [...res.data.data]
                        _status.unshift({ label: 'Tutti', id: -1 });
                        setResultsStatus(_status)
                        setStatusSelected(_status[0])
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            let _status = [...find.results]
                            _status.unshift({ label: 'Tutti', id: -1 });
                            setResultsStatus(_status)
                            setStatusSelected(_status[0])
                        }
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                    }
                    console.log(e)
                })
            await APIRequest('GET', 'recruitmentService/jobPosition')
                .then((res) => {
                    if (res.data.success) {
                        let _posizioni = [...res.data.data]
                        _posizioni.unshift({ title: 'Tutti', id: -1 });
                        setPosizioni(_posizioni);
                        setPosizioneSelected(_posizioni[0])
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            let _posizioni = [...find.results]
                            _posizioni.unshift({ label: 'Tutti', id: -1 });
                            setPosizioni(_posizioni);
                            setPosizioneSelected(_posizioni[0])
                        }
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                    }
                    console.log(e)
                })
            await APIRequest('GET', 'documentsService/documentType', null, dispatch)
                .then((res) => {
                    if (res.data.success) {
                        setResultsTypeDocs(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            setResultsTypeDocs(find.results)
                        }
                    }
                    console.log(e);
                });
            await APIRequest('GET', 'recruitmentService/contractType', null, dispatch)
                .then((res) => {
                    if (res.data.success) {
                        setContratti(res.data.data);
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                })
                .catch((e) => {
                    if (e.code === "ERR_NETWORK") {
                        let find = allResults.find(el => el.title === (e.config.url.includes("3001/") ? e.config.url.split("3001/")[1] : (e.config.url.includes("/api/") ? e.config.url.split("/api/")[1] : e.config.url)))
                        toast.current.show({ severity: 'warn', summary: 'ATTENZIONE!', detail: "Connessione assente, impossibile caricare / aggiornare i dati", life: 3000 });
                        if (find) {
                            setContratti(find.results)
                        }
                    }
                    console.log(e);
                });
        }
        trovaRisultato();
        dispatch(resetPathCrumb())
    }, [dispatch])

    const openDocumentsDialog = async (rowData) => {
        if (rowData.status.label === "Candidato") {
            let _results = [...results]
            let body = {
                "appliedAt": rowData?.appliedAt,
                "jobPositionId": rowData?.jobPosition?.id,
                "statusId": resultsStatus?.find(el => el?.label === "Valutazione")?.id,
                "note": rowData?.note
            }
            await APIRequest("PUT", `recruitmentService?id=${rowData.id}`, body, dispatch)
                .then(res => {
                    if (res.data.success) {
                        _results.forEach(el => {
                            if (el.id === res.data.data.id) {
                                el.status = res.data.data.status
                            }
                        })
                        setResults(_results)
                        setResultsCopy(_results)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                    console.log(e)
                })
        }
        setResult(rowData?.idDocument)
        setResultDialog(true)
        setCandidato(rowData)
    }

    const openContractDialog = (rowData) => {
        if (rowData.status.label === "Valutazione" || rowData.status.label === "Rifiutato") {
            setContractDialog(true)
            setResult(rowData?.idDocument)
            setCandidato(rowData)
            dispatch(setPathCrumb(
                {
                    "label": "Configurazione data entry",
                    "template": () => <NavLink to={candidaturePath}>Configurazione data entry ⁓ {rowData.idRegistry.firstName} {rowData.idRegistry.lastName} ⁓</NavLink>
                }
            ))
        } else if (rowData.status.label === "Candidato") {
            toast.current.show({ severity: 'error', summary: 'Errore', detail: "è possibile eseguire l'operazione solo dopo aver visualizzato la candidatura", life: 3000 });
        }
    }

    const rejectCandidatesDialog = async (rowData) => {
        if (rowData.status.label === "Valutazione") {
            let _results = [...results]
            let body = {
                "appliedAt": rowData?.appliedAt,
                "jobPositionId": rowData?.jobPosition?.id,
                "statusId": resultsStatus?.find(el => el?.label === "Rifiutato")?.id,
                "note": rowData?.note
            }
            await APIRequest("PUT", `recruitmentService?id=${rowData.id}`, body, dispatch)
                .then(res => {
                    if (res.data.success) {
                        _results.forEach(el => {
                            if (el.id === res.data.data.id) {
                                el.status = res.data.data.status
                            }
                        })
                        setResults(_results)
                        setResultsCopy(_results)
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                    console.log(e)
                })
        } else if (rowData.status.label === "Approvato") {
            toast.current.show({ severity: 'error', summary: 'Errore', detail: "Non è possibile eseguire l'operazione dopo l'approvazione", life: 3000 });
        } else if (rowData.status.label === "Candidato") {
            toast.current.show({ severity: 'error', summary: 'Errore', detail: "è possibile eseguire l'operazione solo dopo aver visualizzato la candidatura", life: 3000 });
        }
    }

    const showApproveCandidatesDialog = async (rowData) => {
        await APIRequest("GET", `recruitmentService/userContract?idUser=${rowData?.idUser?.id}`, null, dispatch)
            .then(res => {
                if (res.data.success) {
                    console.log(res.data.data)
                    setShowContract(true)
                } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                }
            }).catch((e) => {
                toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                console.log(e)
            })
    }

    const fields = [
        {
            field: "data",
            header: "Data",
            body: "dataFormat",
            sortable: true,
            showHeader: true,
        },
        {
            field: "firstName",
            header: "Nome",
            body: "firstName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "lastName",
            header: "Cognome",
            body: "lastName",
            sortable: true,
            showHeader: true,
        },
        {
            field: "email",
            header: "Email",
            body: "username",
            sortable: true,
            showHeader: true,
        },
        {
            field: "cellNumber",
            header: "Cellulare",
            body: "cellNumber",
            showHeader: true,
        },
        {
            field: "jobPosition",
            header: "Posizione",
            body: "jobPosition",
            showHeader: true,
        },
        {
            field: "ASLPreference",
            header: "Preferenza Asl",
            body: "ASLPreference",
            showHeader: true,
        },
        {
            field: "status",
            header: "Stato",
            body: "statusTemplate",
            showHeader: true,
        },
        {
            field: "progress",
            header: "Progresso",
            body: "progressTemplate",
            showHeader: true,
        },
    ];

    const actionFields = (rowData) => {
        // Inizializza le azioni base
        const actions = [
            {
                name: () => "Vedi documento",
                icon: () => <i className="pi pi-eye" />,
                handler: openDocumentsDialog,
            }
        ];

        // Aggiungi l'azione "Rifiuta" solo se lo status è "Valutazione"
        if (rowData.status.label === "Rifiutato") {
            actions.splice(1, 0, {
                name: () => "Assegna contratto",
                icon: () => <i className="pi pi-file-edit" />,
                handler: openContractDialog,
            });
        } else if (rowData.status.label === "Valutazione") {
            actions.splice(1, 0, {
                name: () => "Assegna contratto",
                icon: () => <i className="pi pi-file-edit" />,
                handler: openContractDialog,
            });
            actions.splice(2, 0, {
                name: () => "Rifiuta",
                icon: () => <i className="pi pi-times" />,
                handler: rejectCandidatesDialog,
            });
        } else if (rowData.status.label === "Approvato") {
            actions.splice(1, 0, {
                name: () => "Conferma",
                icon: () => <i className="pi pi-file-check" />,
                handler: showApproveCandidatesDialog,
            });
            actions.splice(2, 0, {
                name: () => "Rifiuta",
                icon: () => <i className="pi pi-times" />,
                handler: rejectCandidatesDialog,
            });
        }
        return actions;
    };

    const filterStatus = (e) => {
        setStatusSelected(e.value)
        let _results = [...results]
        if (e.value.id >= 0) {
            if (posizioneSelected.id >= 0) {
                setResultsCopy(_results.filter(el => el.status.id === e.value.id && el.jobPosition.id === posizioneSelected.id))
            } else {
                setResultsCopy(_results.filter(el => el.status?.id === e.value.id))
            }
        } else {
            if (posizioneSelected.id >= 0) {
                setResultsCopy(_results.filter(el => el.jobPosition?.id === posizioneSelected.id))
            } else {
                setResultsCopy(_results)
            }
        }
    }

    const filterPositions = (e) => {
        setPosizioneSelected(e.value)
        let _results = [...results]
        if (e.value.id >= 0) {
            if (statusSelected.id >= 0) {
                setResultsCopy(_results.filter(el => el.jobPosition?.id === e.value.id && el.status.id === statusSelected.id))
            } else {
                setResultsCopy(_results.filter(el => el.jobPosition?.id === e.value.id))
            }
        } else {
            if (statusSelected.id >= 0) {
                setResultsCopy(_results.filter(el => el.status.id === statusSelected.id))
            } else {
                setResultsCopy(_results)
            }
        }
    }

    const indietro = () => {
        handleDialogAndBreadcrumbs(setContractDialog, dispatch)
        setActiveIndex(0)
        setFinalContract(null)
        setOreLavoro(null)
        setRal(null)
        setBuoniPasto(null)
        setContratto(null)
        setRimborsoCarb(null)
    }

    const addInput = () => {
        if (labelInput) {
            setInputText(prevSteps => [...prevSteps, { id: prevSteps.length, label: labelInput, required: false, show: false }]);
            setShowAddInput(false);
            setLabelInput("");
        } else {
            toast.current.show({ severity: 'error', summary: 'Errore', detail: "Label del nuovo campo non inserito", life: 3000 });
        }
    }

    const SaveConfiguration = () => {
        if (contratto) {
            if (oreLavoro !== null && ral !== null) {
                /* if (inputText?.length > 0) { */
                approveCandidatesDialog(candidato)
                /* } else {
                    toast.current.show({ severity: 'error', summary: 'Errore', detail: "Attenzione! Non è possibile salvare la configurazione senza almeno un campo", life: 3000 });
                } */
            } else {
                toast.current.show({ severity: 'error', summary: 'Errore', detail: "Attenzione! Non è possibile salvare la configurazione senza specificare la RAL e le ore lavorative settimanali", life: 3000 });
            }
        } else {
            toast.current.show({ severity: 'error', summary: 'Errore', detail: "Attenzione! Non è possibile salvare la configurazione senza selezionare una tipologia di contratto", life: 3000 });
        }
    }

    const approveCandidatesDialog = async (rowData) => {
        if (rowData.status.label === "Valutazione" || rowData.status.label === "Rifiutato") {
            finalContract.fields = finalContract.fields.map(el => {
                return { ...el, value: null }
            })
            let _results = [...results]
            let body = {
                "appliedAt": rowData?.appliedAt,
                "jobPositionId": rowData?.jobPosition?.id,
                "statusId": JSON.parse(localStorage.getItem("user")).role.label === "ADMIN" ? resultsStatus?.find(el => el?.label === "Avallato")?.id : resultsStatus?.find(el => el?.label === "Approvato")?.id,
                "note": rowData?.note
            }
            let _contract = {
                "idUser": candidato.idUser.id,
                "idContract": finalContract.id,
                "rimbCarb": rimborsoCarb,
                "buonipasto": buoniPasto,
                "ral": ral,
                "weeklyHours": oreLavoro,
                "fields": finalContract.fields,
                "content": finalContract.content,
                "documents": finalContract.attachments,
            }
            await APIRequest("POST", `recruitmentService/userContract`, _contract, dispatch)
                .then(async res => {
                    if (res.data.success) {
                        if (JSON.parse(localStorage.getItem("user")).role.label === "ADMIN") {
                            await APIRequest("PUT", `recruitmentService?id=${rowData.id}`, body, dispatch)
                                .then(res => {
                                    if (res.data.success) {
                                        _results.forEach(el => {
                                            if (el.id === res.data.data.id) {
                                                el.status = res.data.data.status
                                            }
                                        })
                                        setContractDialog(false)
                                        setResults(_results)
                                        setResultsCopy(_results)
                                        setActiveIndex(0)
                                        handleDialogAndBreadcrumbs(setContractDialog, dispatch)
                                    } else {
                                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                                    }
                                }).catch((e) => {
                                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                                    console.log(e)
                                })
                        } else {
                            await APIRequest("PUT", `recruitmentService?id=${rowData.id}`, body, dispatch)
                                .then(res => {
                                    if (res.data.success) {
                                        _results.forEach(el => {
                                            if (el.id === res.data.data.id) {
                                                el.status = res.data.data.status
                                            }
                                        })
                                        setContractDialog(false)
                                        setResults(_results)
                                        setResultsCopy(_results)
                                        setActiveIndex(0)
                                        handleDialogAndBreadcrumbs(setContractDialog, dispatch)
                                    } else {
                                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                                    }
                                }).catch((e) => {
                                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                                    console.log(e)
                                })
                        }
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Errore', detail: `${res.data.message}`, life: 3000 });
                    }
                }).catch((e) => {
                    toast.current.show({ severity: 'error', summary: 'Siamo spiacenti!', detail: "Non è stato possibile caricare i documenti", life: 3000 });
                    console.log(e)
                })
        } else if (rowData.status.label === "Candidato") {
            toast.current.show({ severity: 'error', summary: 'Errore', detail: "è possibile eseguire l'operazione solo dopo aver visualizzato la candidatura", life: 3000 });
        }
    }

    const fieldsXLSX = ["Data", "Nome", "Cognome", "Role", "Stato"]
    const pathFieldsXLSX = ["idDocument.created_at", "idRegistry.firstName", "idRegistry.lastName", "idUser.role.label", "status.label"]
    const nameFileExport = "Candidati"

    const header = (
        <div className="flex flex-row max-[650px]:flex-col justify-between items-end max-[650px]:items-start">
            <Button className="buttonCustom" type="button" icon="pi pi-file-excel" label={"Esporta XLS"} severity="success" rounded onClick={() => exportExcel(fieldsXLSX, pathFieldsXLSX, results, nameFileExport)} />
            <div className='flex flex-row min-[900px]:justify-end gap-5 max-[650px]:w-full w-6/12'>
                <div className="mt-3 max-[1050px]:w-6/12 w-3/12">
                    <label htmlFor="email" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                        <LiaUserNurseSolid size={23} className='mr-3' />Stato
                    </label>
                    <Dropdown disabled={!resultsStatus} value={statusSelected} onChange={(e) => filterStatus(e)} options={resultsStatus} optionLabel="label" placeholder='Stato'
                        filter className="w-full rounded-md border border-[#00b1b1]" checkmark={true} highlightOnSelect={false} />
                </div>
                <div className="mt-3 max-[1050px]:w-6/12 w-3/12">
                    <label htmlFor="email" className="select-none font-bold block flex flex-row items-center justify-start mb-2 text-[#017878] uppercase">
                        <LiaUserNurseSolid size={23} className='mr-3' />Posizione
                    </label>
                    <Dropdown disabled={!posizioni} value={posizioneSelected} onChange={(e) => filterPositions(e)} options={posizioni} optionLabel="title" placeholder='Posizione'
                        filter className="w-full rounded-md border border-[#00b1b1]" checkmark={true} highlightOnSelect={false} />
                </div>
            </div>
        </div>
    );

    return (
        <div className="flex flex-col h-full relative">
            <Toast ref={toast} />
            {!props.isDashboard &&
                <HeadPage setDialog={setContractDialog} />
            }
            {contractDialog ?
                <div className='pt-5'>
                    <Steps className='py-5' model={items} activeIndex={activeIndex} readOnly={false} />
                    <div className="grid grid-flow-rows">
                        <div className='row-span-1 px-6 grid grid-cols-5 max-[1100px]:grid-cols-3 gap-5 items-start'>
                            <Button className='buttonCustom max-[1100px]:col-span-1' label={`${width > 480 ? "Annulla" : ""}`} icon={`pi pi-times`} onClick={() => indietro()}></Button>
                            {
                                <>
                                    {activeIndex > 0 &&
                                        <Button className={`buttonCustom max-[1100px]:col-span-1 col-start-4`} label={`${width > 480 ? "Indietro" : ""}`} icon={`pi pi-arrow-left`} onClick={() => setActiveIndex(activeIndex - 1)}></Button>
                                    }
                                    {activeIndex === 1 ?
                                        <>
                                            <Button className={`buttonCustom max-[1100px]:col-span-1 max-[1100px]:col-start-3 min-[1101px]:col-start-5`} label={`${width > 480 ? "Salva" : ""}`} icon={`pi pi-save`} onClick={() => SaveConfiguration()}></Button>
                                        </>
                                        :
                                        <Button
                                            className={`buttonCustom max-[1100px]:col-span-1 max-[1100px]:col-start-3 min-[1101px]:col-start-5`}
                                            label={`${width > 480 ? "Avanti" : ""}`}
                                            disabled={
                                                (activeIndex === 0 && (!contratto || oreLavoro === null || ral === null))
                                                /* || (activeIndex === 1 && (inputText?.length <= 0))
                                                || (activeIndex === 2 && (attachments?.filter(el => el.show).length <= 0)) */
                                            }
                                            icon={`pi pi-arrow-right`}
                                            onClick={() => setActiveIndex(activeIndex + 1)}>
                                        </Button>
                                    }
                                </>
                            }
                        </div>
                        <div className='p-2 row-span-1 px-6 max-h-[calc(100vh-230px)] overflow-y-scroll my-2'>
                            <div className='w-full text-center uppercase font-bold my-2 text-fluid0'>{activeIndex === 0 ? "Assegnazione tipologia contratto" : (activeIndex === 1 ? "Informazioni necessarie dell'utente" : (activeIndex === 2 ? "Documenti necessari dell'utente" : "Contratto"))}</div>
                            {activeIndex === 0 ?
                                <CreazioneContratto toast={toast} candidato={candidato} finalContract={finalContract} setFinalContract={setFinalContract} setOreLavoro={setOreLavoro} oreLavoro={oreLavoro} setRal={setRal} ral={ral} setBuoniPasto={setBuoniPasto} buoniPasto={buoniPasto} setRimborsoCarb={setRimborsoCarb} rimborsoCarb={rimborsoCarb} contratto={contratto} setContratto={setContratto} contratti={contratti} setContratti={setContratti} inputText={inputText} setInputText={setInputText} attachments={attachments} setAttachments={setAttachments} />
                                :
                                /* (activeIndex === 1 ?
                                    <div className='flex flex-col items-center justify-center'>
                                        <FormCandidato inputText={inputText} setInputText={setInputText} />
                                    </div>
                                    :
                                    (activeIndex === 2 ?
                                        <div className='flex flex-col items-center justify-center'>
                                            <AssegnazioneDocs attachments={attachments} setAttachments={setAttachments} />
                                        </div>
                                        :
                                    )
                                ) */
                                <div className="col-span-1">
                                    <FrameContratto candidato={candidato} finalContract={finalContract}/>
                                </div>
                            }
                        </div>
                    </div>
                </div>
                :
                (showContract ?
                    <div className="col-span-1">
                        <FrameContratto candidato={candidato} finalContract={finalContract}/>
                    </div>
                    :
                    <div className={`flex-grow transition-all duration-300 p-3 min-[900px]:px-10`}>
                        <CustomDataTable
                            dataKey="id"
                            loading={!resultsCopy}
                            refer={dt}
                            value={resultsCopy}
                            actionFields={actionFields}
                            fields={fields}
                            showHeader={true}
                            header={header}
                        />
                    </div>
                )
            }
            <Dialog visible={resultDialog} className="w-1/2 h-auto" header={"Dettagli documento"} onHide={() => setResultDialog(false)}>
                <div className="flex flex-col w-full justify-between items-center gap-5">
                    <CaricamentoDocs dialogDocs={resultDialog} setDialogDocs={setResultDialog} result={candidato} idRole={10} />
                    <div className='flex flex-col items-center justify-start w-full'>
                        <p className='font-bold text-xl text-start w-full'>Messaggio:</p>
                        <p className='text-start break-words w-full'>{candidato?.note}</p>
                    </div>
                </div>
            </Dialog>
        </div>
    );
}

export default Candidature;
